import { apiV3Client } from './BaseRepository';

export function fetchJobUsageBudget(jobId) {
  return apiV3Client.get(`job-usage-budget/${jobId}/available-budget`);
}
export function FetchAllJobUsageBudgetRequests(payload, pageSize = 10, pageNumber = 1) {
  return apiV3Client.post(`job-usage-budget/requests`, {
    From: (pageNumber - 1) * pageSize,
    Size: pageSize,
    ...payload,
  });
}

export function postJobUsageBudgetRequest(jobId, totalBudget) {
  return apiV3Client.post(`job-usage-budget/request/${jobId}`, {
    BudgetUnits: totalBudget,
  });
}

export function updateJobUsageBudgetRequestStatus(requestId, payload) {
  return apiV3Client.put(`job-usage-budget/request/${requestId}`, payload);
}
