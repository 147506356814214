import React from 'react';
import { Tooltip } from 'antd';
import { getSourceImage } from '../../../Utils/SourceUtils';

export default function SourceImageIcon(props) {
  const {
    tooltipVisibility,
    placement,
    sourceName,
    sourcePortal,
    iconStyle,
    showVaultName,
    isMaskActiveAsInternalEnabled,
  } = props;

  return tooltipVisibility ? (
    <Tooltip
      placement={placement}
      title={sourceName === 'Active' && isMaskActiveAsInternalEnabled ? 'Internal' : sourceName}
    >
      {getSourceImage({ Portal: sourcePortal }, iconStyle, showVaultName, isMaskActiveAsInternalEnabled)}{' '}
    </Tooltip>
  ) : (
    getSourceImage({ Portal: sourcePortal }, iconStyle, showVaultName, isMaskActiveAsInternalEnabled)
  );
}
