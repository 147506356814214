import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './EnhancedCandidateTransferModal.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const CandidateMoveButton = props => {
  const { isBoxSelected, actionName, handleActionChange } = props;
  return (
    <div
      className={classNames(styles.actionButtons, {
        [styles.highlightedActionButtons]: isBoxSelected(actionName, 'Move'),
      })}
      key="Move"
      onClick={() => handleActionChange('Move')}
      onKeyPress={() => handleActionChange('Move')}
      tabIndex={0}
      role="button"
    >
      <div className={styles.actionButtonText}>
        <FormattedMessage {...message.moveLabel} />
        <div className={styles.actionButtonInfoText}>
          {' '}
          (<FormattedMessage {...message.removeTransferTo} />{' '}
        </div>
        <div className={styles.actionButtonInfoText}>
          <FormattedMessage {...message.anotherEntityLabel} />)
        </div>
      </div>
    </div>
  );
};

export default CandidateMoveButton;
