import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import { Skeleton, BackTop } from 'antd';
import BackTopIcon from '../Icons/BackTopIcon';

import JobCandidatesTab from '../Containers/JobCandidatesTab/JobCandidatesTab';
import AppToastNotification from '../Containers/AppNotification/AppToastNotification';
import BotConfigDrawerContainer from '../Containers/BotConfigDrawerContainer/BotConfigDrawerContainer';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import ManualSearchContainerV2 from '../Containers/ManualSearchV2/ManualSearchContainer/ManualSearchContainer';
import ManualSearchContainerV1 from '../Containers/ManualSearch/ManualSearchContainer/ManualSearchContainer';
import { getJobDetails } from '../Reducers/JobReducer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { getWhiteLabelInformation, getFetchUserConfigApiStatus } from '../Reducers/ConfigReducer';
import * as CandidateActions from '../Actions/CandidateActions';
import * as jobCandidatesTabActions from '../Actions/JobCandidatesTabActions';

class DtmfTone {
  play = () => {};
}

class CandidateList extends React.Component {
  constructor(props) {
    super(props);
    let { status, connectionStatus } = props;
    if (connectionStatus === 'Connected') {
      status = null;
      connectionStatus = 'Connected';
    }
    const queryParams = queryString.parse(window.location.search);
    const { candidateGuid } = queryParams;
    const { candidateId } = props;
    this.state = {
      status,
      drawerVisibility: false,
      connectionStatus,
      atsCandidateRankIndex: -1,
      initialLoadingFlag: !candidateId && !candidateGuid,
      candidateId,
      isAryaRankedTab: false,
      isScoutingAgentTab: false,
    };
  }

  async componentDidMount() {
    const { fetchCandidateRank, candidateId, jobId, featureToggleList, paramStatusVal } = this.props;
    const { connectionStatus, status } = this.state;
    const queryParams = queryString.parse(window.location.search);
    const { candidateGuid, cStatus, isScoutingAgentCandidate, activeTab } = queryParams;
    if (candidateId || candidateGuid) {
      const candidateRankQuery = {
        candidateId,
        candidateGuid,
        connectionStatus: cStatus,
        isScoutingAgentCandidate: isScoutingAgentCandidate === 'true',
      };
      const response = await fetchCandidateRank({ jobId, candidateRankQuery });
      const { Status, Rank, CandidateId: AryaCandidateId } = response;
      if (Rank > -1) {
        this.setState({
          atsCandidateRankIndex: Rank,
          connectionStatus: cStatus ? Status : connectionStatus,
          status: cStatus ? status : Status,
          candidateId: AryaCandidateId,
          isAryaRankedTab: !!(
            featureToggleList.AdvanceSearchV2.IsEnabled &&
            featureToggleList.AryaRanking.IsAllowed &&
            paramStatusVal === 'Ranked'
          ),
        });
      }
      this.setState({ initialLoadingFlag: true });
    }

    if (activeTab?.toLowerCase() === 'scoutingagent') this.setState({ isScoutingAgentTab: true });
  }

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { drawerVisibility, botDrawerRenderFlag } = this.state;
    if (drawerVisibility) this.setState({ drawerVisibility: !drawerVisibility });
    else this.setState({ drawerVisibility: !drawerVisibility, botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  changeStatus = (status, connectionStatus) => {
    this.setState({
      status: status && status.charAt(0).toUpperCase() + status.slice(1),
      connectionStatus: connectionStatus && connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1),
    });
  };

  _onSearchCandidatesCallback = () => {
    const { onSearchCandidatesCallback, setJobCandidateTabActiveTab } = this.props;
    setJobCandidateTabActiveTab({ activeTab: 'sourced' });
    this.setState({ status: 'Sourced' });
    if (onSearchCandidatesCallback) onSearchCandidatesCallback();
  };

  render() {
    const {
      jobId,
      openAtsView,
      notesContainer,
      callNotesContainer,
      postCallNotes,
      dialingTone,
      ringingTone,
      dtmfTone = new DtmfTone(),
      jobDetails,
      openAryaCandidateView,
      whiteLabelInfo,
      atsFetchBulkNotes,
      fetchUserConfigApiStatus,
      featureToggleList,
      atsManualSearchAppendId,
      OpenSipCallWindowsApp,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      version,
      smartRecruiterVersion,
      activeTab,
      atsActivePageName,
      botEnabledFromBulkMessageAtsCallback,
    } = this.props;
    const {
      status,
      connectionStatus,
      drawerVisibility,
      botDrawerRenderFlag,
      atsCandidateRankIndex,
      initialLoadingFlag,
      candidateId,
      isAryaRankedTab,
      isScoutingAgentTab,
    } = this.state;
    const isAtsCandidateFetchModalVisible = atsActivePageName === 'AryaCandidates';
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
    const intJobId = parseInt(jobId, 10);
    if (!initialLoadingFlag || fetchUserConfigApiStatus === 'PENDING') {
      return <LoadingScreen active loading />;
    }
    const atsManualSearchAppendElement = document.getElementById(atsManualSearchAppendId);
    const {
      CandidateManualSearch: { IsEnabled: isCandidateManualSearchEnabled },
      PrefetchAdvanceSearchResults: { IsEnabled: isPrefetchAdvanceSearchResultsEnabled },
      PreferredKeywordAutoSelect: { IsEnabled: isPreferredKeywordAutoSelectEnabled },
      ChatBotDemo: { IsEnabled: isChatBotDemoEnabled },
    } = featureToggleList;

    let ManualSearchContainer = featureToggleList.AdvanceSearchV2.IsEnabled
      ? ManualSearchContainerV2
      : ManualSearchContainerV1;
    if (smartRecruiterVersion) {
      ManualSearchContainer = smartRecruiterVersion === 'v2' ? ManualSearchContainerV2 : ManualSearchContainerV1;
    }
    return (
      <>
        {isChatBotDemoEnabled ? (
          <div id="demo-chatbot" style={{ position: 'fixed', zIndex: 23000, bottom: '15px', left: '110px' }}></div>
        ) : null}
        <>
          {isCandidateManualSearchEnabled && atsManualSearchAppendElement
            ? ReactDOM.createPortal(
                <ManualSearchContainer
                  jobId={jobId}
                  onSearchCandidatesCallback={this._onSearchCandidatesCallback}
                  pageName="JobDetail"
                  version={version}
                  candidateContext="job"
                  isAtsCandidateFetchModalVisible={isAtsCandidateFetchModalVisible}
                  isPrefetchEnabled={isPrefetchAdvanceSearchResultsEnabled}
                  isPreferredKeywordAutoSelectEnabled={isPreferredKeywordAutoSelectEnabled}
                  atsActivePageName={atsActivePageName}
                />,
                atsManualSearchAppendElement
              )
            : null}
          <AppToastNotification />
          <BotConfigDrawerContainer
            jobTitle=""
            jobId={intJobId}
            visible={drawerVisibility}
            botDrawerRenderFlag={botDrawerRenderFlag}
            onClose={this.toggleBotConfigDrawer}
            AppName={AppName}
          />
          <BackTop style={{ right: '40px', bottom: '20px' }}>
            <BackTopIcon style={{ transform: 'scale(1.5)' }} />
          </BackTop>
          <Skeleton active paragraph={{ rows: 8 }} loading={!jobDetails.JobGuid}>
            <JobCandidatesTab
              smartRecruiterVersion={smartRecruiterVersion}
              version={version}
              jobId={intJobId}
              status={status}
              changeStatus={this.changeStatus}
              setUrl={() => {}}
              notesContainer={notesContainer}
              callNotesContainer={callNotesContainer}
              openAtsView={openAtsView}
              openAryaCandidateView={openAryaCandidateView}
              connectionStatus={connectionStatus}
              postAtsCallNotes={postCallNotes}
              showBotConfigDrawer={this.toggleBotDrawerRenderFlag}
              dialingTone={dialingTone}
              ringingTone={ringingTone}
              dtmfTone={dtmfTone}
              isBulkActionEnabled
              atsFetchBulkNotes={atsFetchBulkNotes}
              currentJobDetails={jobDetails}
              atsCandidateRankIndex={atsCandidateRankIndex}
              openSipCallWindowsApp={OpenSipCallWindowsApp}
              openSegmentAtsView={openSegmentAtsView}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              onClickAddJobCallBack={onClickAddJobCallBack}
              candidateId={candidateId}
              isAtsCandidateFetchModalVisible={isAtsCandidateFetchModalVisible}
              isAryaRankedTab={isAryaRankedTab}
              isScoutingAgentTab={isScoutingAgentTab}
              atsActivePageName={atsActivePageName}
              botEnabledFromBulkMessageAtsCallback={botEnabledFromBulkMessageAtsCallback}
            />
          </Skeleton>
        </>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  jobDetails: getJobDetails(state, props.jobId),
  whiteLabelInfo: getWhiteLabelInformation(state),
  fetchUserConfigApiStatus: getFetchUserConfigApiStatus(state),
  featureToggleList: getFeatureToggleList(state),
});
const mapDispatchToProps = {
  fetchCandidateRank: CandidateActions.fetchCandidateRank,
  setJobCandidateTabActiveTab: jobCandidatesTabActions.setJobCandidateTabActiveTab,
};

const PackageCandidateList = connect(mapStateToProps, mapDispatchToProps)(CandidateList);

export default PackageCandidateList;

export { CandidateList as CandidateListWithoutStore };
