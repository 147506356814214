/* eslint-disable */
import React from 'react';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LocaleProvider, BackTop } from 'antd';
import { addLocaleData, IntlProvider } from 'react-intl';
import moment from 'moment';
import 'moment/locale/es';
import queryString from 'query-string';
import _ from 'lodash';
import BackTopIcon from './Icons/BackTopIcon';

import './antd-overrides.less';
import './index.scss';
import './createPackage.scss';

import { ConnectUrlMapper } from './Containers/Connect/ConnectUrlMapper';
import PackageCandidateList from './Package/CandidateList';
import PackageBotConfig from './Package/BotConfig';
import ConnectContainer from './Package/ConnectContainer';
import PackageJobMatchingModal from './Package/JobMatchingModalContainer';
import PackageJobBoardSettings from './Package/JobBoardSettings';
import PackageContactSettings from './Package/ContactSettingsContainer';
import PackageAdminSettings from './Package/AdminSettingsContainer';
import PackageApplicationSettings from './Package/ApplicationSettingsContainer';
import PackageSettings from './Package/SettingsContainer';
import { JobIntelContainerWithoutRouter } from './Containers/JobIntel/JobIntel';
import ActivationDialogContainer from './Containers/ActivationDialog/ActivationDialog';
import AppAlertNotificationContainer from './Containers/AppNotification/AppAlertNotification';
import AppToastNotification from './Containers/AppNotification/AppToastNotification';
import SourcingProgressIndicator from './Components/SourcingProgressIndicator/SourcingProgressIndicator';
import JobSettings from './Containers/JobSettings/JobSettings';
import CallDialogContainer from './Containers/CallDialog/CallDialog';
import JobInitiator from './Containers/JobInitiator/JobInitiator';
import EmailConfigurationContainer from './Containers/EmailConfigurationContainer/EmailConfigurationContainer';
import AdminSMTPSettingsContainer from './Containers/AdminSMTPSettingsContainer/AdminSMTPSettingsContainer';
import ManualSearchContainerV1 from './Containers/ManualSearch/ManualSearchContainer/ManualSearchContainer';
import ManualSearchContainerV2 from './Containers/ManualSearchV2/ManualSearchContainer/ManualSearchContainer';
import ConnectBotWindow from './Components/Connect/ConnectBotWindow/ConnectBotWindow';
import CandidateNotes from './Components/CandidateNotes/CandidateNotes';
import CandidateView from './Components/CandidateView/CandidateView';
import CallNotes from './Components/CallNotes/CallNotes';
import NotesForm from './Components/NotesForm/NotesForm';
import BotSettings from './Components/BotSettings/BotSettings';

import './Components/JobDetailHeader/JobDetailHeader.scss';
import './Components/ApplicationSettings/ApplicationSettings.scss';
import './Containers/Settings/Settings.scss';

import { setConfig } from './Repository/BaseRepository';
import { fetchJobDetails, fetchJobSourcingStats, fetchActivationDetails } from './Repository/JobRepository';

import { getFeatureToggleList } from './Reducers/FeatureToggleReducer.ts';
import { getJobsById } from './Reducers/JobReducer';

import * as JobActions from './Actions/JobActions';
import {
  setBulkCandidateNotes,
  setBulkCallNotesUsingCandidateIds,
  setCandidateNotesCount,
} from './Actions/ActionCreators/CandidateActions';

import store from './store';
import langConfig from './langConfig';
import { AutomationOnIcon, AutomationOffIcon } from './Icons/AryaIcons';
import ManualSearchWrapperV1 from './Containers/ManualSearch/ManualSearchWrapper/ManualSearchWrapper';
import ManualSearchWrapperV2 from './Containers/ManualSearchV2/ManualSearchWrapper/ManualSearchWrapper';
import { getFetchUserConfigApiStatus } from './Reducers/ConfigReducer';
import { getLoadCurrentUserApiStatus } from './Reducers/UserSessionReducer';
import ListOfLists from './Containers/Lists/ListOfLists';
import PackageSegmentsDetailContainerConfig from './Package/SegmentDetailsContainerPackage';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import ScoutingAgentInsideIcon from './Containers/ManualSearch/ScoutingAgent/ScoutingAgentInsideIcon';
import AllConversations from './Components/AllConversations/AllConversations';
import { getAutomationConfiguration } from './Utils/AutomationUtils';


if (window.locale == 'es-ES') {
  window.appLocale = langConfig.es;
  moment.locale('es');
} else {
  window.appLocale = langConfig.en;
  moment.locale('en');
}
console.log({ windowLocale: window.locale, window, appLocale: window.appLocale });
const { appLocale } = window;
addLocaleData(appLocale.data);

const attachStore = container => {
  return (
    <LocaleProvider locale={appLocale.antd}>
      <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
        <Provider store={store}>{container}</Provider>
      </IntlProvider>
    </LocaleProvider>
  );
};

class DtmfTone {
  play = () => { };
}

const ManualSearchWrapperWithStore = props => {
  const { configApiStatus, loadCurrentUserApiStatus, featureToggleList } = props;
  if (configApiStatus !== 'SUCCESS' && loadCurrentUserApiStatus !== 'SUCCESS') return <LoadingScreen />;
  const ManualSearchWrapper = featureToggleList?.AdvanceSearchV2?.IsEnabled
    ? ManualSearchWrapperV2
    : ManualSearchWrapperV1;
  return (
    <>
      <AppAlertNotificationContainer />
      <ManualSearchWrapper jobId={props?.jobId}>
        <PackageCandidateList {...props} version="ats" />
      </ManualSearchWrapper>
    </>
  );
};

const NewManualSearchContainerWithStore = props => {
  const {
    jobId,
    defaultIsManualSearchFormVisible,
    defaultIsManualSearchFormMinimized,
    onSearchCandidatesCallback,
    featureToggleList,
    configApiStatus,
    loadCurrentUserApiStatus,
  } = props;
  if (configApiStatus !== 'SUCCESS' && loadCurrentUserApiStatus !== 'SUCCESS') return <LoadingScreen />;

  const {
    AdvanceSearchV2: { IsEnabled: isAdvancedSearchV2Enabled },
    PrefetchAdvanceSearchResults: { IsEnabled: isPrefetchAdvanceSearchResultsEnabled },
    PreferredKeywordAutoSelect: { IsEnabled: isPreferredKeywordAutoSelectEnabled },
  } = featureToggleList;

  const ManualSearchWrapper = isAdvancedSearchV2Enabled ? ManualSearchWrapperV2 : ManualSearchWrapperV1;
  const ManualSearchContainer = isAdvancedSearchV2Enabled ? ManualSearchContainerV2 : ManualSearchContainerV1;
  return (
    <>
      {' '}
      <AppAlertNotificationContainer />
      <ManualSearchWrapper jobId={jobId}>
        <ManualSearchContainer
          jobId={jobId}
          version="ats"
          defaultIsManualSearchFormVisible={defaultIsManualSearchFormVisible}
          defaultIsManualSearchFormMinimized={defaultIsManualSearchFormMinimized}
          onSearchCandidatesCallback={onSearchCandidatesCallback}
          candidateContext="job"
          isPrefetchEnabled={isPrefetchAdvanceSearchResultsEnabled}
          isPreferredKeywordAutoSelectEnabled={isPreferredKeywordAutoSelectEnabled}
        />
      </ManualSearchWrapper>
    </>
  );
};

const ManualSearchContainerWithStore = props => {
  return attachStore(<PackedNewManualSearchContainerWithStore {...props} />);
};

const CandidateListWithStore = props => {
  return attachStore(<PackagedManualSearchWrapperWithStore {...props} />);
};

const mapStateToPropsCandidateViewAndAdv = state => {
  return {
    configApiStatus: getFetchUserConfigApiStatus(state),
    loadCurrentUserApiStatus: getLoadCurrentUserApiStatus(state),
    featureToggleList: getFeatureToggleList(state),
  };
};

const PackagedManualSearchWrapperWithStore = connect(mapStateToPropsCandidateViewAndAdv)(ManualSearchWrapperWithStore);
export const PackedNewManualSearchContainerWithStore = connect(mapStateToPropsCandidateViewAndAdv)(
  NewManualSearchContainerWithStore
);

const BotConfigWithStore = props => attachStore(<PackageBotConfig {...props} />);

const ConnectContainerWithStore = props => attachStore(<ConnectContainer {...props} />);

const ScoutingAgentPackageWithStore = props => attachStore(<ScoutingAgentInsideIcon {...props} />);

const ConnectWithStore = ({
  notesContainer,
  openAtsView,
  jobClickCallback,
  postCallNotes,
  dialingTone,
  ringingTone,
  dtmfTone = new DtmfTone(),
  callNotesContainer,
  openAryaCandidateView,
  OpenSipCallWindowsApp,
  history,
  location,
  match,
  openJobViewInNewTabCallBack,
  onClickAddJobCallBack,
}) => {
  const { params } = match;
  const queryParams = queryString.parse(location.search);
  const { type, tab, workflowId } = queryParams;
  const previewWorkflowId = params.workflowId;
  const createWorkflowId = location?.state?.workflowId;
  return attachStore(
    <>
      <AppToastNotification />
      <BackTop style={{ right: '40px', bottom: '20px' }} visibilityHeight={100}>
        <BackTopIcon style={{ transform: 'scale(1.5)' }} />
      </BackTop>
      <ConnectUrlMapper
        location={location}
        setUrl={url => history.push(url)}
        type={type}
        tab={tab}
        workflowId={workflowId}
        previewWorkflowId={previewWorkflowId}
        createWorkflowId={createWorkflowId}
        atsProps={{
          version: 'ats',
          notesContainer,
          openAtsView,
          jobClickCallback,
          postAtsCallNotes: postCallNotes,
          dialingTone,
          ringingTone,
          dtmfTone,
          callNotesContainer,
          openAryaCandidateView,
          openSipCallWindowsApp: OpenSipCallWindowsApp,
          openJobViewInNewTabCallBack,
          onClickAddJobCallBack,
        }}
      />
    </>
  );
};

const SegmentDetailContainerWithoutStore = ({
  history,
  location,
  segmentId,
  onSegmentCreationCallback,
  openAtsView,
  openAryaCandidateView,
  openWorkflowinNewTab,
  fetchUserApiStatus,
  OpenSipCallWindowsApp,
  openSegmentAtsView,
  fetchCountries,
  openJobViewInNewTabCallBack,
  onClickAddJobCallBack,
  openListOfList,
  notesContainer,
  callNotesContainer,
  postAtsCallNotes: postCallNotes,
  atsFetchBulkNotes,
}) => {
  if (fetchUserApiStatus === 'PENDING') {
    return <LoadingScreen active loading />;
  }
  return (
    <>
      <AppToastNotification />
      <PackageSegmentsDetailContainerConfig
        segmentId={segmentId}
        location={location}
        onSegmentCreationCallback={onSegmentCreationCallback}
        setUrl={url => history.push(url)}
        openAtsView={openAtsView}
        openAryaCandidateView={openAryaCandidateView}
        openWorkflowinNewTab={openWorkflowinNewTab}
        openSipCallWindowsApp={OpenSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        fetchCountries={fetchCountries}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        openListOfList={openListOfList}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postCallNotes}
        atsFetchBulkNotes={atsFetchBulkNotes}
      />
    </>
  );
};

const _mapStateToProps = state => ({
  fetchUserApiStatus: getFetchUserConfigApiStatus(state),
});
const _mapDispatchToProps = { fetchCountries: JobActions.fetchCountries };

const ConnectedSegmentDetailContainer = connect(
  _mapStateToProps,
  _mapDispatchToProps
)(SegmentDetailContainerWithoutStore);
const SegmentDetailContainerWithStore = props => attachStore(<ConnectedSegmentDetailContainer {...props} />);

class JobSettingsWithoutStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisibility: false,
    };
  }

  updateContainerJobConfig = () => {
    const { jobId, fetchJobConfig } = this.props;
    fetchJobConfig(jobId);
  };

  setDrawerVisibility = () => {
    const { drawerVisibility } = this.state;
    this.setState({ drawerVisibility: !drawerVisibility });
  };

  render() {
    const { jobId, userEmail, featureToggleList, jobsById, openWorkflowinNewTab, fetchUserApiStatus } = this.props;
    const { drawerVisibility } = this.state;
    const jobDetails = _.get(jobsById, jobId, {});
    const workflowAutomationConfiguration = _.get(jobDetails, ['AutomationConfiguration', 'Workflows'], []);
    const autoWorkflow = workflowAutomationConfiguration?.[0];

    const isAutoEmailEnabled = _.get(jobDetails, ['AutomationConfiguration', 'Email', 'IsEnabled'], false);
    const isAutoMessageEnabled = _.get(jobDetails, ['AutomationConfiguration', 'Message', 'IsEnabled'], false);
    const isAutoWorkflowEnabled = _.get(autoWorkflow, 'IsEnabled', false);

    const isAutomationEnabled = isAutoEmailEnabled || isAutoMessageEnabled || isAutoWorkflowEnabled;

    let automationEnabledClassName = '';
    let automationLeftRadiusClassName = '';

    const isJobConfigurationEnabled = _.get(featureToggleList, ['JobConfiguration', 'IsEnabled'], false);
    if (isJobConfigurationEnabled) {
      automationLeftRadiusClassName = 'automation-left-radius';
    }
    if (isAutomationEnabled) {
      automationEnabledClassName = 'automation-on';
    }

    return (
      <>
        {fetchUserApiStatus === 'SUCCESS' ? (
          <JobSettings
            jobId={jobId}
            drawerVisibility={drawerVisibility}
            toggleSettingsDrawer={this.setDrawerVisibility}
            connectToStore
            updateContainerJobConfig={this.updateContainerJobConfig}
            SourcingConfiguration={_.get(jobDetails, ['SourcingConfiguration'], {})}
            AutomationConfiguration={getAutomationConfiguration(_.get(jobConfig, 'AutomationConfiguration', {}), featureToggleList)}
            AryaRejectionConfiguration={_.get(jobDetails, ['AryaRejectionConfiguration'], { IsEnabled: false })}
            currentUserDetails={{ email: userEmail }}
            version="ats"
            isSourcingAllowed
            isCandidateAutoRejectAllowed
            isSendToOptionAllowed
            isEmailAutomationNoteAllowed
            isMessageAutomationNoteAllowed
            openWorkflowinNewTab={openWorkflowinNewTab}
          />
        ) : null}
        {isJobConfigurationEnabled ? (
          <div
            role="presentation"
            onClick={this.setDrawerVisibility}
            className={`automation-config ${automationEnabledClassName} ${automationLeftRadiusClassName}`}
          >
            {isAutomationEnabled ? (
              <AutomationOnIcon key="automationOnIcon" className="automation-icon" />
            ) : (
              <AutomationOffIcon key="automationOffIcon" className="automation-icon" />
            )}
          </div>
        ) : null}
        <AppToastNotification />
      </>
    );
  }
}

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
  fetchUserApiStatus: getFetchUserConfigApiStatus(state),
  jobsById: getJobsById(state),
});

const mapDispatchToProps = {
  fetchJobConfig: JobActions.fetchJobConfig,
};

const ConnectedJobSettings = connect(mapStateToProps, mapDispatchToProps)(JobSettingsWithoutStore);
const JobSettingsWithStore = props => attachStore(<ConnectedJobSettings {...props} />);

const JobIntelWithStore = ({ jobId }) => {
  return attachStore(<JobIntelContainerWithoutRouter match={{ params: { jobId } }} version="ats" />);
};

class ActivationDialogWithStore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      jobSourcingStats: {},
      jobSourcingStatus: {},
      jobDetails: {},
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showActivationDialog = this.showActivationDialog.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { jobId } = this.props;

    fetchJobDetails(jobId).then(response => {
      this.setState({ jobDetails: response.data });
    });
    fetchJobSourcingStats([jobId]).then(response => {
      this.setState({ jobSourcingStats: _.get(response, ['data', jobId], {}) });
    });
    fetchActivationDetails([jobId]).then(response => {
      this.setState({ jobSourcingStatus: _.get(response, ['data', jobId], {}) });
    });
  }

  handleCancel() {
    this.setState({ visible: false });
    setTimeout(this.fetchData, 1000);
  }

  showActivationDialog() {
    this.setState({ visible: true });
  }

  render() {
    const { jobId, showReviewDetails, featureToggleList } = this.props;
    const { visible, jobSourcingStats, jobSourcingStatus, jobDetails } = this.state;

    return attachStore(
      <>
        <ActivationDialogContainer
          version="ats"
          jobId={jobId}
          visible={visible}
          handleCancel={this.handleCancel}
          showReviewDetails={showReviewDetails}
          activeLocation="candidateList"
          job={jobDetails}
        />

        <div className="portal-activation-wrapper">
          <div className="portal-activation" onClick={this.showActivationDialog} role="presentation">
            <SourcingProgressIndicator
              jobSourcingStats={jobSourcingStats || {}}
              recentSourcedTime={jobSourcingStatus.RecentSourcedTime}
              lastChangeTime={jobSourcingStatus.LastChangeTime}
              sourcingStatus={!Object.values(jobSourcingStatus.Sources || {}).every(portal => !portal.IsActivated)}
            />
          </div>
        </div>
      </>
    );
  }
}

const CandidateNotesList = ({ notes }) => {
  return notes.map(note => (
    <CandidateNotes notes={note.content} time={note.time} user={note.user} activityType={note.activityType} />
  ));
};

const CandidateNotesForm = ({ postNotes, currentNotes, onNotesChange, isCallNotes, candidate }) => {
  return (
    <NotesForm
      postNotes={postNotes}
      currentNotes={currentNotes}
      onNotesChange={onNotesChange}
      isCallNotes={isCallNotes}
      candidate={candidate}
    />
  );
};

const CallDialogWithStore = ({
  callNotesContainer,
  dialingTone,
  ringingTone,
  postAtsCallNotes,
  OpenSipCallWindowsApp,
  dtmfTone = new DtmfTone(),
}) => {
  return attachStore(
    <CallDialogContainer
      callNotesContainer={callNotesContainer}
      postAtsCallNotes={postAtsCallNotes}
      dialingTone={dialingTone}
      ringingTone={ringingTone}
      dtmfTone={dtmfTone}
      openSipCallWindowsApp={OpenSipCallWindowsApp}
    />
  );
};

const JobInitiatorWithStore = ({ jobId }) => {
  return attachStore(<JobInitiator jobId={jobId ? parseInt(jobId, 10) : null} />);
};

const BotSettingsWithStore = () => {
  return attachStore(<BotSettings />);
};

const UserSMTPSettingsWithStore = () => {
  return attachStore(<EmailConfigurationContainer />);
};
const JobBoardSettingsWithStore = () => {
  return attachStore(<PackageJobBoardSettings />);
};
const ContactSettingsWithStore = () => {
  return attachStore(<PackageContactSettings />);
};
const ApplicationSettingsWithStore = () => {
  return attachStore(<PackageApplicationSettings />);
};
const CandidateJobMatchingWithStore = ({
  openJobViewInNewTabCallBack,
  onClickAddJobCallBack,
  candidateId,
  candidateTitle,
  candidateSkills,
  candidateCountry,
  candidateSources,
  candidateKeywords,
  version,
}) => {
  const candidate = {
    Id: candidateId,
    Title: candidateTitle,
    Skills: candidateSkills,
    Country: candidateCountry,
    Sources: candidateSources,
    Keywords: candidateKeywords,
  };
  return attachStore(
    <>
      <AppToastNotification />
      <PackageJobMatchingModal
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        candidate={candidate}
        version={version}
        isATSViewActive
      />
    </>
  );
};
const AdminSettingsWithStore = () => {
  return attachStore(<PackageAdminSettings />);
};
const SettingsWithStore = ({ defaultActiveKey }) => {
  return attachStore(
    <>
      <AppToastNotification />
      <PackageSettings defaultActiveKey={defaultActiveKey} />
    </>
  );
};

const AdminSMTPSettingsWithStore = () => {
  return attachStore(<AdminSMTPSettingsContainer version="ats" />);
};

const SegmentWithStore = ({ onSegmentCreationCallback }) => {
  return attachStore(<ListOfLists onSegmentCreationCallback={onSegmentCreationCallback} version="ats" />);
};

const AllConversationsWithStore = props => {
  return attachStore(<AllConversations {...props} version="ats" />);
};

const NewCandidateViewWithStore = props => {
  const {
    configApiStatus,
    loadCurrentUserApiStatus,
    candidateId,
    jobId,
    highlights,
    mustHaves,
    src,
    portal,
    openAtsView,
    notesContainer,
    callNotesContainer,
    postCallNotes,
    dialingTone,
    ringingTone,
    dtmfTone,
    appName,
    openSipCallWindowsApp,
    openSegmentAtsView,
    candidateContext = 'job',
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
  } = props;
  if (configApiStatus !== 'SUCCESS' && loadCurrentUserApiStatus !== 'SUCCESS') return <LoadingScreen />;

  return (
    <ManualSearchWrapperV2 jobId={jobId}>
      <CandidateView
        candidateId={candidateId}
        jobId={jobId}
        version="ats"
        candidateViewVersion="v3"
        highlights={highlights}
        mustHaves={mustHaves}
        src={src}
        portal={portal}
        openAtsView={openAtsView}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postCallNotes}
        dialingTone={dialingTone}
        ringingTone={ringingTone}
        dtmfTone={dtmfTone}
        appName={appName}
        openSipCallWindowsApp={openSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        candidateContext={candidateContext}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
      />
    </ManualSearchWrapperV2>
  );
};

const PackagedNewCandidateViewWithStoreWithStore = connect(mapStateToPropsCandidateViewAndAdv)(
  NewCandidateViewWithStore
);

const CandidateViewWithStore = ({
  candidateId,
  jobId,
  highlights,
  mustHaves,
  src,
  portal,
  openAtsView,
  notesContainer,
  callNotesContainer,
  postCallNotes,
  dialingTone,
  ringingTone,
  dtmfTone,
  appName,
  OpenSipCallWindowsApp,
  openSegmentAtsView,
  candidateContext = 'job',
  openJobViewInNewTabCallBack,
  onClickAddJobCallBack,
}) => {
  return attachStore(
    <PackagedNewCandidateViewWithStoreWithStore
      candidateId={candidateId}
      jobId={jobId}
      version="ats"
      candidateViewVersion="v3"
      highlights={highlights}
      mustHaves={mustHaves}
      src={src}
      portal={portal}
      openAtsView={openAtsView}
      notesContainer={notesContainer}
      callNotesContainer={callNotesContainer}
      postAtsCallNotes={postCallNotes}
      dialingTone={dialingTone}
      ringingTone={ringingTone}
      dtmfTone={dtmfTone}
      appName={appName}
      openSipCallWindowsApp={OpenSipCallWindowsApp}
      openSegmentAtsView={openSegmentAtsView}
      candidateContext={candidateContext}
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
    />
  );
};

const setCandidateNotesCountWithStore = (candidateId, candidateNotesCount) =>
  store.dispatch(setCandidateNotesCount(candidateId, candidateNotesCount));

const ConnectWithRouter = withRouter(ConnectWithStore);

export {
  CandidateListWithStore,
  ConnectWithRouter as ConnectWithStore,
  BotConfigWithStore,
  ConnectContainerWithStore,
  ScoutingAgentPackageWithStore,
  JobIntelWithStore,
  ActivationDialogWithStore,
  CandidateNotesList,
  CandidateNotesForm,
  CallNotes,
  JobSettingsWithStore,
  CallDialogWithStore,
  ConnectBotWindow,
  JobInitiatorWithStore,
  BotSettingsWithStore,
  CandidateViewWithStore,
  setBulkCandidateNotes,
  setBulkCallNotesUsingCandidateIds,
  setCandidateNotesCountWithStore,
  UserSMTPSettingsWithStore,
  AdminSMTPSettingsWithStore,
  ManualSearchContainerWithStore,
  JobBoardSettingsWithStore,
  ContactSettingsWithStore,
  ApplicationSettingsWithStore,
  CandidateJobMatchingWithStore,
  AdminSettingsWithStore,
  SettingsWithStore,
  SegmentWithStore,
  SegmentDetailContainerWithoutStore,
  SegmentDetailContainerWithStore,
  NewCandidateViewWithStore,
  AllConversationsWithStore,
};

export { setConfig };
