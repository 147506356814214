import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import styles from './ATSAuthentication.module.scss';
import message from '../../../ManualSearchV2/ManualSearchMessages';
import ATSAuthentication from './ATSAuthentication';
import ATSDisconnectConfirmationModal from './ATSDisconnectConfirmationModal';
import * as ATSAuthenticationActions from '../../../../Actions/ATSAuthenticationActions';
import { getAtsConfig } from '../../../../Reducers/AtsReducer';

const mapStateToProps = state => ({
  atsConfig: getAtsConfig(state),
});

const mapDispatchToProps = {
  disconnectATS: ATSAuthenticationActions.disconnectATS,
};

function ATSAuthenticationWrapper(props) {
  const { atsConfig, disconnectATS } = props;
  const list = [{ key: 'LEVER', name: 'Lever by employ' }];
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const colCount = 3;
  const rowCount = Math.ceil(list.length / colCount);
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < rowCount; i += 1) {
      const rowItems = list.slice(i * colCount, (i + 1) * colCount);
      rows.push(
        <Row gutter={[16, 16]}>
          {rowItems.map(item => (
            <Col span={8}>
              <ATSAuthentication
                atsKey={item.key}
                atsName={item.name}
                setConfirmationModalVisible={setConfirmationModalVisible}
              />
            </Col>
          ))}
        </Row>
      );
    }
    return rows;
  };

  return (
    <>
      <div className={styles.aTSAuthenticationWrapper}>
        <div className={styles.chooseYourATS}>
          <FormattedMessage {...message.chooseYourATS} />
        </div>
        <div className={styles.ATSAuthentication}> {renderRows()}</div>
      </div>
      <ATSDisconnectConfirmationModal
        setConfirmationModalVisible={setConfirmationModalVisible}
        isConfirmationModalVisible={isConfirmationModalVisible}
        atsConfig={atsConfig}
        disconnectATS={disconnectATS}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ATSAuthenticationWrapper);
