import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as UserActions from '../../Actions/UserActions';
import * as JobActions from '../../Actions/JobActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as EmailTemplatesActions from '../../Actions/EmailTemplates';
import * as MessageTemplatesActions from '../../Actions/TextTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as AryabotContextActions from '../../Actions/AryabotContextActions';
import * as PaymentActions from '../../Actions/AryaPayActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import JobSettings from '../JobSettings/JobSettings';
import JobFormComponent from '../../Components/JobForm/JobFormComponent';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getJobUtilities, getJobsById, getSkillSet } from '../../Reducers/JobReducer';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getJobStatus, getDefaultStatusId, getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getCurrentUser, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { getEmailTemplatesById, getEmailTemplatesCount, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import { getTextTemplatesById, getTextTemplatesCount, getTextTemplateIds } from '../../Reducers/TextTemplates';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import { getUsersById } from '../../Reducers/UserReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getUserByUserGuid } from '../../Utils/UserInfo';
import {
  getUserGuids,
  getJobDetailsForAnalytics,
  getIsLoading,
  getIsFreeCandidateStepper,
  isWorkflowConfigurationUpdated,
} from '../../Utils/JobUtils';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../Utils/CreditInfoUtils';
import {
  getProductVariantsById,
  getProductVariants,
  getPlanDetailsByProductVariantId,
} from '../../Reducers/AryaPayOrderReducer';
import { getCreateOrderApiStatus } from '../../Reducers/AryaPayReducer';
import { NORMAL_JOB_SAVE_DRAFT, SAVE_JOB } from '../../PubSub/EventTypes';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import { getJobCandidateLink } from '../../Utils/LinkUtils/LinkUtils';
import { setCandidatesViewedStats, raiseGtmEvents } from '../../Utils/PubSubUtils';
import ConfirmationDialogueBox from '../../Components/AdvancedJobCreation/ConfirmationDialogueBox/ConfirmationDialogueBox';
import BotConfigPopup from '../BotConfig/BotConfigPopup';
import BotPrequalPopup from '../../Components/Connect/BotConfig/BotPrequalPopup';
import { getCurrentClientId, getCurrentClientName } from '../../Reducers/ClientReducer';
import { clearCurrentClientCreationInfo as _clearCurrentClientCreationInfo } from '../../Actions/ClientActions';
import { getAutomationConfiguration, getIsAutomationConfigured } from '../../Utils/AutomationUtils';

const publishEvent = (jobDetails, submitAction, isFreeCandidateStepper) => {
  const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
  const gtagPayload = {
    jobId: jobDetails?.JobId,
    jobTitle: jobDetails?.JobTitle,
    isAdvanced: jobDetails?.IsAdvancedJob,
    jobDetails: analyticsJobDetails,
  };
  if (submitAction?.toLowerCase() === 'savedraft') {
    PubSub.publish(NORMAL_JOB_SAVE_DRAFT, gtagPayload);
  } else if (!isFreeCandidateStepper && submitAction !== 'savedraft') {
    PubSub.publish(SAVE_JOB, gtagPayload);
  }
};

const mapStateToProps = state => ({
  jobUtilities: getJobUtilities(state),
  jobStatus: getJobStatus(state),
  defaultStatusId: getDefaultStatusId(state),
  userConfig: getConfig(state) || {},
  jobsById: getJobsById(state),
  usersById: getUsersById(state),
  editJobStatus: getApiStatus(state, 'editJobStatus'),
  emailTemplatesCount: getEmailTemplatesCount(state),
  messageTemplatesCount: getTextTemplatesCount(state),
  emailTemplateIds: getEmailTemplateIds(state),
  dripTemplatesById: getDripTemplatesById(state),
  messageTemplateIds: getTextTemplateIds(state),
  emailTemplatesById: getEmailTemplatesById(state),
  messageTemplatesById: getTextTemplatesById(state),
  currentUser: getCurrentUser(state),
  titleSynonymStatusUpdate: getApiStatus(state, 'titleSynonymStatusUpdate'),
  tagsFetchStatusUpdate: getApiStatus(state, 'tagsFetchStatusUpdate'),
  featureToggleList: getFeatureToggleList(state),
  skillsFetchStatusUpdate: getApiStatus(state, 'skillsFetchStatusUpdate'),
  skillSetForJob: getSkillSet(state),
  productVariantrsById: getProductVariantsById(state),
  getJobQuestionApiStatus: getApiStatus(state, 'getJobQuestionApiStatus'),
  tryNowCandidateApiStatus: getApiStatus(state, 'fetchTryNowCandidateApiStatus'),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  role: getConfig(state) ? getConfig(state).Role : 'Recruiter',
  currentClientId: getCurrentClientId(state),
  currentClientName: getCurrentClientName(state),
  productVariants: getProductVariants(state),
  createOrderApiStatus: getCreateOrderApiStatus(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  currentUserDetails: getCurrentUserDetails(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  putEditJob: JobActions.putEditJob,
  patchEditJob: JobActions.patchEditJob,
  fetchTitleSynonyms: JobActions.fetchTitleSynonyms,
  clearTitleSynonyms: JobActions.clearTitleSynonyms,
  fetchJobDetails: JobActions.fetchJobDetails,
  fetchCountries: JobActions.fetchCountries,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetZipcodes: JobActions.resetZipcodes,
  fetchClients: JobActions.fetchClients,
  fetchIndustries: JobActions.fetchIndustries,
  fetchJobStatus: JobStatusActions.fetchJobStatus,
  fetchUsersForShare: UserActions.fetchUsersForDropdownLists,
  shareJob: JobActions.shareJob,
  searchZipCode: JobActions.searchZipCode,
  clearZipCodeError: JobActions.clearZipCodeError,
  clearZipValidationError: JobActions.clearZipValidationError,
  updateJobConfig: JobActions.updateJobConfig,
  fetchJobConfig: JobActions.fetchJobConfig,
  fetchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  fetchMessageTemplates: MessageTemplatesActions.searchTextTemplates,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  downloadJobResume: JobActions.downloadJobResume,
  fetchTagsForJobForm: JobActions.fetchTagsForJobForm,
  updateTotalTagsCount: JobActions.updateTotalTagsCount,
  fetchSkills: JobActions.fetchSkills,
  getAnswers: AryabotContextActions.getAnswers,
  postAnswers: AryabotContextActions.postAnswers,
  getQuestions: AryabotContextActions.getQuestions,
  postQuestions: AryabotContextActions.postQuestions,
  fetchOrgActivatedJobsCount: JobActions.fetchOrgActivatedJobsCount,
  fetchTryNowCandidates: CandidateActions.fetchTryNowCandidates,
  setTrynowFormData: JobActions.setTrynowFormData,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
  saveJobDiversityChanges: JobActions.saveJobDiversityChanges,
  clearCurrentClientCreationInfo: _clearCurrentClientCreationInfo,
  createOrder: PaymentActions.createOrder,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  fetchOrgOpenedJobsCount: JobActions.fetchOrgOpenedJobsCount,
  fetchActivationStatus: JobActions.fetchActivationStatus,
};

class JobEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toShareWith: [],
      toUnshareWith: [],
      settingsDrawerVisibility: false,
      jobConfig: {},
      botModalVisibility: false,
      botPrequalPopupVisibility: false,
      confirmationDialogueBoxVisibility: false,
      currentJobData: {},
    };
    this.replaceJob = this.replaceJob.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.onClose = this.onClose.bind(this);
    this.toggleSettingsDrawer = this.toggleSettingsDrawer.bind(this);
    this.changeJobConfig = this.changeJobConfig.bind(this);
    this.changeAutoEmailEnabled = this.changeAutoEmailEnabled.bind(this);
    this.changeAutoMessageEnabled = this.changeAutoMessageEnabled.bind(this);
    this.changeSourceLimit = this.changeSourceLimit.bind(this);
    this.placeJob = this.placeJob.bind(this);
    this.replaceJobForPulse = this.replaceJobForPulse.bind(this);
  }

  componentDidMount() {
    const {
      fetchJobDetails,
      match,
      fetchJobStatus,
      fetchOrgActivatedJobsCount,
      fetchPlanDetailsByProductVariantId,
      fetchOrgOpenedJobsCount,
    } = this.props;
    fetchJobStatus();
    fetchJobDetails(match.params.jobId);
    this.toShareJob = this.toShareJob.bind(this);
    fetchOrgActivatedJobsCount();
    fetchPlanDetailsByProductVariantId();
    fetchOrgOpenedJobsCount();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, history, featureToggleList } = nextProps;
    const { jobId } = match.params;
    if (_.get(nextProps, ['jobsById', jobId, 'ErrorCode'], undefined) === 403) {
      history.push(`/jobs/${match.params.jobId}/forbidden`);
    }
    if (_.get(nextProps, ['jobsById', jobId, 'ErrorCode'], undefined) === 404) {
      history.push(`/jobs/${match.params.jobId}/notfound`);
    }
    const automationConfig = _.get(nextProps, ['jobsById', jobId, 'AutomationConfiguration'], null);
    const sourcingConfig = _.get(nextProps, ['jobsById', jobId, 'SourcingConfiguration'], null);
    const workflowAutomationConfiguration = automationConfig?.Workflows?.[0];
    if (
      prevState.isAutoEmailEnabled === undefined &&
      prevState.isAutoMessageEnabled === undefined &&
      automationConfig &&
      sourcingConfig
    ) {
      const isAutoEmailEnabled =
        getIsAutomationConfigured(automationConfig, featureToggleList, 'Email') ||
        getIsAutomationConfigured(automationConfig, featureToggleList, 'Workflows');
      const isAutoMessageEnabled = getIsAutomationConfigured(automationConfig, featureToggleList, 'Message');
      return {
        jobConfig: {
          SourcingConfiguration: {
            ...sourcingConfig,
            minSourceLimit: _.get(sourcingConfig, ['SourceLimit']),
          },
          AutomationConfiguration: automationConfig,
        },
        isAutoEmailEnabled,
        isAutoMessageEnabled,
      };
    }
    return prevState;
  }

  componentDidUpdate() {
    const { match, jobsById, fetchZipCodes } = this.props;
    const { jobId } = match.params;
    const { Location, CountryCode } = jobsById[jobId] || {};
    if (!this.initialZipcodeListFetched && Location && CountryCode) {
      fetchZipCodes({ country: CountryCode, location: Location });
      this.initialZipcodeListFetched = true;
    }
    // TODO if ai job redirect accordingly
  }

  toggleSettingsDrawer() {
    const { settingsDrawerVisibility } = this.state;
    this.setState({
      settingsDrawerVisibility: !settingsDrawerVisibility,
    });
  }

  changeJobConfig(jobConfig) {
    const workflowAutomationConfiguration = _.get(jobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0];
    const emailTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], undefined);
    const workflowTemplateId = _.get(workflowAutomationConfiguration, ['WorkflowDefinitionId'], undefined);
    const messageTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], undefined);
    this.setState({
      jobConfig,
    });
    if (emailTemplateId) {
      this.changeAutoEmailEnabled(_.get(jobConfig, ['AutomationConfiguration', 'Email', 'IsEnabled'], false));
    }
    if (workflowTemplateId) {
      this.changeAutoEmailEnabled(_.get(workflowAutomationConfiguration, ['IsEnabled'], false));
    }
    if (messageTemplateId) {
      this.changeAutoMessageEnabled(_.get(jobConfig, ['AutomationConfiguration', 'Message', 'IsEnabled'], false));
    }
  }

  changeSourceLimit(sourceLimit) {
    const { jobConfig } = this.state;
    this.setState({
      jobConfig: {
        ...jobConfig,
        SourcingConfiguration: { ...jobConfig.SourcingConfiguration, SourceLimit: sourceLimit },
      },
    });
  }

  changeAutoEmailEnabled(status) {
    this.setState({
      isAutoEmailEnabled: status,
    });
  }

  changeAutoMessageEnabled(status) {
    this.setState({
      isAutoMessageEnabled: status,
    });
  }

  onClose() {
    const { history, clearTitleSynonyms, match, resetZipcodes } = this.props;
    history.push(`/jobs/${match.params.jobId}`);
    clearTitleSynonyms();
    resetZipcodes();
  }

  isEmailConfigUpdated(jobDetails) {
    const { jobConfig } = this.state;
    const { AutomationConfiguration } = jobDetails;
    const currentTemplateId = _.get(AutomationConfiguration, ['Email', 'TemplateId'], null);
    const currentTemplateSendTo = _.get(AutomationConfiguration, ['Email', 'SendTo'], []).map(sendTo =>
      sendTo.toLowerCase()
    );
    const currentTemplateFrom = _.get(AutomationConfiguration, ['Email', 'From'], null);
    const currentEmailIsEnabled = _.get(AutomationConfiguration, ['Email', 'IsEnabled'], false);

    const selectedEmailTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], null);
    const selectedEmailTemplateSendTo = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'SendTo'], []).map(
      sendTo => sendTo.toLowerCase()
    );
    const selectedEmailTemplateFrom = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'From'], null);
    const isAutoEmailEnabled = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'IsEnabled'], null);

    let isUpdated = false;
    isUpdated =
      currentEmailIsEnabled !== isAutoEmailEnabled ||
      currentTemplateId !== selectedEmailTemplateId ||
      !_.isEqual(selectedEmailTemplateSendTo, currentTemplateSendTo) ||
      currentTemplateFrom !== selectedEmailTemplateFrom;
    return isUpdated;
  }

  isWorkflowConfigUpdated(jobDetails) {
    const { jobConfig } = this.state;
    const { AutomationConfiguration } = jobDetails;
    const selectedWorkflowAutomationConfiguration = _.get(jobConfig, ['AutomationConfiguration', 'Workflows'])?.[0];

    const selectedWorkflowTemplateId = _.get(
      selectedWorkflowAutomationConfiguration,
      'WorkflowDefinitionId',
      undefined
    );
    const selectedWorflowTemplateSendTo = _.get(selectedWorkflowAutomationConfiguration, 'SendTo', []).map(sendTo =>
      sendTo.toLowerCase()
    );
    const selectedWorkflowFromAddress = _.get(
      selectedWorkflowAutomationConfiguration,
      'EmailActivityFromAddress',
      null
    );
    const selectedWorkflowIsEnabled = _.get(selectedWorkflowAutomationConfiguration, 'IsEnabled', null);

    return isWorkflowConfigurationUpdated({
      AutomationConfiguration,
      selectedAutomationConfiguration: {
        definitionId: selectedWorkflowTemplateId,
        selectedEmailTemplateSendTo: selectedWorflowTemplateSendTo,
        selectedEmailTemplateFrom: selectedWorkflowFromAddress,
        isEmailTemplatePanelEnabled: selectedWorkflowIsEnabled,
      },
    });
  }

  isMessageConfigUpdated(jobDetails) {
    const { jobConfig } = this.state;
    const { AutomationConfiguration } = jobDetails;

    const currentTemplateId = _.get(AutomationConfiguration, ['Message', 'TemplateId'], null);
    const currentTemplateSendTo = _.get(AutomationConfiguration, ['Message', 'SendTo'], []).map(sendTo =>
      sendTo.toLowerCase()
    );
    const currentMessageIsEnabled = _.get(AutomationConfiguration, ['Message', 'IsEnabled'], false);

    const selectedMessageTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], null);
    const selectedMessageTemplateSendTo = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'SendTo'], []).map(
      sendTo => sendTo.toLowerCase()
    );
    const isAutoMessageEnabled = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'IsEnabled'], null);

    let isUpdated = false;
    isUpdated =
      currentMessageIsEnabled !== isAutoMessageEnabled ||
      currentTemplateId !== selectedMessageTemplateId ||
      !_.isEqual(selectedMessageTemplateSendTo, currentTemplateSendTo);

    return isUpdated;
  }

  async replaceJobForPulse(jobDetails, submitAction, resumeUpdateDetails) {
    const { confirmationDialogueBoxVisibility } = this.state;
    const {
      showSampleCandidates,
      usersById,
      history,
      putEditJob,
      planDetailsByProductVariantId,
      productVariantrsById,
    } = this.props;
    const newJobDetails = _.cloneDeep(jobDetails);
    newJobDetails.AssignedTo = getUserGuids(newJobDetails?.AssignedTo || [], usersById);
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantrsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantrsById);
    const isFreeCandidateStepper = getIsFreeCandidateStepper(
      availablePaidCredits,
      showSampleCandidates,
      freeJobCreditsCount
    );
    const redirectOptions = {
      history,
      redirectUrl: submitAction === 'SaveDraft' ? `/jobs` : `/select-plan`,
      search: submitAction === 'SaveDraft' ? '' : `?jobGuid=${newJobDetails.JobGuid}&jobId=${newJobDetails.JobId}`,
    };
    const res =
      submitAction === 'SaveDraft' || !isFreeCandidateStepper
        ? await putEditJob(newJobDetails.JobId, newJobDetails, redirectOptions, resumeUpdateDetails)
        : null;
    if (isFreeCandidateStepper && submitAction !== 'SaveDraft') {
      if (!confirmationDialogueBoxVisibility) {
        this.setState({ currentJobData: newJobDetails });
        this.toggleConfirmationDialogueBoxVisibility();
      }
    }
    publishEvent(res, submitAction, isFreeCandidateStepper);
  }

  async replaceJob(jobDetails, values, resumeUpdateDetails, submitAction) {
    const newJobDetails = _.cloneDeep(jobDetails);
    const { toShareWith, toUnshareWith, jobConfig } = this.state;
    const { putEditJob, shareJob, jobStatus, history, updateJobConfig, featureToggleList, fetchActivationStatus } =
      this.props;
    const { PaidJobService, CustomizeJobUrl } = featureToggleList;
    const { SourcingConfiguration } = jobDetails;
    Object.keys(values).forEach(key => {
      if (jobDetails[key] !== values[key]) {
        newJobDetails[key] = values[key];
      }
    });

    delete newJobDetails.ApplyUrl;
    if (!CustomizeJobUrl?.IsEnabled) {
      delete newJobDetails.ViewUrl;
    }

    if (_.get(newJobDetails, 'Description.description', true)) {
      newJobDetails.Description = jobDetails.Description;
    }
    delete newJobDetails.aryaState;
    delete newJobDetails.sourcingStats;
    if (!newJobDetails.MinExperience && newJobDetails.MinExperience !== 0) {
      newJobDetails.MinExperience = null;
    }
    if (!newJobDetails.MaxExperience && newJobDetails.MaxExperience !== 0) {
      newJobDetails.MaxExperience = null;
    }
    const status = jobStatus.find(elem => elem.Id === newJobDetails.StatusId);
    newJobDetails.Status = status.Name;
    newJobDetails.AryaStatus = status.AryaStatus;
    newJobDetails.GenderPreferences = jobDetails?.GenderPreferences;
    newJobDetails.CategoryPreferences = jobDetails?.CategoryPreferences;
    newJobDetails.Summary = jobDetails?.Summary;
    newJobDetails.AutomationConfiguration = getAutomationConfiguration(
      _.get(jobConfig, 'AutomationConfiguration', {}),
      featureToggleList
    )
    const primaryLocation = newJobDetails.SourcingLocations?.find(x => x.IsPrimaryLocation) || {};
    if (primaryLocation.Postalcode) newJobDetails.Zipcode = primaryLocation.Postalcode;
    // newJobDetails.MustHaves = newJobDetails.MustHaves && newJobDetails.MustHaves.join(',');
    // newJobDetails.NiceToHaves = newJobDetails.NiceToHaves && newJobDetails.NiceToHaves.join(',');
    // newJobDetails.EducationalQualifications =
    //   newJobDetails.EducationalQualifications && newJobDetails.EducationalQualifications.join(',');
    if (PaidJobService.IsEnabled) {
      this.replaceJobForPulse(newJobDetails, submitAction, resumeUpdateDetails);
    } else {
      await putEditJob(
        jobDetails.JobId,
        newJobDetails,
        { history, redirectUrl: `/jobs/${jobDetails.JobId}` },
        resumeUpdateDetails
      );
    }
    const sourceLimit = _.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], null);
    const isSourceLimitUpdated = _.get(SourcingConfiguration, ['SourceLimit'], null) !== sourceLimit;
    const isEmailConfigUpdated = this.isEmailConfigUpdated(jobDetails);
    const isWorkflowConfigUpdated = this.isWorkflowConfigUpdated(jobDetails);
    const isMessageConfigUpdated = this.isMessageConfigUpdated(jobDetails);
    await fetchActivationStatus([jobDetails.JobId]);
    if (
      !PaidJobService.IsEnabled &&
      (isSourceLimitUpdated || isWorkflowConfigUpdated || isEmailConfigUpdated || isMessageConfigUpdated)
    ) {
      updateJobConfig(jobDetails.JobId, { ...jobConfig, IsReactivationRequired: false });
    }

    if (toShareWith.length || toUnshareWith.length) {
      shareJob(
        jobDetails.JobId,
        { sharedWith: toShareWith, unsharedWith: toUnshareWith },
        { history, redirectUrl: `/jobs/${jobDetails.JobId}` }
      );
    }
  }

  updateJob(jobDetails, values) {
    const { jobsById, match } = this.props;
    const newJobDetails = {};
    const patchArray = [];
    const copyOfValues = values;
    const oldJobDetails = jobsById[match.params.jobId];
    const { patchEditJob } = this.props;
    Object.keys(jobDetails).forEach(key => {
      if (typeof copyOfValues[key] !== 'undefined') {
        if (jobDetails[key] !== copyOfValues[key]) {
          newJobDetails[key] = copyOfValues[key];
          const patchObj = {
            op: 'replace',
            path: key,
            value: copyOfValues[key],
          };
          patchArray.push(patchObj);
        }
      }
    });
    if (JSON.stringify(jobDetails.Synonyms) !== JSON.stringify(oldJobDetails.Synonyms)) {
      newJobDetails.Synonyms = jobDetails.Synonyms;
      const patchObj = {
        op: 'replace',
        path: 'Synonyms',
        value: jobDetails.Synonyms,
      };
      patchArray.push(patchObj);
    }
    patchEditJob(match.params.jobId, patchArray);
  }

  toShareJob(toShareWith, toUnshareWith) {
    this.setState({
      toShareWith,
      toUnshareWith,
    });
  }

  getJobSharedWithList() {
    const { usersById, jobsById, match } = this.props;
    const { jobId } = match.params;
    const { toShareWith, toUnshareWith } = this.state;
    let users = [];
    let allUsers = []; // this list include shared user and user created that job
    if (!toShareWith.length && !toUnshareWith.length) {
      if (usersById) {
        if (jobsById[jobId].CreatedBy) {
          allUsers.push(jobsById[jobId].CreatedBy);
        }
        allUsers = allUsers.concat(jobsById[jobId].SharedWith || []);
        allUsers = allUsers.concat(jobsById[jobId].AssignedTo || []);
      }
      users = allUsers.length ? _.uniq(allUsers).map(userId => _.get(usersById, [userId, 'Email'], null)) : [];
      users = users.filter(user => !!user);
      return users;
    }
    users = toShareWith.length ? toShareWith.map(userId => _.get(usersById, [userId, 'Email'], null)) : [];
    return users;
  }

  onClickSave = () => {
    this.setState({
      botPrequalPopupVisibility: false,
      botModalVisibility: true,
    });
    /*
    const { fetchJobDetails, getQuestions, getAnswers, match } = this.props;
    const { jobId } = match.params;
    const jobDetails = await fetchJobDetails(jobId);
    const botContextId = _.get(jobDetails, ['BotContextId']);
    getQuestions(botContextId);
    getAnswers(botContextId);  */
  };

  onBotClose = () => {
    this.setState({
      botModalVisibility: false,
    });
    this.onClickSkip();
  };

  onClickSkip = () => {
    const { history, jobsById, match } = this.props;
    const jobDetails = jobsById[match.params.jobId];
    this.setState({
      botPrequalPopupVisibility: false,
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${jobDetails.JobGuid}&jobId=${jobDetails.JobId}`,
      state: jobDetails,
    });
  };

  onBotCancel = () => {
    const { history, jobsById, match } = this.props;
    const jobDetails = jobsById[match.params.jobId];
    this.setState({
      botPrequalPopupVisibility: false,
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${jobDetails.JobGuid}&jobId=${jobDetails.JobId}`,
      state: jobDetails,
    });
  };

  hideBotModal = () => {
    this.setState({
      botPrequalPopupVisibility: false,
    });
  };

  handleBack = () => {
    const { history } = this.props;
    history.push('/jobs');
  };

  async placeJob(jobDetails, resumeUpdateDetails, submitAction) {
    const { putEditJob, createOrder, history, productVariants, currentUserDetails } = this.props;
    const res = await putEditJob(jobDetails?.JobId, jobDetails, null, resumeUpdateDetails?.uploadedResumeFiles);
    const freeTrialVariant = productVariants.filter(productVariant => productVariant.Name === FREE_TRIAL);
    const jobGuid = res.JobGuid;
    const jobId = res.JobId;
    if (submitAction !== 'draft') {
      const paymentData = {
        cardDetails: null,
        paymentProfileId: null,
        address: null,
      };
      const items = [
        {
          ProductVariantId: freeTrialVariant[0].Id,
          Quantity: 1,
          CreditsToUse: 1,
          RefIds: [jobGuid],
        },
      ];
      const orderResponse = await createOrder(items, paymentData, 'immediate');
      const orderResponseData = orderResponse.data;
      raiseGtmEvents(res, orderResponseData);
      const jobCandidateLink = getJobCandidateLink(jobId);
      history.push(jobCandidateLink);
      setCandidatesViewedStats([], currentUserDetails?.UserGuid);
    } else {
      history.push('/jobs');
    }
  }

  toggleConfirmationDialogueBoxVisibility = () => {
    const { confirmationDialogueBoxVisibility } = this.state;
    this.setState({
      confirmationDialogueBoxVisibility: !confirmationDialogueBoxVisibility,
    });
  };

  render() {
    const {
      toShareWith,
      toUnshareWith,
      settingsDrawerVisibility,
      isAutoEmailEnabled,
      isAutoMessageEnabled,
      jobConfig,
      botModalVisibility,
      botPrequalPopupVisibility,
      confirmationDialogueBoxVisibility,
      currentJobData,
    } = this.state;
    const {
      jobsById,
      fetchJobStatus,
      fetchTitleSynonyms,
      fetchCountries,
      fetchZipCodes,
      fetchClients,
      fetchIndustries,
      jobUtilities,
      jobStatus,
      defaultStatusId,
      match,
      usersById,
      editJobStatus,
      userConfig,
      searchZipCode,
      clearZipCodeError,
      clearZipValidationError,
      fetchJobConfig,
      downloadJobResume,
      fetchTagsForJobForm,
      updateTotalTagsCount,
      titleSynonymStatusUpdate,
      tagsFetchStatusUpdate,
      featureToggleList,
      fetchSkills,
      skillsFetchStatusUpdate,
      skillSetForJob,
      currentUser,
      getJobQuestionApiStatus,
      location,
      productVariantrsById,
      tryNowCandidateApiStatus,
      showSampleCandidates,
      setTrynowFormData,
      setSampleCandidatesDisplayFlag,
      saveJobDiversityChanges,
      role,
      currentClientId,
      currentClientName,
      clearCurrentClientCreationInfo,
      createOrderApiStatus,
      planDetailsByProductVariantId,
      whiteLabelInfo,
    } = this.props;
    let users = [];
    const userDetail = getUserByUserGuid(usersById, currentUser.sub);
    const isConfigLoaded = !!userConfig;
    const jobDetails = jobsById[match.params.jobId];
    if (defaultStatusId && jobDetails) {
      if (!toShareWith.length && !toUnshareWith.length) {
        let allUsers = []; // this list include shared user and user created that job
        if (usersById) {
          allUsers.push(jobDetails.CreatedBy);
          allUsers = allUsers.concat(jobDetails.SharedWith || []);
          allUsers = allUsers.concat(jobDetails.AssignedTo || []);
        }
        users = allUsers
          ? _.uniq(allUsers).map(userId => {
            return {
              name: usersById[userId] ? usersById[userId].FullName : null,
              Id: userId,
              Email: usersById[userId] ? usersById[userId].Email : null,
            };
          })
          : [];
        users = users.filter(user => !!user);
      } else {
        users = toShareWith.map(id => ({
          name: usersById[id] ? usersById[id].FullName : null,
          Id: id,
          Email: usersById[id] ? usersById[id].Email : null,
        }));
      }
      const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantrsById);
      const isLoadingTrue = getIsLoading(createOrderApiStatus, editJobStatus);
      const isAutoWorkflowConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0]
        ?.WorkflowDefinitionId;
      const isAutoEmailConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], false);
      const isAutoMessageConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], false);
      const isAutomationConfigured = isAutoEmailConfigured || isAutoWorkflowConfigured || isAutoMessageConfigured;
      const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantrsById);
      const isFreeCandidateStepper = getIsFreeCandidateStepper(
        availablePaidCredits,
        showSampleCandidates,
        freeJobCreditsCount
      );

      return (
        <div>
          <JobSettings
            SourcingConfiguration={_.get(jobConfig, 'SourcingConfiguration', {})}
            AutomationConfiguration={getAutomationConfiguration(
              _.get(jobConfig, 'AutomationConfiguration', {}),
              featureToggleList
            )}
            sourceLimit={_.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], null)}
            drawerVisibility={settingsDrawerVisibility}
            toggleSettingsDrawer={this.toggleSettingsDrawer}
            jobDetails={jobsById[match.params.jobId]}
            updateJobConfig={this.changeJobConfig}
            isSourcingAllowed
            isCandidateAutoRejectAllowed
            isSendToOptionAllowed
            isEmailAutomationNoteAllowed
            isMessageAutomationNoteAllowed
            isJobCreateOrUpdate
          />
          <BotConfigPopup
            title="Leoforce Pulse PreQualified Questions"
            subTitle="Questions candidates will be asked"
            botModalVisibility={botModalVisibility}
            onBotClose={this.onBotClose}
            jobId={match.params.jobId}
            onBotCancel={this.onBotCancel}
            cancelButtonText="Skip"
            saveButtonText="Save and Continue"
            hideSelectTemplate
            getJobQuestionApiStatus={getJobQuestionApiStatus}
          />
          <BotPrequalPopup
            botPrequalPopupVisibility={botPrequalPopupVisibility}
            onClickSkip={this.onClickSkip}
            onClickSave={this.onClickSave}
          />
          <JobFormComponent
            type="update"
            jobDetails={jobsById[match.params.jobId]}
            fetchTagsForJobForm={fetchTagsForJobForm}
            updateTotalTagsCount={updateTotalTagsCount}
            redirectUrl={`/jobs/${match.params.jobId}`}
            isAutoEmailEnabled={isAutoEmailEnabled}
            isAutoMessageEnabled={isAutoMessageEnabled}
            isAutomationConfigured={isAutomationConfigured}
            sourceLimit={_.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], null)}
            defaultStatusId={defaultStatusId}
            replaceJob={this.replaceJob}
            fetchJobConfig={fetchJobConfig}
            handleSubmit={this.replaceJob}
            fetchTitleSynonyms={fetchTitleSynonyms}
            fetchCountries={fetchCountries}
            fetchZipCodes={fetchZipCodes}
            fetchClients={fetchClients}
            fetchIndustries={fetchIndustries}
            fetchJobStatus={fetchJobStatus}
            utilities={jobUtilities}
            jobStatus={jobStatus}
            collaborators={users}
            toShareJob={this.toShareJob}
            handleClose={this.onClose}
            handleBack={this.handleBack}
            apiStatus={editJobStatus}
            isConfigLoaded={isConfigLoaded}
            searchZipCode={searchZipCode}
            clearZipCodeError={clearZipCodeError}
            clearZipValidationError={clearZipValidationError}
            userConfig={userConfig}
            toggleSettingsDrawer={this.toggleSettingsDrawer}
            changeSourceLimit={this.changeSourceLimit}
            downloadJobResume={downloadJobResume}
            titleSynonymStatusUpdate={titleSynonymStatusUpdate}
            tagsFetchStatusUpdate={tagsFetchStatusUpdate}
            featureToggleList={featureToggleList}
            minSourceValue={
              _.get(jobConfig, ['SourcingConfiguration', 'minSourceLimit']) ||
              _.get(userConfig, 'DefaultSourceLimit', 1)
            }
            fetchSkills={fetchSkills}
            skillsFetchStatusUpdate={skillsFetchStatusUpdate}
            skillSetForJob={skillSetForJob}
            currentUser={userDetail}
            location={location}
            productVariantrsById={productVariantrsById}
            tryNowCandidateApiStatus={tryNowCandidateApiStatus}
            showSampleCandidates={isFreeCandidateStepper}
            setTrynowFormData={setTrynowFormData}
            setSampleCandidatesDisplayFlag={setSampleCandidatesDisplayFlag}
            saveJobDiversityChanges={saveJobDiversityChanges}
            role={role}
            currentClientId={currentClientId}
            currentClientName={currentClientName}
            clearCurrentClientCreationInfo={clearCurrentClientCreationInfo}
            AppName={_.get(whiteLabelInfo, ['AppName'], 'Leoforce')}
          />
          <ConfirmationDialogueBox
            confirmationDialogueBoxVisibility={confirmationDialogueBoxVisibility}
            onCancel={this.toggleConfirmationDialogueBoxVisibility}
            onPlaceJob={this.placeJob}
            isLoading={isLoadingTrue}
            jobDetails={currentJobData}
          />
        </div>
      );
    }
    return null;
  }
}

JobEdit.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  putEditJob: PropTypes.func.isRequired,
  patchEditJob: PropTypes.func.isRequired,
  jobsById: PropTypes.shape({ JobId: PropTypes.number }),
  jobUtilities: PropTypes.shape({}),
  fetchTitleSynonyms: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchZipCodes: PropTypes.func.isRequired,
  fetchClients: PropTypes.func.isRequired,
  fetchIndustries: PropTypes.func.isRequired,
  fetchJobDetails: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

JobEdit.defaultProps = {
  jobStatus: [],
  jobsById: {},
  jobUtilities: {},
};

const JobEditContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(JobEdit));

export default JobEditContainer;
export { JobEdit as JobEditContainerWithoutStore };
