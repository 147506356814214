// Profile Settings
const profileSettings = {};

// Application Settings
const applicationSettings = {};
const jobBoardIntegration = {};
const jobPublishIntegration = {};
const defaultDistanceConfig = {};
const smtpEmailConfig = {};
const candidateAutoRejectionConfig = {};
const aryaBotSettings = {
  saveChatBotSettings: 'SAVE_CHATBOT_SETTINGS',
  turnOnBotAfterSendingBulkMessage: 'TURN_ON_BOT_AFTER_SENDING_BULK_MESSAGE',
};

// Admin Settings
const adminSettings = {};
const callConfig = {};
const candidateInfoProviderConfig = {
  saveChanges: 'SAVE_CONTACT_PROVIDER_CONFIG',
};
const adminSmtpEmailConfig = {};
const consentManager = {};
const multiLocationConfig = {};
const diversityAndInclusion = {};

// Client Settings
const clientSettings = {};

const settingsEventTypes = {
  profileSettings,
  applicationSettings,
  jobBoardIntegration,
  jobPublishIntegration,
  defaultDistanceConfig,
  smtpEmailConfig,
  candidateAutoRejectionConfig,
  aryaBotSettings,
  adminSettings,
  callConfig,
  candidateInfoProviderConfig,
  adminSmtpEmailConfig,
  consentManager,
  multiLocationConfig,
  diversityAndInclusion,
  clientSettings,
};

export default settingsEventTypes;
