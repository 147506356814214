import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import message from '../Containers/CandidateDrawer/messages';

const Sourced = 'sourced';

export const AutomationSendToSelection = ({ onChange, value, sendTo, isScoreAndRankAutomationEnabled }) => {
  return (
    <Checkbox.Group onChange={onChange} value={value}>
      <Checkbox value={sendTo.shortlistedIdentifier} disabled={!isScoreAndRankAutomationEnabled}>
        <FormattedMessage {...message.shortlistedCandidatesLabel} />
      </Checkbox>
      {isScoreAndRankAutomationEnabled ? (
        <Checkbox value={sendTo.scoreAndRankIdentifier}>
          <FormattedMessage {...message.scoredAndRankedByMeLabel} />
        </Checkbox>
      ) : null}
    </Checkbox.Group>
  );
};
export const getAutomationConfiguration = (automationConfig = {}, featureToggleList) => {
  if (featureToggleList.ScoreAndRankAutomation.IsEnabled) return automationConfig;
  const emailSendTo = (automationConfig.Email?.SendTo || []).filter(item => item.toLowerCase() !== Sourced);
  const messageSendTo = (automationConfig.Message?.SendTo || []).filter(item => item.toLowerCase() !== Sourced);
  const triggerTo = (automationConfig.Workflows?.[0]?.TriggerTo?.RecommendedStatuses || []).filter(
    item => item.toLowerCase() !== Sourced
  );

  return {
    ...automationConfig,
    Email: {
      ...automationConfig.Email,
      SendTo: emailSendTo.length ? emailSendTo : undefined,
      IsEnabled: automationConfig.Email?.IsEnabled && !!emailSendTo.length,
      FromUserGuid: emailSendTo.length ? automationConfig.Email?.FromUserGuid : undefined,
      From: emailSendTo.length ? automationConfig.Email?.From : undefined,
    },
    Message: {
      ...automationConfig.Message,
      SendTo: messageSendTo.length ? messageSendTo : undefined,
      IsEnabled: automationConfig.Message?.IsEnabled && !!messageSendTo.length,

      FromUserGuid: messageSendTo.length ? automationConfig.Message?.FromUserGuid : undefined,
      From: messageSendTo.length ? automationConfig.Message?.From : undefined,
    },
    Workflows: automationConfig.Workflows
      ? [
        {
          ...automationConfig.Workflows?.[0],
          TriggerTo: {
            ...automationConfig.Workflows?.[0]?.TriggerTo,
            RecommendedStatuses: triggerTo.length ? triggerTo : undefined,
          },
          IsEnabled: automationConfig.Workflows?.[0]?.IsEnabled && !!triggerTo.length,
          EmailActivityFromAddress: triggerTo.length
            ? automationConfig.Workflows?.[0]?.EmailActivityFromAddress
            : undefined,
        },
      ]
      : automationConfig.Workflows,
  };
};

export const getIsAutomationConfigured = (automationConfig = {}, featureToggleList, field) => {
  if (field === 'Workflows') {
    if (featureToggleList.ScoreAndRankAutomation.IsEnabled) return !!automationConfig[field]?.[0]?.IsEnabled;
    return (
      automationConfig[field]?.[0]?.IsEnabled &&
      !!(automationConfig[field][0]?.TriggerTo?.RecommendedStatuses || []).filter(
        item => item.toLowerCase() !== Sourced
      ).length
    );
  }
  if (featureToggleList.ScoreAndRankAutomation.IsEnabled) return !!automationConfig[field]?.IsEnabled;
  const sendTo = (automationConfig[field]?.SendTo || []).filter(item => item.toLowerCase() !== Sourced);
  return !!automationConfig[field]?.IsEnabled && !!sendTo.length;
};
