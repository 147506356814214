import _ from 'lodash';

function CallConversationsReducer(state = {}, action) {
  let newState;
  let conversationCalls;
  let byAudioConversationId;
  let newCallHistory;
  const { payload } = action;
  switch (action.type) {
    case 'SET_CONVERSATION_CALLS': {
      const ById = payload.conversationId ? 'ByConversationId' : 'ByPersonId';
      const Id = payload.conversationId ? payload.conversationId : payload.personId;
      newState = _.cloneDeep(state);
      if (!newState[ById]) {
        newState[ById] = {};
      }
      if (!newState[ById][Id]) {
        newState[ById][Id] = {};
      }
      if (!newState[ById][Id].Order) {
        newState[ById][Id].Order = [];
      }
      newCallHistory = (payload.Calls || []).map(call => {
        // if (
        //   ['Ringing', 'Initiated'].includes(call.ConversationStatus) &&
        //   call.Id !== newState.ById[payload.conversationId].OngoingCallId
        // ) {
        //   return { ...call, ConversationStatus: 'Canceled' };
        // }
        return call;
      });
      if (newCallHistory) {
        const newOrder = newCallHistory.map(call => call.Id);
        newState[ById][Id].Order = [...new Set([...newState[ById][Id].Order, ...newOrder])];
        byAudioConversationId = _.keyBy(newCallHistory, 'Id');
        if (!newState[ById][Id].ByCallId) {
          newState[ById][Id].ByCallId = {};
        }
        newState[ById][Id].ByCallId = {
          ...newState[ById][Id].ByCallId,
          ...byAudioConversationId,
        };
        if (!newState[ById][Id].Count) {
          newState[ById][Id].Count = 0;
        }
        newState[ById][Id].Count = payload.Count;
      }
      return newState;
    }
    case 'ADD_NEW_CALL': {
      const { isAllConversationsEnabled } = payload;
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (isAllConversationsEnabled && !newState.ByPersonId) {
        newState.ByPersonId = {};
      }
      conversationCalls = _.get(newState, ['ByConversationId', payload.conversationId], null);
      if (!conversationCalls) {
        newState.ByConversationId[payload.conversationId] = {};
        newState.ByConversationId[payload.conversationId].Order = [];
        newState.ByConversationId[payload.conversationId].ByCallId = {};
        newState.ByConversationId[payload.conversationId].Count = 0;
      }
      if (isAllConversationsEnabled) {
        const allConversationCalls = _.get(newState, ['ByPersonId', payload.personId], null);
        if (!allConversationCalls) {
          newState.ByPersonId[payload.personId] = {};
          newState.ByPersonId[payload.personId].Order = [];
          newState.ByPersonId[payload.personId].ByCallId = {};
          newState.ByPersonId[payload.personId].Count = 0;
        }
      }
      if (
        !payload.Call.ConversationStatus ||
        payload.Call.ConversationStatus === 'Initiated' ||
        payload.Call.ConversationStatus === 'Ringing' ||
        payload.Call.ConversationStatus === 'InProgress'
      ) {
        newState.ByConversationId[payload.conversationId].OngoingCallId = payload.Call.Id;
        if (isAllConversationsEnabled) {
          newState.ByPersonId[payload.personId].OngoingCallId = payload.Call.Id;
        }
      } else {
        newState.ByConversationId[payload.conversationId].OngoingCallId = null;
        if (isAllConversationsEnabled) {
          newState.ByPersonId[payload.personId].OngoingCallId = null;
        }
      }
      newState.ByConversationId[payload.conversationId].Order.unshift(payload.Call.Id);
      newState.ByConversationId[payload.conversationId].Count += 1;
      newState.ByConversationId[payload.conversationId].ByCallId[payload.Call.Id] = payload.Call;
      if (isAllConversationsEnabled) {
        newState.ByPersonId[payload.personId].Order.unshift(payload.Call.Id);
        newState.ByPersonId[payload.personId].Count += 1;
        newState.ByPersonId[payload.personId].ByCallId[payload.Call.Id] = payload.Call;
      }
      if (!payload.Call.ConversationStatus) {
        newState.ByConversationId[payload.conversationId].ByCallId[payload.Call.Id].ConversationStatus = 'Initiated';
        if (isAllConversationsEnabled) {
          newState.ByPersonId[payload.personId].ByCallId[payload.Call.Id].ConversationStatus = 'Initiated';
        }
      }
      return newState;
    }
    case 'UPDATE_CALL_STATUS':
      {
        newState = _.cloneDeep(state);
        const { isAllConversationsEnabled } = payload;
        if (!newState.ByConversationId) {
          newState.ByConversationId = {};
        }
        if (!newState.ByPersonId) {
          newState.ByPersonId = {};
        }
        conversationCalls = _.get(newState, ['ByConversationId', payload.conversationId], []);
        if (!conversationCalls) {
          throw new Error('Call conversations do not exist for the specified conversationId.');
        }
        if (newState.ByConversationId[payload.conversationId].ByCallId?.[payload.audioConversationId]) {
          newState.ByConversationId[payload.conversationId].ByCallId[payload.audioConversationId].ConversationStatus =
            payload.conversationStatus;
          if (action.payload.conversationStatus === 'Answered') {
            newState.ByConversationId[payload.conversationId].ByCallId[payload.audioConversationId].AnsweredTime =
              new Date().toISOString();
          } else if (action.payload.conversationStatus === 'Completed') {
            newState.ByConversationId[payload.conversationId].ByCallId[payload.audioConversationId].EndedTime =
              new Date().toISOString();
          }
        }
        if (
          isAllConversationsEnabled &&
          newState.ByPersonId[payload.personId].ByCallId?.[payload.audioConversationId]
        ) {
          newState.ByPersonId[payload.personId].ByCallId[payload.audioConversationId].ConversationStatus =
            payload.conversationStatus;
          if (action.payload.conversationStatus === 'Answered') {
            newState.ByPersonId[payload.personId].ByCallId[payload.audioConversationId].AnsweredTime =
              new Date().toISOString();
          } else if (action.payload.conversationStatus === 'Completed') {
            newState.ByPersonId[payload.personId].ByCallId[payload.audioConversationId].EndedTime =
              new Date().toISOString();
          }
        }
      }
      return newState;
    case 'UNSET_ONGOING_CALL_ID': {
      newState = _.cloneDeep(state);
      const { isAllConversationsEnabled } = payload;
      conversationCalls = _.get(newState, ['ByConversationId', payload.conversationId], null);
      if (!conversationCalls) {
        if (!newState.ByConversationId) {
          newState.ByConversationId = {};
        }
        newState.ByConversationId[payload.conversationId] = {};
        newState.ByConversationId[payload.conversationId].Order = [];
        newState.ByConversationId[payload.conversationId].ByCallId = {};
        newState.ByConversationId[payload.conversationId].OngoingCallId = null;
      }
      if (isAllConversationsEnabled) {
        const allConversationCalls = _.get(newState, ['ByPersonId', payload.personId], null);
        if (!allConversationCalls) {
          if (!newState.ByPersonId) {
            newState.ByPersonId = {};
          }
          newState.ByPersonId[payload.personId] = {};
          newState.ByPersonId[payload.personId].Order = [];
          newState.ByPersonId[payload.personId].ByCallId = {};
          newState.ByPersonId[payload.personId].OngoingCallId = null;
        }
        newState.ByPersonId[payload.personId].OngoingCallId = null;
      }
      newState.ByConversationId[payload.conversationId].OngoingCallId = null;
      return newState;
    }
    default:
      return state;
  }
}

function getCallConversations(state, conversationId) {
  return _.get(state.CallConversationsReducer, ['ByConversationId', conversationId]);
}

function getCallConversationsCount(state, conversationId) {
  return _.get(state.CallConversationsReducer, ['ByConversationId', conversationId, 'Count']);
}

function getAllCallConversations(state, personId) {
  return _.get(state.CallConversationsReducer, ['ByPersonId', personId]);
}

function getAllCallConversationsCount(state, personId) {
  return _.get(state.CallConversationsReducer, ['ByPersonId', personId, 'Count']);
}

function getOngoingCallId(state, conversationId, personId, isAllConversationsEnabled) {
  if (conversationId)
    return _.get(state.CallConversationsReducer, ['ByConversationId', conversationId, 'OngoingCallId']);
  if (isAllConversationsEnabled && personId)
    return _.get(state.CallConversationsReducer, ['ByPersonId', personId, 'OngoingCallId']);
  return null;
}

function getCallStatus(state, conversationId, callId, isAllConversationsEnabled, personId) {
  if (conversationId) {
    return _.get(
      state.CallConversationsReducer,
      ['ByConversationId', conversationId, 'ByCallId', callId, 'ConversationStatus'],
      null
    );
  }
  if (isAllConversationsEnabled && personId) {
    return _.get(
      state.CallConversationsReducer,
      ['ByPersonId', personId, 'ByCallId', callId, 'ConversationStatus'],
      null
    );
  }
  return null;
}

export {
  CallConversationsReducer,
  getCallConversations,
  getOngoingCallId,
  getCallStatus,
  getAllCallConversations,
  getAllCallConversationsCount,
  getCallConversationsCount,
};
