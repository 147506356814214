import { setApiStatus } from '../ApiStatusActions';

export const SET_JOB_NOTES = 'SET_JOB_NOTES';
export const DELETE_JOB_NOTE = 'DELETE_JOB_NOTE';
export const CREATE_JOB_NOTE = 'CREATE_JOB_NOTE';
export const UPDATE_JOB_NOTE = 'UPDATE_JOB_NOTE';
export const CREATE_CANDIDATE_NOTE = 'CREATE_CANDIDATE_NOTE';
export const DELETE_CANDIDATE_NOTE = 'DELETE_CANDIDATE_NOTE';
export const UPDATE_CANDIDATE_NOTE = 'UPDATE_CANDIDATE_NOTE';
export const SET_CANDIDATE_NOTES_V2 = 'SET_CANDIDATE_NOTES_V2';
export const ADD_JOB_NOTE_TAGS = 'ADD_JOB_NOTE_TAGS';
export const ADD_CANDIDATE_NOTE_TAGS = 'ADD_CANDIDATE_NOTE_TAGS';
export const DELETE_CANDIDATE_NOTE_TAG = 'DELETE_CANDIDATE_NOTE_TAG';
export const DELETE_JOB_NOTE_TAG = 'DELETE_JOB_NOTE_TAG';

export const setJobNotes = ({ jobId, notes }) => {
  return {
    type: SET_JOB_NOTES,
    payload: {
      jobId,
      notes,
    },
  };
};

export const addJobNoteTags = payload => {
  return {
    type: ADD_JOB_NOTE_TAGS,
    payload,
  };
};

export const removeTagFromJobNote = payload => {
  return {
    type: DELETE_JOB_NOTE_TAG,
    payload,
  };
};

export const addCandidateNoteTags = payload => {
  return {
    type: ADD_CANDIDATE_NOTE_TAGS,
    payload,
  };
};

export const removeTagFromCandidateNote = payload => {
  return {
    type: DELETE_CANDIDATE_NOTE_TAG,
    payload,
  };
};

export const setCandidateNotes = ({ candidateId, notes }) => {
  return {
    type: SET_CANDIDATE_NOTES_V2,
    payload: {
      candidateId,
      notes,
    },
  };
};

export const removeJobNote = ({ jobId, noteId }) => {
  return {
    type: DELETE_JOB_NOTE,
    payload: {
      jobId,
      noteId,
    },
  };
};

export const removeCandidateNote = payload => {
  return {
    type: DELETE_CANDIDATE_NOTE,
    payload,
  };
};

export const addJobNote = payload => {
  return {
    type: CREATE_JOB_NOTE,
    payload,
  };
};

export const addCandidateNote = payload => {
  return {
    type: CREATE_CANDIDATE_NOTE,
    payload,
  };
};

export const updateJobNote = payload => {
  return {
    type: UPDATE_JOB_NOTE,
    payload,
  };
};

export const updateCandidateNote = payload => {
  return {
    type: UPDATE_CANDIDATE_NOTE,
    payload,
  };
};

export const setJobNoteFetchApiStatus = status => {
  const apiName = 'jobNoteFetchApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateNoteFetchApiStatus = status => {
  const apiName = 'candidateNoteFetchApiStatus';
  return setApiStatus({ apiName, status });
};

export const clearJobNotes = jobId => {
  return setJobNotes({ jobId, notes: [] });
};

export const clearCandidateNotes = candidateId => {
  return setCandidateNotes({ candidateId, notes: [] });
};

export const setJobNoteDeleteApiStatus = status => {
  const apiName = 'jobNoteDeleteApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateNoteDeleteApiStatus = status => {
  const apiName = 'candidateNoteDeleteApiStatus';
  return setApiStatus({ apiName, status });
};
export const setJobNoteAddButtonApiStatus = status => {
  const apiName = 'jobNoteAddButtonApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateNoteCreateApiStatus = status => {
  const apiName = 'candidateNoteCreateApiStatus';
  return setApiStatus({ apiName, status });
};
export const setPopupNoteCreateApiStatus = status => {
  const apiName = 'setPopupNoteCreateApiStatus';
  return setApiStatus({ apiName, status });
};
