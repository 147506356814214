import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getUserType } from './Utils/ConfigUtils';
import config from './Config/Config';
import { fetchAppConfiguration } from './Actions/ConfigActions';
import { getAppConfiguration } from './Reducers/ConfigReducer';
import { getCurrentUserDetails } from './Reducers/UserSessionReducer';

function getClientName() {
  let client = localStorage.getItem('Client');
  if (!client) {
    client = _.find(config.whiteLabelClients, currentClient =>
      window.location.href.toLowerCase().includes(currentClient.toLowerCase())
    );
  }
  return client;
}

function getApplicationTitle(clientName) {
  if (clientName) return clientName;
  if (window.location.hostname === 'app.goarya.com') return 'Leoforce | AI Recruiting Technology';
  if (window.location.hostname === 'pulse.goarya.com') return 'Leoforce Pulse | On-Demand AI Recruiting Technology';
  return 'Leoforce';
}

function getMetaTagDescription() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'app.goarya.com':
      return `Discover the world's best talent with the competitive edge of AI Recruiting. Match the right candidates with the right opportunities-quickly and objectively`;
    case 'pulse.goarya.com':
      return 'Arya Pulse is an on-demand recruiting service for small and medium businesses, empowering you to compete for talent with big companies with dedicated HR teams.';
    default:
      return `Discover the world's best talent with the competitive edge of AI Recruiting. Match the right candidates with the right opportunities-quickly and objectively`;
  }
}

function getMetaTagKeywords() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'pulse.goarya.com':
      return [
        'Arya Pulse',
        'Pulse',
        'recruiting',
        'AI Technology',
        'low cost hiring',
        'recruitment',
        'recruiting platform',
      ];
    default:
      return ['Arya', 'recruiting', 'AI Technology', 'recruitment', 'recruiting platform'];
  }
}
export default function HelmetComponent() {
  const dispatch = useDispatch();
  useEffect(() => {
    const userType = getUserType();
    dispatch(fetchAppConfiguration(userType === 'pulse' ? 'pulse' : 'quantum'));
  }, []);

  const appConfiguration = useSelector(state => getAppConfiguration(state));
  const currentUserDetails = useSelector(state => getCurrentUserDetails(state));
  const userId = currentUserDetails?.Id;
  const userEmail = currentUserDetails?.Email;
  const clientName = getClientName();
  const title = getApplicationTitle(clientName);
  const metaTagDescription = getMetaTagDescription();
  const metaTagKeywords = getMetaTagKeywords().join();
  const olarkId = config.Olark.Id;
  const gtagUrl = config.urls.getGtagUrl(config.googleAnalytics.key);
  const {
    Olark: olarkConfiguration,
    GoogleAnalytics: googleAnalticsConfiguration,
    PartnerStack: partnerStackConfiguration,
    SmartKarrot: smartKarrotConfiguration,
    GoogleTagManager: gtmConfiguration,
  } = appConfiguration;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaTagDescription} />
      <meta name="keywords" content={metaTagKeywords} />
      {olarkConfiguration?.IsEnabled ? (
        <script type="text/javascript" async>
          {`;
    (function (o, l, a, r, k, y) {
      if (o.olark) return;
      r = "script";
      y = l.createElement(r);
      r = l.getElementsByTagName(r)[0];
      y.async = 1;
      y.src = "//" + a;
      r.parentNode.insertBefore(y, r);
      y = o.olark = function () {
        k.s.push(arguments);
        k.t.push(+new Date)
      };
      y.extend = function (i, j) {
        y("extend", i, j)
      };
      y.identify = function (i) {
        y("identify", k.i = i)
      };
      y.configure = function (i, j) {
        y("configure", i, j);
        k.c[i] = j
      };
      k = y._ = {
        s: [],
        t: [+new Date],
        c: {},
        l: a
      };
    })(window, document, "static.olark.com/jsclient/loader.js");
    /* custom configuration goes here (www.olark.com/documentation) */
    olark.configure('system.hb_show_button_text', false );
    olark.configure('system.hb_primary_color', '#007BFF');
    olark.configure('system.hb_position', 'left');
    olark.configure('system.hb_custom_style', {
      general: {
        secondaryColor: '#007BFF'
      }
    });
    (function() {
      const secondsUntilAutoDelay = 1;
      let didSendMessageToVisitor = JSON.parse(sessionStorage.getItem('didSendMessageToVisitor'));

      olark('api.chat.onMessageToVisitor', function() {
        didSendMessageToVisitor = true;
        sessionStorage.setItem('didSendMessageToVisitor', true);
      });

      olark('api.rules.defineRule', {
        id: 'delayed_response',
        description: 'Will send a response to the visitor after an agent does not respond.',
        condition: function(pass) {
          olark('api.visitor.getDetails', function(details) {
            if (details.isConversing &&
              !didSendMessageToVisitor &&
              details.secondsSinceLastMessageToOperator > secondsUntilAutoDelay) {
              pass();
            }
          });
        },
        action: function() {
          olark('api.chat.sendMessageToVisitor', {
            body: 'Hi! Thank you for reaching out to Arya support. I hope you are having a pleasant day! Lets get started with your inquiry.'
          });
          olark('api.chat.sendNotificationToOperator', {
            body: 'Automated first response message has been sent to visitor.'
          });
        },
        perVisit: true,
      });
    })();
    olark.identify('${olarkId}');`}
        </script>
      ) : null}
      <script async src={gtagUrl}></script>
      {googleAnalticsConfiguration?.IsEnabled ? (
        <script>
          {`;
      if (window.location.host === '${config.urls.pulseHostName}') {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${config.googleAnalytics.key}');
      }
      if (window.location.host === '${config.urls.pulseHostName}') {
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${config.googleAnalytics.container}');
      }
      `}
        </script>
      ) : null}
      {userId ? (
        <script>
          {`; function removeClarityEventListeners() {
          ['keydown', 'mousedown', 'mousemove', 'touchmove', 'touchstart', 'touchend', 'wheel'].forEach(function (e) {
            window.removeEventListener(e, loadClarity);
          });
        }
        function loadClarity() {
          (function (c, l, a, r, i, t, y) {
            c[a] =
              c[a] ||
              function () {
                (c[a].q = c[a].q || []).push(arguments);
              };
            t = l.createElement(r);
            t.async = 1;
            t.src = 'https://www.clarity.ms/tag/' + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, 'clarity', 'script', '${config.Clarity.Id}');
          clarity('set', 'UserId', '${userId || null}');
          clarity('set', 'UserEmail', '${userEmail || null}');
          removeClarityEventListeners();
        }
        function loadEvent() {
          ['keydown', 'mousedown', 'mousemove', 'touchmove', 'touchstart', 'touchend', 'wheel'].forEach(function (e) {
            window.addEventListener(e, loadClarity, false);
          });
        }
        if (window.addEventListener) {
          // For modern browsers
          window.addEventListener('load', loadEvent, false);
        }`}
        </script>
      ) : null}
      {partnerStackConfiguration?.IsEnabled ? (
        <script>
          {`;
      if (window.location.host === '${config.urls.pulseHostName}') {
        (function() {
          var gs = document.createElement('script');
          gs.src = 'https://js.partnerstack.com/v1/';
          gs.type = 'text/javascript';
          gs.async = 'true';
          gs.onload = gs.onreadystatechange = function() {
            var rs = this.readyState;
            if (rs && rs != 'complete' && rs != 'loaded') return;
            try {
              growsumo._initialize('${config.partnerstack.key}');
              if (typeof growsumoInit === 'function') {
                growsumoInit();
              }
            } catch (e) {}
          };
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(gs, s);
        })();
      };
      `}
        </script>
      ) : null}
      {gtmConfiguration?.IsEnabled ? (
        <script>
          {`; (function(w,d,s,l,i) {
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),
      dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${config.pivotRootGTMContainer}');
  `}
        </script>
      ) : null}
      {smartKarrotConfiguration?.IsEnabled ? (
        <script
          defer
          src="https://docs.smartkarrot.com/frameworks/web/v4/auto/multi-product/UsageAnalytics.js"
        ></script>
      ) : null}
      <link
        rel="shortcut icon"
        href={`${clientName ? config.urls.getWhiteLabelResourceUrl(clientName) : process.env.PUBLIC_URL}/favicon.png`}
      />
    </Helmet>
  );
}
