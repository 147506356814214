import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Slider, Collapse, Button, Switch, Drawer, Tooltip, Checkbox } from 'antd';
import './JobSettings.scss';
import RichTextEditor from '../../Components/Editor/RichTextEditor';
import ConnectTemplateSelect from '../../Components/JobSettings/ConnectTemplateSelect';
import * as JobActions from '../../Actions/JobActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as EmailTemplatesActions from '../../Actions/EmailTemplates';
import * as MessageTemplatesActions from '../../Actions/TextTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import * as ConfigActions from '../../Actions/ConfigActions';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import CandidateAutoRejectSwitch from '../../Components/CandidateAutoRejectSwitch/CandidateAutoRejectSwitch';
import { getEmailTemplatesById, getEmailTemplatesCount, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import { getTextTemplatesById, getTextTemplatesCount, getTextTemplateIds } from '../../Reducers/TextTemplates';
import { getUsersById, getFilteredUserEmails } from '../../Reducers/UserReducer';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import { getCurrentUser, getImpersonatedUserDetails } from '../../Reducers/UserSessionReducer';
import {
  getConfig,
  getConnectConfig,
  getcandidateDownloadFilterConfig,
  getcandidateCommunicationFilterConfig,
} from '../../Reducers/ConfigReducer';
import { getJobsById, getConsentText } from '../../Reducers/JobReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import languageSupportmessages from './messages';
import {
  getIsLinkedEmailsTestSuccesful,
  getProviderLinkedEmails,
  getSuccessfulTestedEmailList,
} from '../../Utils/ContactUtils';
import {
  getConnectSettings,
  getEmailSmtpConfiguration,
  getOrgDefaultEmail,
} from '../../Reducers/ConnectSettingsReducer';
import { getEmailTemplate as _getEmailTemplate } from '../../Actions/EmailTemplates';
import { getTextTemplate as _getTextTemplate } from '../../Actions/TextTemplates';
import { getEmailTemplateDetails } from '../../Utils/EmailTemplateUtil';
import { getConsentComplianceInfo } from '../../Utils/ConnectUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';
import EmailAutomationWorkflowWrapper from '../../Components/EmailAutomationWorkflowWrapper/EmailAutomationWorkflowWrapper';
import { isWorkflowConfigurationUpdated } from '../../Utils/JobUtils';
import { compareDates } from '../../Utils/MomentUtils';
import InfoIconWithTooltip from '../../Components/Common/InfoIconWithTooltip/InfoIconWithTooltip';
import { getCurrentSelectedFromEmail } from '../../Utils/UserInfo';
import { getIsValidWorkflowConfiguration } from '../../Utils/WorkflowUtils';
import CampaignStats from './CampaignStats';
import {
  messageCount,
  emojiFinder,
  checkForJunkWordsInText,
  parseTextForJunkDetection,
  getInfoAlertMessage,
} from '../../Components/Utils/SmsUtils';
import ExclamationCircleIcon from '../../Icons/ExclamationCircleIcon';
import BulkCandidatesDownloadFilterInfo from '../../Components/BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import message from '../CandidateDrawer/messages';
import jobMessage from '../../Components/JobForm/JobMessages';
import SpamAlertText from '../../Components/Common/SpamTextAlert/SpamTextAlert';
import * as AryaPayReducer from '../../Reducers/AryaPayReducer';
import { getAvailableCredits } from '../../Utils/CreditInfoUtils';
import { checkIfJobMergeTagPresent, validateTemplateContent } from '../../Utils/Validators';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import { AutomationSendToSelection } from '../../Utils/AutomationUtils';

const { Panel } = Collapse;

const SEND_TO = {
  All: ['shortlisted', 'sourced'],
  Shortlisted: ['shortlisted'],
  allIdentifier: 'all',
  shortlistedIdentifier: 'shortlisted',
  userGivenIdentifier: 'usergiven',
  scoreAndRankIdentifier: 'sourced',
};

const defaultActivePanel = ['3'];

const mapStateToProps = (state, ownProps) => {
  let jobDetails = {};
  let sourcingConfiguration = {};
  let automationConfiguration = {};
  let extraProps = {};
  if (ownProps.connectToStore) {
    jobDetails = _.get(getJobsById(state), [ownProps.jobId], {});
    sourcingConfiguration = _.get(jobDetails, ['SourcingConfiguration'], {});
    automationConfiguration = _.get(jobDetails, ['AutomationConfiguration'], {});
  }
  if (ownProps.connectToStore) {
    extraProps = {
      jobDetails,
      SourcingConfiguration: sourcingConfiguration,
      AutomationConfiguration: automationConfiguration,
      updateContainerJobConfig: ownProps.updateContainerJobConfig,
    };
  }
  const emailSmtpConfiguration = getEmailSmtpConfiguration(state);

  return {
    userConfig: getConfig(state),
    userConnectConfig: getConnectConfig(state),
    usersById: getUsersById(state),
    emailTemplatesCount: getEmailTemplatesCount(state),
    messageTemplatesCount: getTextTemplatesCount(state),
    emailTemplateIds: getEmailTemplateIds(state),
    dripTemplatesById: getDripTemplatesById(state),
    messageTemplateIds: getTextTemplateIds(state),
    consentText: getConsentText(state),
    emailTemplatesById: getEmailTemplatesById(state),
    messageTemplatesById: getTextTemplatesById(state),
    currentUserDetails: getCurrentUser(state),
    impersonatedUserDetails: getImpersonatedUserDetails(state),
    featureToggleList: getFeatureToggleList(state),
    emailSmtpConfiguration,
    userEmails: getFilteredUserEmails(state),
    orgDefaultEmail: getOrgDefaultEmail(state),
    connectSettings: getConnectSettings(state),
    candidateDownloadFilterConfig: getcandidateDownloadFilterConfig(state),
    candidateCommunicationFilterConfig: getcandidateCommunicationFilterConfig(state),
    availableCredits: AryaPayReducer.getAvailableCredits(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    ...extraProps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actionCreators = {
    fetchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
    fetchMessageTemplates: MessageTemplatesActions.searchTextTemplates,
    getDripTemplate: DripTemplatesActions.getDripTemplate,
    fetchUserEmails: ConnectActions.fetchUserEmails,
    getEmailTemplate: _getEmailTemplate,
    getTextTemplate: _getTextTemplate,
    fetchCandidateDownloadFilterConfig: ConfigActions.fetchCandidateDownloadFilterConfig,
    fetchCandidateCommunicateFilterConfig: ConfigActions.fetchCandidateCommunicateFilterConfig,
    fetchJobConfig: JobActions.fetchJobConfig,
    fetchCampaignStats: CandidateAdvanceFilterActions.fetchCampaignStats,
    setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
  };
  if (ownProps.connectToStore) {
    actionCreators.updateJobConfig = _.get(JobActions, 'updateJobConfig');
  }
  return {
    dispatch,
    ...bindActionCreators(actionCreators, dispatch),
  };
};

const debouncedCheckForJunkWordsInText = _.debounce((plainTextContent, setter, junkWordsSetter) => {
  const { hasJunkWords, junkWords } = checkForJunkWordsInText(plainTextContent);
  setter(hasJunkWords);
  junkWordsSetter(junkWords);
}, 600);

class JobSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplateContentVisibility: false,
      messageTemplateContentVisibility: false,
      automationRadioSelected: '',
      definitionId: undefined,
      startTime: moment().format('MM/DD/YYYY'),
      endTime: undefined,
      campaignTriggeredEndTime: undefined,
      currentPanel: ['3'],
      isCampaignAutmationPanelChecked: false,
      isJunkWordFound: false,
      junkWords: [],
    };
    this.onSave = this.onSave.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
    this.onChangeEmailTemplate = this.onChangeEmailTemplate.bind(this);
    this.onChangeMessageTemplate = this.onChangeMessageTemplate.bind(this);
    this.toggleEmailContentVisibility = this.toggleEmailContentVisibility.bind(this);
    this.toggleMessageContentVisibility = this.toggleMessageContentVisibility.bind(this);
    this.onEmailSendToChange = this.onEmailSendToChange.bind(this);
    this.onEmailFromChange = this.onEmailFromChange.bind(this);
    this.onMessageSendToChange = this.onMessageSendToChange.bind(this);
    this.fetchEmailTemplates = this.fetchEmailTemplates.bind(this);
    this.fetchMessageTemplates = this.fetchMessageTemplates.bind(this);
    this.getUserGuidFromEmail = this.getUserGuidFromEmail.bind(this);
    this.checkSelectedEmailHasLinkedSMTP = this.checkSelectedEmailHasLinkedSMTP.bind(this);
    this.handleAutoRejectSwitchChange = this.handleAutoRejectSwitchChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      SourcingConfiguration,
      AutomationConfiguration,
      drawerVisibility,
      AryaRejectionConfiguration,
      featureToggleList,
      campaignDrawerVisbility,
    } = props;
    const { isEmailTemplatePanelEnabled, isMessageTemplatePanelEnabled } = state;
    if (
      (isEmailTemplatePanelEnabled === undefined || isEmailTemplatePanelEnabled === null) &&
      (isMessageTemplatePanelEnabled === undefined || isMessageTemplatePanelEnabled === null) &&
      !_.isEmpty(SourcingConfiguration) &&
      !_.isEmpty(AutomationConfiguration)
    ) {
      let emailSendTo = _.get(AutomationConfiguration, ['Email', 'SendTo'], SEND_TO.Shortlisted).map(sendTo =>
        sendTo.toLowerCase()
      );

      let messageSendTo = _.get(AutomationConfiguration, ['Message', 'SendTo'], SEND_TO.Shortlisted).map(sendTo =>
        sendTo.toLowerCase()
      );
      const sendEmailToManualCandidates = _.get(AutomationConfiguration, ['Email', 'SendToUserRecommended']);
      const sendMessageToManualCandidates = _.get(AutomationConfiguration, ['Message', 'SendToUserRecommended']);
      if (sendEmailToManualCandidates) {
        emailSendTo = SEND_TO.userGivenIdentifier;
      }
      if (sendMessageToManualCandidates) {
        messageSendTo = SEND_TO.userGivenIdentifier;
      }

      const workflowAutomationConfiguration = AutomationConfiguration?.Workflows?.[0];
      const workflowSendToRecommendedStatus = JobSettings.getWorkflowSendToRecommendedStatus(AutomationConfiguration);

      const isAutoEmailEnabled =
        (_.get(AutomationConfiguration, ['Email', 'IsEnabled'], false) &&
          !!AutomationConfiguration.Email?.SendTo?.length) ||
        (featureToggleList.Workflow.IsEnabled &&
          _.get(workflowAutomationConfiguration, 'IsEnabled', false) &&
          !!AutomationConfiguration.Workflows?.[0]?.TriggerTo?.RecommendedStatuses?.length);
      const isAutoMessageEnabled =
        _.get(AutomationConfiguration, ['Message', 'IsEnabled'], false) &&
        !!AutomationConfiguration.Message?.SendTo?.length;
      let activePanel;
      if (isAutoEmailEnabled) {
        activePanel = ['1'];
      } else if (isAutoMessageEnabled) {
        activePanel = ['2'];
      } else {
        activePanel = defaultActivePanel;
      }

      const isWorkflowAutomationEnabled =
        featureToggleList.Workflow.IsEnabled && _.get(workflowAutomationConfiguration, 'IsEnabled', false);
      const selectedAutomation = isWorkflowAutomationEnabled ? 'Workflow' : 'Email';
      return {
        sliderValue: _.get(SourcingConfiguration, ['SourceLimit'], undefined),
        isEmailTemplatePanelEnabled: isAutoEmailEnabled,
        selectedEmailTemplateFrom:
          _.get(AutomationConfiguration, ['Email', 'From'], undefined) ||
          _.get(workflowAutomationConfiguration, 'EmailActivityFromAddress', undefined),
        selectedEmailTemplateId: _.get(AutomationConfiguration, ['Email', 'TemplateId'], undefined),
        selectedEmailTemplateName: _.get(AutomationConfiguration, ['Email', 'TemplateName'], undefined),
        selectedEmailTemplateSendTo: isWorkflowAutomationEnabled ? workflowSendToRecommendedStatus : emailSendTo,
        isDripTemplate: _.get(AutomationConfiguration, ['Email', 'IsDrip'], false),
        isMessageTemplatePanelEnabled: isAutoMessageEnabled,
        selectedMessageTemplateId: _.get(AutomationConfiguration, ['Message', 'TemplateId'], undefined),
        selectedMessageTemplateSendTo: messageSendTo,
        currentPanel: activePanel,
        autoRejectSwitchValue: AryaRejectionConfiguration?.IsEnabled ?? false,
        definitionId: _.get(workflowAutomationConfiguration, 'WorkflowDefinitionId', undefined),
        workflowDefinitionName: _.get(workflowAutomationConfiguration, 'WorkflowDefinitionName', undefined),
        startTime: _.get(workflowAutomationConfiguration, 'StartTime', moment().format('MM/DD/YYYY')),
        endTime: _.get(workflowAutomationConfiguration, 'EndTime', undefined),
        campaignTriggeredEndTime: _.get(workflowAutomationConfiguration, 'CampaignTriggerEndTime', undefined),
        automationRadioSelected: selectedAutomation,
      };
    }
    if (state.propsDrawerVisibility !== drawerVisibility) {
      return {
        sliderValue: _.get(SourcingConfiguration, ['SourceLimit'], undefined),
        propsDrawerVisibility: drawerVisibility,
        tooltipVisibility: drawerVisibility,
        currentPanel: campaignDrawerVisbility ? ['1'] : defaultActivePanel,
      };
    }
    return null;
  }

  static getWorkflowSendToRecommendedStatus(automationConfiguration) {
    const { Workflows } = automationConfiguration || {};
    const workflowAutomationConfiguration = Workflows?.[0];
    const sendWorkflowToManualCandidates = workflowAutomationConfiguration?.TriggerToUserRecommended;
    if (sendWorkflowToManualCandidates) {
      return SEND_TO.userGivenIdentifier;
    }
    return (workflowAutomationConfiguration?.TriggerTo?.RecommendedStatuses ?? SEND_TO.Shortlisted).map(status =>
      status.toLowerCase()
    );
  }

  setDefaultEmailTemplateValues = () => {
    const { emailTemplatesById, AutomationConfiguration } = this.props;
    const isAutoEmailEnabled = _.get(AutomationConfiguration, ['Email', 'IsEnabled'], false);
    const isPulse = isPulseUser();
    const defaultEmailTemplate = Object.values(emailTemplatesById).find(template => template.IsDefault);
    if (defaultEmailTemplate?.Id && !isPulse && !isAutoEmailEnabled) {
      this.onChangeEmailTemplate(defaultEmailTemplate?.Id);
    }
  };

  onChangeAutomationRadioOption = event => {
    this.setState({
      automationRadioSelected: event.target.value,
    });
  };

  setDefinitionId = value => {
    this.setState({
      definitionId: value,
    });
  };

  setWorkflowDefinitionName = value => {
    this.setState({
      workflowDefinitionName: value,
    });
  };

  setStartTime = value => {
    this.setState({
      startTime: value,
    });
  };

  setEndTime = value => {
    this.setState({
      endTime: value,
    });
  };

  setCampaignTriggeredEndTime = value => {
    this.setState({
      campaignTriggeredEndTime: value,
    });
  };

  setAutomationPanelCheckedStatus = () => {
    const { AutomationConfiguration, featureToggleList } = this.props;
    const automationPanelChecked =
      _.get(AutomationConfiguration, ['Email', 'IsEnabled'], false) ||
      (featureToggleList.Workflow.IsEnabled && AutomationConfiguration?.Workflows?.[0]?.IsEnabled);
    if (automationPanelChecked) {
      this.setState({
        isCampaignAutmationPanelChecked: automationPanelChecked,
      });
    }
  };

  componentDidMount() {
    const {
      fetchEmailTemplates,
      fetchMessageTemplates,
      fetchUserEmails,
      emailTemplateIds,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      fetchCandidateDownloadFilterConfig,
      fetchCandidateCommunicateFilterConfig,
      jobId,
      fetchCampaignStats,
      candidateContext = 'job',
    } = this.props;
    const isPulse = isPulseUser();
    if (jobId && candidateContext !== 'job') {
      fetchCampaignStats(jobId);
    }
    fetchEmailTemplates({ From: 0, Size: 10, IncludeDrip: true, IncludeSystemTemplate: !isPulse }, true);
    fetchMessageTemplates({ From: 0, Size: 10, IsConsent: true, IncludeSystemTemplate: true }, true);
    if (!candidateDownloadFilterConfig) {
      fetchCandidateDownloadFilterConfig();
    }
    if (!candidateCommunicationFilterConfig) {
      fetchCandidateCommunicateFilterConfig();
    }
    fetchUserEmails({ testEmailStatus: true });
    if (emailTemplateIds.length > 0) this.setDefaultEmailTemplateValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      jobDetails,
      toggleSettingsDrawer,
      drawerVisibility,
      fetchEmailTemplates,
      fetchMessageTemplates,
      fetchJobConfig,
      jobId,
      fetchCampaignStats,
      candidateContext = 'job',
    } = this.props;
    const updateJobConfigApiStatus = _.get(jobDetails, ['updateJobConfigApiStatus'], '');
    const prevUpdateJobConfigApiStatus = _.get(prevProps, ['jobDetails', 'updateJobConfigApiStatus'], '');
    const prevDrawerVisibility = _.get(prevProps, 'drawerVisibility', false);
    const isPulse = isPulseUser();
    if (
      prevDrawerVisibility &&
      prevUpdateJobConfigApiStatus === 'INPROGRESS' &&
      updateJobConfigApiStatus === 'COMPLETED'
    ) {
      toggleSettingsDrawer();
      fetchJobConfig(jobId);
    }

    if (prevDrawerVisibility === false && drawerVisibility === true) {
      fetchEmailTemplates({ From: 0, Size: 10, IncludeDrip: true, IncludeSystemTemplate: !isPulse }, true);
      fetchMessageTemplates({ From: 0, Size: 10, IsConsent: true, IncludeSystemTemplate: true }, true);
      if (jobId && candidateContext !== 'job') {
        fetchCampaignStats(jobId);
      }
    }
  }

  fetchEmailTemplates(filter) {
    const { fetchEmailTemplates } = this.props;
    const isPulse = isPulseUser();
    const filterWithDripTemplates = { ...filter, IncludeDrip: true, IncludeSystemTemplate: !isPulse };
    fetchEmailTemplates(filterWithDripTemplates, true);
  }

  fetchMessageTemplates(filter) {
    const { fetchMessageTemplates } = this.props;
    const filterWithConsent = { ...filter, IsConsent: true };
    fetchMessageTemplates(filterWithConsent, true);
  }

  handlePanelChange = key => {
    if (!key.length) this.setState({ currentPanel: [] });
    else {
      this.setState({ currentPanel: key });
    }
  };

  getUserGuidFromEmail(email) {
    const { currentUserDetails, impersonatedUserDetails } = this.props;
    const jobSharedWithUsersList = this.getJobSharedWithList();
    const users = jobSharedWithUsersList.filter(user => user.Email === email);
    const currentUserGuid = impersonatedUserDetails
      ? _.get(impersonatedUserDetails, 'UserGuid', null)
      : _.get(currentUserDetails, ['sub'], null);
    return _.get(users[0], 'UserGuid', currentUserGuid);
  }

  onSave() {
    const {
      updateJobConfig,
      jobId,
      toggleSettingsDrawer,
      updateContainerJobConfig,
      featureToggleList,
      messageTemplatesById,
      candidateContext,
      setInvalidTemplateNotification,
      emailTemplatesById,
    } = this.props;
    const {
      sliderValue,
      isEmailTemplatePanelEnabled,
      selectedEmailTemplateFrom,
      isMessageTemplatePanelEnabled,
      selectedEmailTemplateId,
      selectedEmailTemplateName,
      selectedMessageTemplateId,
      selectedEmailTemplateSendTo,
      selectedMessageTemplateSendTo,
      isDripTemplate,
      autoRejectSwitchValue,
      definitionId,
      workflowDefinitionName,
      startTime,
      endTime,
      campaignTriggeredEndTime,
      automationRadioSelected,
      isCampaignAutmationPanelChecked,
    } = this.state;

    const selectedTemplateBodyForMessage = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Body'], undefined);
    const isJobMergeTagsUsedInMessage = checkIfJobMergeTagPresent({
      selectedTemplateBody: selectedTemplateBodyForMessage,
    });
    if (
      candidateContext === 'segment' &&
      ((isJobMergeTagsUsedInMessage && isMessageTemplatePanelEnabled) ||
        (!this.validateEmailTemplate() && isEmailTemplatePanelEnabled))
    ) {
      setInvalidTemplateNotification(candidateContext);
      return;
    }
    const updatedEmailConfiguration = {
      IsEnabled: isEmailTemplatePanelEnabled,
      From: selectedEmailTemplateFrom,
      TemplateId: selectedEmailTemplateId,
      TemplateName: selectedEmailTemplateName,
      SendTo: selectedEmailTemplateSendTo,
      IsDrip: isDripTemplate,
      FromUserGuid: this.getUserGuidFromEmail(selectedEmailTemplateFrom),
    };
    if (selectedEmailTemplateSendTo === SEND_TO.userGivenIdentifier) {
      updatedEmailConfiguration.SendToUserRecommended = true;
    }

    const updatedWorkflowSendTo = {
      RecommendedStatuses: selectedEmailTemplateSendTo,
      Sources: null,
    };

    const updatedWorkflowConfiguration = {
      WorkflowContextUserId: this.getUserGuidFromEmail(selectedEmailTemplateFrom),
      EmailActivityFromAddress: selectedEmailTemplateFrom,
      WorkflowDefinitionId: definitionId,
      WorkflowDefinitionName: workflowDefinitionName,
      IsEnabled: true,
      TriggerTo: updatedWorkflowSendTo,
      StartTime: startTime,
      EndTime: endTime,
      CampaignTriggerEndTime: campaignTriggeredEndTime,
    };
    if (selectedEmailTemplateSendTo === SEND_TO.userGivenIdentifier) {
      updatedWorkflowConfiguration.TriggerToUserRecommended = true;
    }
    const updatedMessageConfiguration = {
      IsEnabled: isMessageTemplatePanelEnabled,
      TemplateId: selectedMessageTemplateId,
      SendTo: selectedMessageTemplateSendTo,
    };
    if (selectedMessageTemplateSendTo === SEND_TO.userGivenIdentifier) {
      updatedMessageConfiguration.SendToUserRecommended = true;
    }

    const isWorkflowRadioActive = automationRadioSelected === 'Workflow' && featureToggleList.Workflow.IsEnabled;

    const updatedJobConfiguration = {
      SourcingConfiguration: {
        SourceLimit: sliderValue,
      },
      AutomationConfiguration: {
        Email: isWorkflowRadioActive ? { SendTo: [] } : updatedEmailConfiguration,
        Message: updatedMessageConfiguration,
        Workflows: isWorkflowRadioActive && isCampaignAutmationPanelChecked ? [updatedWorkflowConfiguration] : [],
      },
      AryaRejectionConfiguration: {
        IsEnabled: autoRejectSwitchValue,
      },
    };
    if (jobId) {
      updateJobConfig(jobId, updatedJobConfiguration);
    } else {
      updateJobConfig(updatedJobConfiguration);
      toggleSettingsDrawer();
    }
    setTimeout(updateContainerJobConfig, 3000);
  }

  handleSliderChange(value) {
    const { SourcingConfiguration, isSourceLimitReducible } = this.props;
    if (SourcingConfiguration?.SourceLimit <= value || isSourceLimitReducible) {
      this.setState({
        sliderValue: value,
      });
    }
  }

  handleAutoRejectSwitchChange(value) {
    this.setState({
      autoRejectSwitchValue: value,
    });
  }

  isEmailConfigUpdated() {
    const {
      selectedEmailTemplateId,
      selectedEmailTemplateSendTo,
      selectedEmailTemplateFrom,
      isEmailTemplatePanelEnabled,
    } = this.state;
    const { AutomationConfiguration } = this.props;

    const currentTemplateId = _.get(AutomationConfiguration, ['Email', 'TemplateId'], undefined);
    let currentTemplateSendTo = _.get(AutomationConfiguration, ['Email', 'SendTo'], SEND_TO.Shortlisted).map(sendTo =>
      sendTo.toLowerCase()
    );
    const sendToUserRecommended = _.get(AutomationConfiguration, ['Email', 'SendToUserRecommended']);
    if (sendToUserRecommended) {
      currentTemplateSendTo = SEND_TO.userGivenIdentifier;
    }
    const currentTemplateFrom = _.get(AutomationConfiguration, ['Email', 'From'], undefined);
    const currentEmailIsEnabled =
      _.get(AutomationConfiguration, ['Email', 'IsEnabled'], false) && !!AutomationConfiguration.Email?.SendTo?.length;
    let isUpdated = false;
    isUpdated =
      currentEmailIsEnabled !== isEmailTemplatePanelEnabled ||
      currentTemplateId !== selectedEmailTemplateId ||
      !_.isEqual(selectedEmailTemplateSendTo, currentTemplateSendTo) ||
      currentTemplateFrom !== selectedEmailTemplateFrom;
    return isUpdated;
  }

  isEmailConfigValid() {
    const {
      isEmailTemplatePanelEnabled,
      selectedEmailTemplateId,
      selectedEmailTemplateFrom,
      selectedEmailTemplateSendTo,
    } = this.state;
    const isEmailTemplateIdValid = isEmailTemplatePanelEnabled && selectedEmailTemplateId !== undefined;
    const isEmailTemplateFromValid = isEmailTemplatePanelEnabled && selectedEmailTemplateFrom !== undefined;
    const isEmailTemplateSendToValid = isEmailTemplatePanelEnabled && selectedEmailTemplateSendTo !== undefined;
    return (
      (isEmailTemplateIdValid && isEmailTemplateFromValid && isEmailTemplateSendToValid) || !isEmailTemplatePanelEnabled
    );
  }

  isMessageConfigUpdated = () => {
    const { isMessageTemplatePanelEnabled, selectedMessageTemplateId, selectedMessageTemplateSendTo } = this.state;
    const { AutomationConfiguration } = this.props;
    const messageConfig = _.get(AutomationConfiguration, 'Message', {});
    const currentTemplateId = _.get(messageConfig, 'TemplateId', undefined);
    const currentTemplateSendTo = _.get(messageConfig, 'SendTo', SEND_TO.Shortlisted).map(sendTo =>
      sendTo.toLowerCase()
    );
    const currentMessageIsEnabled = _.get(messageConfig, 'IsEnabled', false) && !!messageConfig?.SendTo?.length;
    return (
      currentMessageIsEnabled !== isMessageTemplatePanelEnabled ||
      currentTemplateId !== selectedMessageTemplateId ||
      !_.isEqual(selectedMessageTemplateSendTo, currentTemplateSendTo)
    );
  };

  isMessageConfigValid() {
    const { isMessageTemplatePanelEnabled, selectedMessageTemplateId } = this.state;
    const isMessageTemplateIdValid = isMessageTemplatePanelEnabled && selectedMessageTemplateId !== undefined;
    const isMessageTemplateFromValid = isMessageTemplatePanelEnabled;
    return (isMessageTemplateIdValid && isMessageTemplateFromValid) || !isMessageTemplatePanelEnabled;
  }

  getIsEndTimeValid = () => {
    const { startTime, endTime } = this.state;
    const dateFrom = startTime?.split('/')?.join('-');
    const dateTo = endTime?.split('/')?.join('-');
    if (!endTime) return true;
    return !moment(dateTo).isSameOrBefore(dateFrom, 'day');
  };

  getIsCampaignEndTimeValid = () => {
    const { startTime, endTime, campaignTriggeredEndTime } = this.state;
    const startDate = startTime?.split('/')?.join('-');
    const endDate = endTime?.split('/')?.join('-');
    const campaignEndDate = campaignTriggeredEndTime?.split('/')?.join('-');
    const validateCampaignEndDate = endDate ? moment(campaignEndDate).isAfter(endDate, 'day') : false;
    if (!campaignTriggeredEndTime) return true;
    return !(moment(campaignEndDate).isSameOrBefore(startDate, 'day') || validateCampaignEndDate);
  };

  getIsWorkflowConfigUpdated() {
    const { AutomationConfiguration } = this.props;
    const {
      campaignTriggeredEndTime,
      startTime,
      endTime,
      definitionId,
      selectedEmailTemplateSendTo,
      selectedEmailTemplateFrom,
      isEmailTemplatePanelEnabled,
    } = this.state;

    const isStartTimeUpdated = !compareDates({
      initialDate: AutomationConfiguration?.Workflows?.[0]?.StartTime,
      targetDate: startTime,
      dateFormat: 'MM/DD/YYYY',
    });

    const isCampaignTriggeredEndTimeUpdated = !compareDates({
      initialDate: AutomationConfiguration?.Workflows?.[0]?.CampaignTriggerEndTime,
      targetDate: campaignTriggeredEndTime,
      dateFormat: 'MM/DD/YYYY',
    });

    const isEndCampaignEndTimeUpdated = !compareDates({
      initialDate: AutomationConfiguration?.Workflows?.[0]?.EndTime,
      targetDate: endTime,
      dateFormat: 'MM/DD/YYYY',
    });

    return (
      isWorkflowConfigurationUpdated({
        AutomationConfiguration,
        selectedAutomationConfiguration: {
          definitionId,
          selectedEmailTemplateSendTo,
          selectedEmailTemplateFrom,
          isEmailTemplatePanelEnabled,
        },
      }) ||
      isEndCampaignEndTimeUpdated ||
      isCampaignTriggeredEndTimeUpdated ||
      isStartTimeUpdated
    );
  }

  isSaveDisabled() {
    const { sliderValue, autoRejectSwitchValue, automationRadioSelected, startTime, endTime, definitionId } =
      this.state;
    const { SourcingConfiguration, AryaRejectionConfiguration } = this.props;

    const isSourceLimitUpdated = SourcingConfiguration ? SourcingConfiguration.SourceLimit !== sliderValue : false;
    const isAutoRejectConfigUpdated = AryaRejectionConfiguration
      ? AryaRejectionConfiguration.IsEnabled !== autoRejectSwitchValue
      : false;

    const isEmailConfigUpdated = this.isEmailConfigUpdated();
    const isEmailConfigValid = this.isEmailConfigValid();
    const isMessageConfigUpdated = this.isMessageConfigUpdated();
    const isMessageConfigValid = this.isMessageConfigValid();

    const isWorkflowConfigValid =
      getIsValidWorkflowConfiguration(startTime, endTime, definitionId) && this.getIsCampaignEndTimeValid();

    const onlyAutomationConfigUpdated =
      (automationRadioSelected === 'Email' && isEmailConfigUpdated && isEmailConfigValid) ||
      (automationRadioSelected === 'Workflow' && this.getIsWorkflowConfigUpdated() && isWorkflowConfigValid);

    const isMessageConfigUpdatedAndValid = isMessageConfigUpdated && isMessageConfigValid;

    const onlySourceLimitUpdated = isSourceLimitUpdated && !onlyAutomationConfigUpdated && !isAutoRejectConfigUpdated;
    const onlyAutoRejectConfigUpdated =
      !isSourceLimitUpdated && !onlyAutomationConfigUpdated && isAutoRejectConfigUpdated;
    const onlySourceAndRejectConfigUpdated =
      isSourceLimitUpdated && isAutoRejectConfigUpdated && !onlyAutomationConfigUpdated;
    // Simpler logic = (isSourceLimitUpdated || isEmailConfigUpdated || isMessageConfigUpdated || isAutoRejectConfigUpdated) && (isEmailConfigValid && isMessageConfigValid)
    const isSaveEnabled =
      onlySourceLimitUpdated ||
      onlyAutomationConfigUpdated ||
      onlyAutoRejectConfigUpdated ||
      onlySourceAndRejectConfigUpdated ||
      isMessageConfigUpdatedAndValid;
    return !isSaveEnabled;
  }

  onSwitchChange(key, event, checked) {
    const { currentPanel, isEmailTemplatePanelEnabled, isMessageTemplatePanelEnabled, selectedEmailTemplateFrom } =
      this.state;
    const {
      currentUserDetails,
      impersonatedUserDetails,
      AutomationConfiguration,
      featureToggleList,
      userEmails,
      orgDefaultEmail,
      connectSettings,
    } = this.props;
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const currentUserEmail = impersonatedUserDetails ? impersonatedUserDetails.Email : currentUserDetails?.email;
    const isAdminSMTPConfigurationExists = (connectSettings.AdminEmailProviders || []).length !== 0;
    const userEmailProviders = connectSettings.UserEmailProviders;
    const linkedEmails = getProviderLinkedEmails(userEmailProviders);
    const isLinkedEmailsTestSuccesful = getIsLinkedEmailsTestSuccesful({ userEmailProviders });
    const isEmailProviderSMTPConfigurationExists =
      (linkedEmails || []).length !== 0 && !_.isEmpty(isLinkedEmailsTestSuccesful);
    const successfulTestedEmailList = getSuccessfulTestedEmailList({ userEmailProviders });
    const currentSelectedEmail = getCurrentSelectedFromEmail({
      userEmails: combinedEmails,
      currentUserEmail,
      featureToggleList,
      selectedFromEmail: selectedEmailTemplateFrom,
      isAdminSMTPConfigurationExists,
      isEmailProviderSMTPConfigurationExists,
      orgDefaultEmail: orgDefaultEmail?.EmailId?.toLowerCase(),
      successfulTestedEmailList,
    });

    if (key === '1') {
      if (!currentPanel.includes('1')) {
        if (isEmailTemplatePanelEnabled) {
          this.setState({
            isEmailTemplatePanelEnabled: false,
          });
        } else {
          this.setState({
            isEmailTemplatePanelEnabled: true,
            selectedEmailTemplateFrom: _.get(AutomationConfiguration, ['Email', 'From'], currentSelectedEmail),
            currentPanel: [...currentPanel, '1'],
          });
        }
      } else {
        this.setState({
          isEmailTemplatePanelEnabled: false,
          currentPanel: currentPanel.filter(item => item !== '1'),
        });
      }
      this.setState({
        isCampaignAutmationPanelChecked: checked,
      });
    } else if (key === '2') {
      if (!currentPanel.includes('2')) {
        if (isMessageTemplatePanelEnabled) {
          this.setState({
            isMessageTemplatePanelEnabled: false,
          });
        } else {
          this.setState({ isMessageTemplatePanelEnabled: true, currentPanel: ['2'] });
        }
      } else {
        this.setState({
          isMessageTemplatePanelEnabled: false,
          currentPanel: [],
        });
      }
    }
    event.stopPropagation();
  }

  onEmailFromChange(emailId) {
    this.setState({
      selectedEmailTemplateFrom: emailId,
    });
  }

  onChangeEmailTemplate(emailTemplateId) {
    const { emailTemplatesById, getDripTemplate, getEmailTemplate, dripTemplatesById } = this.props;
    const { Name: selectedTemplateName } = getEmailTemplateDetails(
      emailTemplateId,
      emailTemplatesById,
      dripTemplatesById
    );
    const isDrip = _.get(emailTemplatesById, [emailTemplateId, 'IsDrip'], false);
    if (isDrip) {
      getDripTemplate(emailTemplateId);
    } else {
      getEmailTemplate(emailTemplateId);
    }
    this.setState({
      selectedEmailTemplateId: emailTemplateId,
      selectedEmailTemplateName: selectedTemplateName,
      isDripTemplate: isDrip,
    });
  }

  setIsJunkWordFound = value => {
    this.setState({ isJunkWordFound: value });
  };

  setJunkWordsFound = value => {
    this.setState({ junkWords: value });
  };

  onChangeMessageTemplate(messageTemplateId) {
    const { getTextTemplate, messageTemplatesById } = this.props;
    if (messageTemplateId) {
      getTextTemplate(messageTemplateId);
    }
    this.setState(
      {
        selectedMessageTemplateId: messageTemplateId,
      },
      () => {
        const { selectedMessageTemplateId } = this.state;
        const selectedMessageTemplateBody = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Body'], '');
        debouncedCheckForJunkWordsInText(
          parseTextForJunkDetection(selectedMessageTemplateBody),
          this.setIsJunkWordFound,
          this.setJunkWordsFound
        );
      }
    );
  }

  onEmailSendToChange(value) {
    this.setState({
      selectedEmailTemplateSendTo: value,
    });
  }

  onMessageSendToChange(value) {
    this.setState({
      selectedMessageTemplateSendTo: value,
    });
  }

  toggleEmailContentVisibility() {
    const { emailTemplateContentVisibility } = this.state;
    this.setState({
      emailTemplateContentVisibility: !emailTemplateContentVisibility,
    });
  }

  toggleMessageContentVisibility() {
    const { messageTemplateContentVisibility } = this.state;
    this.setState({
      messageTemplateContentVisibility: !messageTemplateContentVisibility,
    });
  }

  getJobSharedWithList() {
    const { usersById, jobDetails, currentUserDetails, impersonatedUserDetails } = this.props;
    const currentUserEmail = impersonatedUserDetails ? impersonatedUserDetails.Email : currentUserDetails?.email;
    const currentUserGuid = impersonatedUserDetails
      ? impersonatedUserDetails.UserGuid
      : _.get(currentUserDetails, 'sub', null);
    let allUsers = []; // this list include shared user and user created that job
    if (usersById) {
      if (_.get(jobDetails, 'CreatedBy', {})) {
        allUsers.push(
          _.get(jobDetails, 'CreatedBy', {
            Email: currentUserEmail,
            UserGuid: currentUserGuid,
          })
        );
      }
      allUsers = allUsers.concat(_.get(jobDetails, 'SharedWith', []) || []);
      allUsers = allUsers.concat(_.get(jobDetails, 'AssignedTo', []) || []);
    }
    let users = allUsers ? _.uniq(allUsers).map(userId => _.get(usersById, [userId], null)) : [];
    users = users.filter(user => !!user);
    return users;
  }

  getEmailPanelDropdownList = () => {
    const { currentUserDetails, impersonatedUserDetails, version, userEmails, orgDefaultEmail, featureToggleList } =
      this.props;
    const { selectedEmailTemplateFrom } = this.state;
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    let emailsList = [];
    const verifiedEmails = _.filter(
      combinedEmails || [],
      email => _.get(email, ['VerificationStatus'], '').toLowerCase() === 'verified'
    );
    emailsList = verifiedEmails.map(email => email.EmailId);
    const currentUserEmail = impersonatedUserDetails
      ? impersonatedUserDetails.Email
      : _.get(currentUserDetails, ['email'], '');
    if (version === 'ats') {
      if (currentUserEmail) {
        emailsList.unshift(currentUserEmail);
      }
      if (selectedEmailTemplateFrom && selectedEmailTemplateFrom !== currentUserDetails.email)
        emailsList.push(selectedEmailTemplateFrom);
      return _.uniqBy(emailsList, email => email.toLowerCase());
    }
    const isPrimaryFromEmailEnabled = featureToggleList.PrimaryFromEmail.IsEnabled;
    if (!isPrimaryFromEmailEnabled) {
      emailsList = emailsList.filter(email => email.toLowerCase() !== currentUserEmail.toLowerCase());
    }
    return _.uniqBy(emailsList, email => email.toLowerCase());
  };

  checkSelectedEmailHasLinkedSMTP(emailId) {
    const { connectSettings, userEmails, orgDefaultEmail } = this.props;
    const combinedEmails = [orgDefaultEmail, ...userEmails];

    const { UserEmailProviders, AdminEmailProviders } = connectSettings;
    const linkedEmails = getProviderLinkedEmails(UserEmailProviders);
    const lowercaseLinkedEmails = linkedEmails.map(email => email?.toLowerCase());
    let emailsList = [];
    const verifiedEmails = _.filter(
      combinedEmails || [],
      email => _.get(email, ['VerificationStatus'], '').toLowerCase() === 'verified'
    );
    emailsList = verifiedEmails.map(email => email.EmailId?.toLowerCase());
    const updatedEmailId = emailId?.toLowerCase();
    return (
      (emailId && orgDefaultEmail.EmailId?.toLowerCase() === updatedEmailId) ||
      (lowercaseLinkedEmails || []).includes(updatedEmailId) ||
      (AdminEmailProviders || []).length !== 0 ||
      !(emailsList || []).includes(updatedEmailId)
    );
  }

  getMergeTags = () => {
    const { mergeTags, candidateContext } = this.props;
    let emailSubjectMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
    let emailBodyMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'));
    let emailPreHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));

    if (candidateContext === 'segment') {
      emailSubjectMergeTags = emailSubjectMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailBodyMergeTags = emailBodyMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailPreHeaderMergeTags = emailPreHeaderMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
    }
    return { emailSubjectMergeTags, emailBodyMergeTags, emailPreHeaderMergeTags };
  };

  validateEmailTemplate = () => {
    const { emailTemplatesById } = this.props;
    const { selectedEmailTemplateId } = this.state;
    const temp = emailTemplatesById[selectedEmailTemplateId];
    const { PreHeader, Subject, Body } = temp;

    const newMergeTags = this.getMergeTags();
    const { emailSubjectMergeTags, emailBodyMergeTags, emailPreHeaderMergeTags } = newMergeTags;

    const isEmailSubjectValid = validateTemplateContent({ templateContent: Subject, mergeTags: emailSubjectMergeTags });
    const isBobyContentValid = validateTemplateContent({ templateContent: Body, mergeTags: emailBodyMergeTags });
    const isPreHeaderContentValid = validateTemplateContent({
      templateContent: PreHeader,
      mergeTags: emailPreHeaderMergeTags,
    });
    return isEmailSubjectValid && isBobyContentValid && isPreHeaderContentValid;
  };

  render() {
    const {
      toggleSettingsDrawer,
      userConfig,
      emailTemplatesCount,
      messageTemplatesCount,
      emailTemplatesById,
      emailTemplateIds,
      dripTemplatesById,
      messageTemplatesById,
      messageTemplateIds,
      currentUserDetails,
      drawerVisibility,
      jobDetails,
      AutomationConfiguration,
      featureToggleList,
      userConnectConfig,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      emailSmtpConfiguration,
      isSourcingAllowed,
      isCandidateAutoRejectAllowed,
      isSendToOptionAllowed,
      isEmailAutomationNoteAllowed,
      isMessageAutomationNoteAllowed,
      candidateContext = 'job',
      version,
      openWorkflowinNewTab,
      campaignDrawerVisbility,
      jobId,
      availableCredits,
      consentText,
    } = this.props;
    const updateJobConfigApiStatus = _.get(jobDetails, 'updateJobConfigApiStatus', null);
    const {
      emailTemplateContentVisibility,
      messageTemplateContentVisibility,
      currentPanel,
      isEmailTemplatePanelEnabled,
      selectedEmailTemplateFrom,
      selectedEmailTemplateId,
      selectedEmailTemplateSendTo,
      isMessageTemplatePanelEnabled,
      selectedMessageTemplateId,
      selectedMessageTemplateSendTo,
      sliderValue,
      autoRejectSwitchValue,
      automationRadioSelected,
      definitionId,
      startTime,
      endTime,
      campaignTriggeredEndTime,
      isJunkWordFound,
      junkWords,
    } = this.state;

    const availableCreditInfo = getAvailableCredits(availableCredits, 'SMS');
    const creditInfoMessage = getInfoAlertMessage(availableCreditInfo);
    const isPanelContentDisable = !availableCreditInfo || availableCreditInfo === 'N/A';
    const currentUserEmail = _.get(currentUserDetails, 'email', null);
    const verifiedUserEmails = this.getEmailPanelDropdownList();
    const {
      Name: selectedEmailTemplateName,
      Subject: selectedEmailTemplateSubject,
      PreHeader: selectedEmailPreHeader,
      Body: selectedEmailTemplateBody,
    } = getEmailTemplateDetails(selectedEmailTemplateId, emailTemplatesById, dripTemplatesById);
    const selectedDripTemplate = _.get(dripTemplatesById, selectedEmailTemplateId, undefined);
    const selectedMessageTemplateName = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Name'], undefined);
    const selectedMessageTemplateBody = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Body'], undefined);
    const isCandidateAutoRejectEnabled = featureToggleList.AryaRejection.IsEnabled;
    const consentComplianceInfo = getConsentComplianceInfo(jobDetails, userConnectConfig);
    const sourceLimitMinRange = 1;
    const sourceLimitMaxRange = userConfig.MaxSourceLimit ?? 3 * userConfig.DefaultSourceLimit;
    const sourceLimitSliderMarks = {
      [sourceLimitMinRange]: sourceLimitMinRange,
      [sourceLimitMaxRange]: sourceLimitMaxRange,
    };
    const getMessageAutomationNote = () => {
      return isMessageAutomationNoteAllowed ? (
        <BulkCandidatesDownloadFilterInfo
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
        />
      ) : null;
    };
    const isPulse = isPulseUser();
    const defaultPanelChecked =
      _.get(AutomationConfiguration, ['Email', 'IsEnabled'], false) ||
      AutomationConfiguration?.Workflows?.[0]?.IsEnabled;

    let showSourceLimit =
      featureToggleList.JobConfiguration.IsEnabled &&
      featureToggleList.SourceLimitConfiguration.IsEnabled &&
      isSourcingAllowed;
    if (featureToggleList.AdvanceSearchV2.IsEnabled && !featureToggleList.AryaRanking.IsAllowed)
      showSourceLimit = false;

    const unicodeMessage = emojiFinder(selectedMessageTemplateBody).length ? (
      <div
        style={{
          display: 'inline-flex',
          backgroundColor: 'rgb(255,251,230)',
          paddingLeft: '10px',
          alignItems: 'center',
          marginBottom: '1px',
          bottom: '1px',
          marginLeft: '1px',
        }}
      >
        <span style={{ marginBottom: '-1px' }}>
          &nbsp; Unicode (Special characters/emojis) {`" ${emojiFinder(selectedMessageTemplateBody)} "`} detected &nbsp;
        </span>
        <Tooltip
          zIndex={2223}
          title="Utilizing Unicode (special characters/emojis) for text reduces the character count per text from 160 to 70 and may result in carrier flagging text as spam. Their usage also results in an increased number of texts sent and credits used."
          placement="topLeft"
        >
          <ExclamationCircleIcon />
        </Tooltip>
      </div>
    ) : null;

    return (
      <Drawer
        title={
          campaignDrawerVisbility ? (
            <FormattedMessage {...jobMessage.campaignsLabel} />
          ) : (
            <FormattedMessage {...jobMessage.jobSettingsDrawer} />
          )
        }
        placement="right"
        id="settings-drawer"
        visible={drawerVisibility}
        width={924}
        bodyStyle={{ padding: 0 }}
        onClose={toggleSettingsDrawer}
        maskClosable={_.get(jobDetails, 'updateJobConfigApiStatus', '') !== 'INPROGRESS'}
      >
        <div className="job-settings-container">
          <Collapse onChange={this.handlePanelChange} activeKey={currentPanel} destroyInactivePanel>
            {!campaignDrawerVisbility ? (
              <Panel
                header={<FormattedMessage {...jobMessage.emailAutomationLabel} />}
                key="1"
                extra={
                  <Switch
                    checkedChildren={<FormattedMessage {...jobMessage.switchOnLabel} />}
                    unCheckedChildren={<FormattedMessage {...jobMessage.switchOffLabel} />}
                    onChange={(checked, event) => this.onSwitchChange('1', event, checked)}
                    defaultChecked={defaultPanelChecked}
                    checked={isEmailTemplatePanelEnabled}
                  />
                }
                disabled={!isEmailTemplatePanelEnabled}
              >
                <div className="job-settings-panel-content">
                  <EmailAutomationWorkflowWrapper
                    consentComplianceInfo={consentComplianceInfo}
                    selectedEmailTemplateFrom={selectedEmailTemplateFrom}
                    checkSelectedEmailHasLinkedSMTP={this.checkSelectedEmailHasLinkedSMTP}
                    getEmailPanelDropdownList={this.getEmailPanelDropdownList}
                    emailSmtpConfiguration={emailSmtpConfiguration}
                    verifiedUserEmails={verifiedUserEmails}
                    currentUserEmail={currentUserEmail}
                    onEmailFromChange={this.onEmailFromChange}
                    emailTemplateIds={emailTemplateIds}
                    emailTemplatesById={emailTemplatesById}
                    emailTemplatesCount={emailTemplatesCount}
                    selectedDripTemplate={selectedDripTemplate}
                    fetchEmailTemplates={this.fetchEmailTemplates}
                    isPulse={isPulse}
                    selectedEmailTemplateId={selectedEmailTemplateId}
                    selectedEmailTemplateName={selectedEmailTemplateName}
                    setDefaultEmailTemplateValues={this.setDefaultEmailTemplateValues}
                    emailTemplates={Object.values(emailTemplatesById)}
                    onChangeEmailTemplate={this.onChangeEmailTemplate}
                    emailTemplateContentVisibility={emailTemplateContentVisibility}
                    toggleEmailContentVisibility={this.toggleEmailContentVisibility}
                    selectedEmailTemplateSubject={selectedEmailTemplateSubject}
                    selectedEmailPreHeader={selectedEmailPreHeader}
                    ref={this.myRef}
                    updateBodyContent={this.updateBodyContent}
                    selectedEmailTemplateBody={selectedEmailTemplateBody}
                    selectedEmailTemplateSendTo={selectedEmailTemplateSendTo}
                    onEmailSendToChange={this.onEmailSendToChange}
                    candidateDownloadFilterConfig={candidateDownloadFilterConfig}
                    candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
                    sendEmailTo={SEND_TO}
                    isSendToOptionAllowed={isSendToOptionAllowed}
                    isEmailAutomationNoteAllowed={isEmailAutomationNoteAllowed}
                    cssClassname="job-settings-template-from-selector"
                    templateSelectorClassname="job-settings-template-selector"
                    setSelctedWorkflowTemplate={this.setSelctedWorkflowTemplate}
                    definitionId={definitionId}
                    startTime={startTime}
                    endTime={endTime}
                    campaignTriggeredEndTime={campaignTriggeredEndTime}
                    setDefinitionId={this.setDefinitionId}
                    setEndTime={this.setEndTime}
                    setStartTime={this.setStartTime}
                    getIsEndTimeValid={this.getIsEndTimeValid}
                    getIsCampaignEndTimeValid={this.getIsCampaignEndTimeValid}
                    setCampaignTriggeredEndTime={this.setCampaignTriggeredEndTime}
                    onChangeAutomationRadioOption={this.onChangeAutomationRadioOption}
                    automationRadioSelected={automationRadioSelected}
                    featureToggleList={featureToggleList}
                    setAutomationPanelCheckedStatus={this.setAutomationPanelCheckedStatus}
                    setWorkflowDefinitionName={this.setWorkflowDefinitionName}
                    candidateContext={candidateContext}
                    version={version}
                    openWorkflowinNewTab={openWorkflowinNewTab}
                    campaignDrawerVisbility={campaignDrawerVisbility}
                  />
                </div>
              </Panel>
            ) : null}
            {!campaignDrawerVisbility ? (
              <Panel
                header={<FormattedMessage {...jobMessage.textMessagingAutomationLabel} />}
                key="2"
                extra={
                  <Switch
                    checkedChildren={<FormattedMessage {...jobMessage.switchOnLabel} />}
                    unCheckedChildren={<FormattedMessage {...jobMessage.switchOffLabel} />}
                    onChange={(checked, event) => this.onSwitchChange('2', event, checked)}
                    defaultChecked={_.get(AutomationConfiguration, ['Message', 'IsEnabled'], false)}
                    checked={isMessageTemplatePanelEnabled}
                  />
                }
                disabled={!isMessageTemplatePanelEnabled}
              >
                <div style={{ marginLeft: '20px' }}>{creditInfoMessage}</div>
                <div className={`job-settings-panel-content ${isPanelContentDisable ? 'panel-content-disable' : ''}`}>
                  <p className="job-settings-panel-description">
                    <FormattedMessage {...jobMessage.automatedTextMessagesToCandidatesLabel} />
                  </p>
                  {candidateContext === 'segment' ? (
                    <div className="templateLabel">
                      <FormattedMessage {...jobMessage.pleaseSelectACompatibleTemplateLabel} />
                    </div>
                  ) : null}
                  <div style={{ display: 'flex', pointerEvents: isPanelContentDisable ? 'none' : '' }}>
                    <ConnectTemplateSelect
                      cssClassname="job-settings-template-selector"
                      templatesById={messageTemplatesById}
                      templateIds={messageTemplateIds}
                      templatesCount={messageTemplatesCount}
                      fetchTemplates={this.fetchMessageTemplates}
                      selectedTemplateId={selectedMessageTemplateId}
                      selectedTemplateName={selectedMessageTemplateName}
                      placeholderValue={<FormattedMessage {...jobMessage.selectTemplateRequiredLabel} />}
                      onChange={this.onChangeMessageTemplate}
                    />
                    {selectedMessageTemplateName ? (
                      <Button type="link" onClick={this.toggleMessageContentVisibility}>
                        {messageTemplateContentVisibility ? (
                          <FormattedMessage {...jobMessage.hideContentLabel} />
                        ) : (
                          <FormattedMessage {...jobMessage.seeContentLabel} />
                        )}
                      </Button>
                    ) : null}
                  </div>
                  {messageTemplateContentVisibility ? (
                    <div>
                      <div>
                        <div className="connect-template-body">
                          <RichTextEditor
                            editorContent={{ htmlContent: selectedMessageTemplateBody }}
                            onChange={this.updateBodyContent}
                            showAdditionalOptions
                            showToolbar={false}
                            disabled
                            editorContext="Message"
                          />
                        </div>
                        <div>
                          {isJunkWordFound ? <SpamAlertText junkWords={junkWords} /> : null}
                          {unicodeMessage}
                        </div>
                      </div>
                      <div
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <div>
                          <div style={{ margin: '15px' }}>
                            <span style={{ fontWeight: 'bold', color: '#000000' }}>
                              <FormattedMessage {...jobMessage.belowTextWillBeAppendedLabel} />
                            </span>
                          </div>
                          <div className="message-window-template-consent-text">{consentText}</div>
                        </div>

                        <div
                          style={{
                            marginLeft: '15px',
                            justifyContent: 'center',
                            paddingTop: '5px',
                            paddingBottom: '-5px',
                            display: 'inline-flex',
                          }}
                        >
                          {messageCount(selectedMessageTemplateBody, true)} &nbsp;
                          <div style={{ marginTop: '2px' }}>
                            <Tooltip
                              zIndex={2223}
                              title={<FormattedMessage {...jobMessage.totalCharactersLabel} />}
                              placement="rightBottom"
                            >
                              <ExclamationCircleIcon />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {candidateContext !== 'segment' ? (
                    <div
                      className="send-to-filter-container"
                      style={{ pointerEvents: isPanelContentDisable ? 'none' : '' }}
                    >
                      <div className="send-to-filter-label">
                        <FormattedMessage {...message.sendToLabel} />
                      </div>
                      <AutomationSendToSelection
                        onChange={this.onMessageSendToChange}
                        value={selectedMessageTemplateSendTo}
                        sendTo={SEND_TO}
                        isScoreAndRankAutomationEnabled={featureToggleList.ScoreAndRankAutomation.IsEnabled}
                      />
                    </div>
                  ) : null}
                  {getMessageAutomationNote()}
                </div>
              </Panel>
            ) : null}
            {showSourceLimit ? (
              <Panel header={<FormattedMessage {...jobMessage.sourcingLabel} />} key="3" onClick={this.handelClick}>
                <div className="panel-content">
                  <p>
                    <FormattedMessage {...jobMessage.sourcingConfigUpdateLabel} />
                  </p>
                  <h4>
                    <FormattedMessage {...jobMessage.singleListCandidateSourceLimit} />
                    &nbsp;
                    <InfoIconWithTooltip
                      tooltipTitle={<FormattedMessage {...jobMessage.singleListCandidateLimitDescription} />}
                    />
                  </h4>
                  <br />
                  <div id="slider">
                    <Slider
                      value={sliderValue}
                      onChange={this.handleSliderChange}
                      min={sourceLimitMinRange}
                      max={sourceLimitMaxRange}
                      marks={sourceLimitSliderMarks}
                      tooltipVisible
                      tooltipPrefixCls="slider-settings-tooltip ant-tooltip"
                      getTooltipPopupContainer={trigger => trigger.parentNode}
                    />
                  </div>
                </div>
              </Panel>
            ) : null}

            {isCandidateAutoRejectEnabled && isCandidateAutoRejectAllowed ? (
              <Panel
                header={<FormattedMessage {...jobMessage.candidateAutoRejectionLabel} />}
                key="4"
                onClick={this.handelClick}
              >
                <div className="job-settings-panel-content">
                  <CandidateAutoRejectSwitch
                    autoRejectValue={autoRejectSwitchValue}
                    onChange={this.handleAutoRejectSwitchChange}
                  />
                </div>
              </Panel>
            ) : null}

            {campaignDrawerVisbility ? (
              <Panel
                header={<FormattedMessage {...jobMessage.campaignStatsHistoryLabel} />}
                key="5"
                onClick={this.handelClick}
              >
                <CampaignStats jobId={jobId} />
              </Panel>
            ) : null}
          </Collapse>
          {!campaignDrawerVisbility ? (
            <div id="confirmation-buttons">
              <Button
                id="cancel-btn"
                disabled={updateJobConfigApiStatus === 'INPROGRESS'}
                onClick={() => toggleSettingsDrawer()}
              >
                <FormattedMessage {...jobMessage.cancelButton} />
              </Button>
              <Button
                type="primary"
                disabled={this.isSaveDisabled() || !this.checkSelectedEmailHasLinkedSMTP(selectedEmailTemplateFrom)}
                id="save-btn"
                onClick={this.onSave}
                loading={updateJobConfigApiStatus === 'INPROGRESS'}
              >
                <FormattedMessage {...jobMessage.saveButtonText} />
              </Button>
            </div>
          ) : null}
        </div>
      </Drawer>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(JobSettings);

export { JobSettings as JobSettingsContainerWithoutStore };
