import React from 'react';
import { connect } from 'react-redux';
import * as AryaPayActions from '../Actions/AryaPayActions';
import AdminSettings from '../Containers/Settings/AdminSettings/AdminSettings';
import * as ConfigActions from '../Actions/ConfigActions';
import * as ConnectSettingsActions from '../Actions/ConnectSettingsActions';
import * as JobActions from '../Actions/JobActions';
import { getConfig, getConnectConfig, getFetchUserConfigApiStatus } from '../Reducers/ConfigReducer';
import { getConnectSettings } from '../Reducers/ConnectSettingsReducer';
import { getJobUtilities } from '../Reducers/JobReducer';
import { getAvailableCredits } from '../Reducers/AryaPayReducer';
import { getApiStatus } from '../Reducers/ApiStatusReducer';

const mapStateToProps = state => ({
  userConfig: getConfig(state),
  userConnectConfig: getConnectConfig(state),
  connectConfig: getConnectSettings(state),
  utilities: getJobUtilities(state),
  availableCredits: getAvailableCredits(state),
  requestForCreditsApiStatus: getApiStatus(state, 'requestCreditsStatus'),
  fetchUserConfigApiStatus: getFetchUserConfigApiStatus(state),
});

const mapDispatchToProps = {
  fetchConfig: ConfigActions.fetchConfig,
  fetchConnectConfig: ConfigActions.fetchConnectConfig,
  patchUpdateConfig: ConfigActions.patchUpdateConfig,
  fetchCallerIds: ConnectSettingsActions.fetchCallerIds,
  fetchContactType: ConnectSettingsActions.fetchContactType,
  fetchAvailableCredits: AryaPayActions.fetchAvailableCredits,
  updateContactType: ConnectSettingsActions.updateContactType,
  updateCallerIds: ConnectSettingsActions.updateCallerIds,
  fetchCountries: JobActions.fetchCountries,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchContactProviders: ConnectSettingsActions.fetchContactProviders,
  bulkSubscribeContactProviders: ConnectSettingsActions.bulkSubscribeContactProviders,
  requestForCredits: AryaPayActions.requestForCredits,
};

function AdminSettingsContainer(props) {
  const {
    userConnectConfig,
    patchUpdateConfig,
    userConfig,
    fetchCallerIds,
    fetchContactType,
    fetchAvailableCredits,
    updateContactType,
    updateCallerIds,
    utilities,
    fetchCountries,
    fetchEmailConfig,
    connectConfig,
    fetchContactProviders,
    bulkSubscribeContactProviders,
    availableCredits,
    requestForCredits,
    requestForCreditsApiStatus,
  } = props;
  return (
    <AdminSettings
        userConnectConfig={userConnectConfig}
        patchUpdateConfig={patchUpdateConfig}
        userConfig={userConfig}
        fetchCallerIds={fetchCallerIds}
        fetchContactType={fetchContactType}
        fetchAvailableCredits={fetchAvailableCredits}
        updateContactType={updateContactType}
        updateCallerIds={updateCallerIds}
        utilities={utilities}
        fetchCountries={fetchCountries}
        fetchEmailConfig={fetchEmailConfig}
        callerIds={connectConfig?.CallerIds ?? []}
        contactSettings={connectConfig?.ContactSettings ?? {}}
        fetchContactProviders={fetchContactProviders}
        bulkSubscribeContactProviders={bulkSubscribeContactProviders}
        availableCredits={availableCredits}
        requestForCredits={requestForCredits}
        requestForCreditsApiStatus={requestForCreditsApiStatus}
        aryaVersion={userConfig?.SubscriptionType}
      />
  );
}
const PackageAdminSettings = connect(mapStateToProps, mapDispatchToProps)(AdminSettingsContainer);
export default PackageAdminSettings;
export { AdminSettingsContainer as AdminSettingsContainerWithoutStore };
