import React from 'react';
import { connect } from 'react-redux';
import CandidateProfileContactsTab from '../../Components/CandidateContacts/CandidateProfileContactsTab';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getAllMailCount, getMailCount } from '../../Reducers/MailConversationsReducer';
import {
  getAllMessageConversation,
  getMessageConversationOfAllPhoneNumbers,
} from '../../Reducers/MessageConversationsReducer';
import { getAllCallConversationsCount, getCallConversationsCount } from '../../Reducers/CallConversationsReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as connectActions from '../../Actions/ConnectActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';

const mapStateToProps = (state, ownProps) => ({
  connectInfo: getCandidatesConnectInfo(state),
  featureToggleList: getFeatureToggleList(state),
  allContextualMailCount: getAllMailCount(state, ownProps.candidate.PersonId),
  contextualMailCount: getMailCount(state, ownProps.candidate.ConversationId),
  sms: getMessageConversationOfAllPhoneNumbers(state, ownProps.candidate.ConversationId),
  allSms: getAllMessageConversation(state, ownProps.candidate.PersonId),
  candidatesConnectInfo: getCandidatesConnectInfo(state),
  contextualMailsConversationApiStatus: getApiStatus(state, 'setContextualMailsConversationApiStatus'),
  allContextualMailsConversationApiStatus: getApiStatus(state, 'setAllContextualMailsConversationApiStatus'),
  callConversationsCount: getCallConversationsCount(state, ownProps.candidate.ConversationId),
  allCallConversationsCount: getAllCallConversationsCount(state, ownProps.candidate.PersonId),
});

const mapDispatchToProps = {
  fetchMails: connectActions.fetchMails,
  postCallNotes: connectActions.postCallNotes,
  fetchEmailTemplates: connectActions.fetchEmailTemplates,
  resetMails: connectActions.resetMails,
  fetchLatestMessages: connectActions.fetchLatestMessages,
  fetchCalls: connectActions.fetchCalls,
};

function CandidateProfileContactsContainer(props) {
  const {
    connectInfo,
    candidate,
    jobId,
    currentJobDetails,
    jobGuid,
    featureToggleList,
    jobCountryCode,
    postCallNotes,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    version,
    mailRef,
    allMailRef,
    smsRef,
    allSmsRef,
    callRef,
    isCandidateViewHeaderVisible,
    isComposeEmailModalVisible,
    setEmailComposeModalVisibility,
    isComposeMessageModalVisible,
    setMessageComposeModalVisibility,
    currentSelectedEmail,
    setCurrentSelectedEmail,
    phoneNumber,
    setPhoneNumber,
    isMailCommunicationAllowed,
    isMessageCommunicationAllowed,
    openSipCallWindowsApp,
    isContactTabRedirection,
    setCandidateViewHeaderVisibility,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
    contactsTabRef,
    isUnsubscribeCandidateEnabled,
    unsubscribeCandidateButton,
    isComposeWorkflowModalVisible,
    setWorkflowComposeModalVisibility,
    workflowSelectedEmails,
    setWorkflowSelectedEmails,
    fetchEmailTemplates,
    fetchMails,
    allContextualMailCount,
    contextualMailCount,
    candidatesConnectInfo,
    resetMails,
    contextualMailsConversationApiStatus,
    allContextualMailsConversationApiStatus,
    setMessageTab,
    allSms,
    sms,
    fetchLatestMessages,
    openCandidateView,
    openJobViewInNewTabCallBack,
    setActiveEmailTabKey,
    activeEmailTabKey,
    currentEmailPage,
    setCurrentEmailPage,
    currentAllEmailPage,
    setCurrentAllEmailPage,
    allCallConversationsCount,
    callConversationsCount,
    fetchCalls,
  } = props;
  return (
    <div>
      <CandidateProfileContactsTab
        connectInfo={connectInfo}
        candidate={candidate}
        jobId={jobId}
        currentJobDetails={currentJobDetails}
        jobGuid={jobGuid}
        featureToggleList={featureToggleList}
        jobCountryCode={jobCountryCode}
        postCallNotes={postCallNotes}
        toggleBotConfigDrawer={toggleBotConfigDrawer}
        candidateContext={candidateContext}
        activeSubTab={activeSubTab}
        onSubTabClick={onSubTabClick}
        version={version}
        mailRef={mailRef}
        allMailRef={allMailRef}
        smsRef={smsRef}
        allSmsRef={allSmsRef}
        callRef={callRef}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        isComposeEmailModalVisible={isComposeEmailModalVisible}
        setEmailComposeModalVisibility={setEmailComposeModalVisibility}
        isComposeMessageModalVisible={isComposeMessageModalVisible}
        setMessageComposeModalVisibility={setMessageComposeModalVisibility}
        currentSelectedEmail={currentSelectedEmail}
        setCurrentSelectedEmail={setCurrentSelectedEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        isMessageCommunicationAllowed={isMessageCommunicationAllowed}
        isMailCommunicationAllowed={isMailCommunicationAllowed}
        openSipCallWindowsApp={openSipCallWindowsApp}
        isContactTabRedirection={isContactTabRedirection}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
        isConnectIconsEnabled={isConnectIconsEnabled}
        isEmailReadVisible={isEmailReadVisible}
        isAudioReadVisible={isAudioReadVisible}
        isMessageReadVisible={isMessageReadVisible}
        composeEmailType={composeEmailType}
        setComposeEmailType={setComposeEmailType}
        contactsTabRef={contactsTabRef}
        isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
        unsubscribeCandidateButton={unsubscribeCandidateButton}
        isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
        setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
        workflowSelectedEmails={workflowSelectedEmails}
        setWorkflowSelectedEmails={setWorkflowSelectedEmails}
        fetchEmailTemplates={fetchEmailTemplates}
        fetchMails={fetchMails}
        allContextualMailCount={allContextualMailCount}
        contextualMailCount={contextualMailCount}
        candidatesConnectInfo={candidatesConnectInfo}
        resetMails={resetMails}
        contextualMailsConversationApiStatus={contextualMailsConversationApiStatus}
        allContextualMailsConversationApiStatus={allContextualMailsConversationApiStatus}
        setMessageTab={setMessageTab}
        allSms={allSms}
        sms={sms}
        fetchLatestMessages={fetchLatestMessages}
        openCandidateView={openCandidateView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        setActiveEmailTabKey={setActiveEmailTabKey}
        activeEmailTabKey={activeEmailTabKey}
        currentEmailPage={currentEmailPage}
        setCurrentEmailPage={setCurrentEmailPage}
        currentAllEmailPage={currentAllEmailPage}
        setCurrentAllEmailPage={setCurrentAllEmailPage}
        allCallConversationsCount={allCallConversationsCount}
        callConversationsCount={callConversationsCount}
        fetchCalls={fetchCalls}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileContactsContainer);
