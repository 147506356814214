import React from 'react';
import uuid from 'uuid';
import { Table, Tooltip } from 'antd';
import styles from './ReportListWrapper.module.scss';
import EmptyReportList from './EmptyReportList/EmptyReportList';
import InfoIconWithTooltip from '../../../../../Common/InfoIconWithTooltip/InfoIconWithTooltip';

const { Column } = Table;

export default function ReportListWrapper(props) {
  const {
    reportList,
    fetchReportStatsApiStatus,
    title,
    selectedReportColumns,
    defaultReportColumnsByAryaName,
    featureToggleList,
  } = props;
  const {
    MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
  } = featureToggleList;
  return (
    <div className={styles.reportListWrapper}>
      {reportList?.length ? (
        <Table
          dataSource={reportList}
          size="small"
          loading={fetchReportStatsApiStatus?.status?.toUpperCase() === 'INPROGRESS'}
          pagination={false}
          className={styles.reportTable}
          scroll={{ y: 400, x: '100%' }}
          key={fetchReportStatsApiStatus?.status}
          rowKey={record => uuid.v4()}
        >
          {selectedReportColumns?.map((columnAryaName, index) => {
            let columnDisplayName = defaultReportColumnsByAryaName?.[columnAryaName]?.DisplayName;
            const columnDisplayNameFirstWord = columnDisplayName.split(' ')[0];
            if (columnDisplayNameFirstWord === 'Active' && isMaskActiveAsInternalEnabled)
              columnDisplayName = columnDisplayName.replace('Active', 'Internal');

            const columnDescription = defaultReportColumnsByAryaName?.[columnAryaName]?.DisplayInfo;
            return (
              !(columnDisplayNameFirstWord === 'Internal' && isMaskActiveAsInternalEnabled) && (
                <Column
                  title={
                    <div className={styles.columnTitle}>
                      <Tooltip title={columnDisplayName}>
                        <div className={styles.columnHeaderTitle}>{columnDisplayName}</div>
                      </Tooltip>
                      {columnDescription ? <InfoIconWithTooltip tooltipTitle={columnDescription} /> : null}
                    </div>
                  }
                  dataIndex={columnAryaName}
                  key={columnAryaName}
                  width={160}
                  ellipsis
                  align={index === 0 ? 'left' : 'center'}
                />
              )
            );
          })}
        </Table>
      ) : (
        <EmptyReportList
          title={`${title} will come here`}
          subtitle={`Use the form above to see ${title?.toLowerCase()}`}
        />
      )}
    </div>
  );
}
