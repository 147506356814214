import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { message, Tooltip, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import SearchCriteriaSelect from './SearchCriteriaSelect/SearchCriteriaSelect';
import SearchSavedCriteriaModal from './Modals/SearchSavedCriteriaModal/SearchSavedCriteriaModal';
import SaveSearchCriteriaModal from './Modals/SaveSearchCriteriaModal/SaveSearchCriteriaModal';
import {
  getManualSearchCriteria,
  getManualSearchCriteriaIdentifiers,
  getManualSearchSelectedCriteriaId,
} from '../../../Reducers/ManualSearchReducer';
import {
  getDefaultFormValuesFromCriteria,
  getDefaultFormValuesFromAryaGeneratedCriteria,
  generateManualSearchPayload,
} from '../../../Utils/ManualSearchUtils';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getJobDetails } from '../../../Reducers/JobReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import styles from './ManualSearchFormHeaderOptions.module.scss';
import eventTypes from '../../../Analytics/EventTypes';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import SearchCriteriaSelectWithAgent from './SearchCriteriaSelect/SearchCriteriaSelectWithAgent';
import { getScoutingAgentSearchCriteriaModalStatus } from '../../../Reducers/ScoutingAgentReducer';
import messages from '../ManualSearchMessages';
import { setNotification } from '../../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  criteriaIdentifiers: getManualSearchCriteriaIdentifiers(state, { jobId: props.jobId, criteriaType: 'Saved' }),
  selectedAgentIdToViewCriteria: getManualSearchSelectedCriteriaId(state, { jobId: props.jobId }),
  aryaGeneratedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'AryaGenerated' }),
  currentJobDetails: getJobDetails(state, props.jobId),
  fetchManualSearchCriteriaIdentifiersApiStatus: getApiStatus(state, 'fetchManualSearchCriteriaIdentifiersApiStatus'),
  featureToggleList: getFeatureToggleList(state),
  isModalVisible: getScoutingAgentSearchCriteriaModalStatus(state),
});

const mapDispatchToProps = {
  fetchManualSearchCriteriaIdentifiers: manualSearchActions.fetchManualSearchCriteriaIdentifiers,
  clearManualSearchCriteriaIdentifiers: manualSearchActions.clearManualSearchCriteriaIdentifiers,
  fetchManualSearchCriteriaById: manualSearchActions.fetchManualSearchCriteriaById,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  setManualSearchFormErrorNotification: manualSearchActions.setManualSearchFormErrorNotification,
  setSelectedCriteriaType: manualSearchActions.setSelectedCriteriaType,
  setSelectedCriteriaName: manualSearchActions.setSelectedCriteriaName,
  setAgentIdViewCriteria: manualSearchActions.setSelectedCriteriaId,
  setSelectedScoutingAgentId: ScoutingAgentActions.setSelectedScoutingAgentIdAction,
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
  setIsModalVisible: ScoutingAgentActions.setScoutingAgentSearchCriteriaModalVisibility,
  setManualSearchSelectedCriteriaInfo: manualSearchActions.setManualSearchSelectedCriteriaInfo,
  SetNotification: setNotification,
};

function isNumericString(str) {
  return /^\d+$/.test(str);
}

function ManualSearchFormHeaderOptions(props) {
  const {
    criteriaIdentifiers,
    form,
    setDefaultFormValues,
    aryaGeneratedCriteria,
    currentJobDetails,
    fetchManualSearchCriteriaIdentifiersApiStatus,
    jobId,
    fetchManualSearchCriteriaIdentifiers,
    clearManualSearchCriteriaIdentifiers,
    saveManualSearchCriteria,
    advancedSettings,
    fetchManualSearchCriteriaById,
    updateManualSearchCriteria,
    setManualSearchFormErrorNotification,
    setSelectedCriteriaName,
    currentUserDetails,
    setAdvancedSettings,
    setIsReactivationAllowedFlag,
    AppName,
    candidateContext,
    featureToggleList,
    setSelectedScoutingAgentId,
    setSelectedCriteriaType,
    selectedAgentIdToViewCriteria,
    setAgentIdViewCriteria,
    isModalVisible,
    setIsModalVisible,
    setManualSearchSelectedCriteriaInfo,
    SetNotification,
    intl,
  } = props;
  const [isSaveSearchCriteriaModalVisible, setIsSaveSearchCriteriaModalVisible] = React.useState(false);
  const [selectedCriteriaId, setSelectedCriteriaId] = React.useState();
  const [criteriaName, setCriteriaName] = React.useState();
  const [isSaveSearchCriteriaFailureModalVisible, setSaveSearchCriteriaFailureModalVisible] = React.useState(false);
  const [criteriaId, setCriteriaId] = React.useState();

  const handleSaveCriteriaSuccess = () => {
    setSelectedCriteriaName({ jobId, criteriaName });
    SetNotification('SUCCESS', {
      messageId: 'searchCriteriaSavedLabel',
    });
  };

  const onApplyCriteria = async () => {
    try {
      const criteria = await fetchManualSearchCriteriaById({ id: selectedCriteriaId ?? selectedAgentIdToViewCriteria });
      const selectedCriteriaTypeLocal = !isNumericString(selectedCriteriaId ?? selectedAgentIdToViewCriteria)
        ? 'Agent'
        : 'Criteria';
      if (selectedCriteriaTypeLocal === 'Criteria') {
        setManualSearchSelectedCriteriaInfo({
          jobId,
          criteriaInfo: {
            selectedManualSearchCriteriaId: selectedCriteriaId,
            selectedManualSearchCriteriaName: criteria.CriteriaName,
          },
        });
      }
      const defaultFormValues = await getDefaultFormValuesFromCriteria({ criteria, currentJobDetails });
      if (!isNumericString(selectedCriteriaId ?? selectedAgentIdToViewCriteria))
        setSelectedScoutingAgentId(selectedCriteriaId ?? selectedAgentIdToViewCriteria);
      setDefaultFormValues(defaultFormValues);
      setAdvancedSettings({});
      form.resetFields();
      SetNotification('SUCCESS', {
        messageId: 'criteriaUpdatedLabel',
      });
      setSelectedCriteriaType({ jobId, selectedCriteriaType: selectedCriteriaTypeLocal });
      setAgentIdViewCriteria({ jobId, criteriaId: null });
      setSelectedCriteriaName({ jobId, criteriaName: criteria.CriteriaName });
      setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
    } catch {
      SetNotification('ERROR', {
        messageId: 'updatingCriteriaErrorLabel',
      });
    }
    setSelectedCriteriaId(undefined);
  };

  const onSelect = id => {
    setIsModalVisible(true);
    setSelectedCriteriaId(id);
  };

  const showSaveSearchCriteriaModal = () => {
    form.validateFieldsAndScroll(error => {
      if (error) {
        setManualSearchFormErrorNotification({ errorFields: Object.keys(error) }, intl);
        return;
      }
      setIsSaveSearchCriteriaModalVisible(true);
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onApplyCriteria();
  };

  const handleCriteriaModalsVisibility = response => {
    const { isSuccess, code, status, id } = response;
    if (isSuccess && isSaveSearchCriteriaModalVisible) {
      setIsSaveSearchCriteriaModalVisible(false);
      handleSaveCriteriaSuccess();
    } else if (!isSuccess && code === 'CONFLICTING_CANDIDATESEARCHCRITERIA' && status === 409) {
      setIsSaveSearchCriteriaModalVisible(false);
      setSaveSearchCriteriaFailureModalVisible(true);
      setCriteriaId(id);
    }
  };

  const getCriteria = () => {
    const payload = generateManualSearchPayload({
      formValues: form.getFieldsValue(),
      locationType: form.getFieldValue('LocationType'),
      activeSearchString: form.getFieldValue('ActiveSearchString'),
      advancedSettings,
      isAdvancedSearchV2Enabled: true,
    });
    return {
      JobId: jobId,
      CriteriaName: criteriaName,
      Criteria: { ...payload, Locations: [], Experience: null, Sources: [], Name: null, Email: null, Mobile: null },
    };
  };

  const handleUpdate = async () => {
    const criteria = getCriteria();
    await updateManualSearchCriteria({ criteria, id: criteriaId });
    setSaveSearchCriteriaFailureModalVisible(false);
    handleSaveCriteriaSuccess();
  };

  const handleSaveCriteria = async isPublic => {
    const criteria = getCriteria();
    criteria.IsPublic = isPublic;
    const response = await saveManualSearchCriteria({ criteria });
    handleCriteriaModalsVisibility(response);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCriteriaId(undefined);
    setAgentIdViewCriteria({ jobId, criteriaId: null });
  };

  const handleResetButtonClick = async () => {
    const defaultFormValues = await getDefaultFormValuesFromAryaGeneratedCriteria({
      aryaGeneratedCriteria,
      currentJobDetails,
      currentUserDetails,
      candidateContext,
    });
    setDefaultFormValues(defaultFormValues);
    setAdvancedSettings({});
    form.resetFields();
    setSelectedCriteriaName({ jobId, criteriaName: undefined });
    setSelectedCriteriaType({ jobId, selectedCriteriaType: null });
    setAgentIdViewCriteria({ jobId, criteriaId: null });
    setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
  };

  const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false);

  return (
    <>
      <div className={styles.manualSearchManualSearchFormHeaderOptions}>
        {isScoutingAgentEnabled ? (
          <SearchCriteriaSelectWithAgent
            jobId={jobId}
            onSelect={onSelect}
            featureToggleList={featureToggleList}
            criteriaIdentifiers={criteriaIdentifiers}
            fetchManualSearchCriteriaIdentifiersApiStatus={fetchManualSearchCriteriaIdentifiersApiStatus}
            fetchManualSearchCriteriaIdentifiers={fetchManualSearchCriteriaIdentifiers}
            clearManualSearchCriteriaIdentifiers={clearManualSearchCriteriaIdentifiers}
          />
        ) : (
          <>
            <SearchCriteriaSelect
              jobId={jobId}
              onSelect={onSelect}
              criteriaIdentifiers={criteriaIdentifiers}
              fetchManualSearchCriteriaIdentifiersApiStatus={fetchManualSearchCriteriaIdentifiersApiStatus}
              fetchManualSearchCriteriaIdentifiers={fetchManualSearchCriteriaIdentifiers}
              clearManualSearchCriteriaIdentifiers={clearManualSearchCriteriaIdentifiers}
            />
            <Tooltip title={<FormattedMessage {...messages.saveSearchCriteria} />}>
              <div
                className={styles.saveIcon}
                onClick={showSaveSearchCriteriaModal}
                sk-event-name={eventTypes.job.manualSearch.saveManualSearchCriteria}
              >
                <FormattedMessage {...messages.saveCriteria} />
              </div>
            </Tooltip>
          </>
        )}
        <Tooltip
          title={<FormattedMessage {...messages.thisWillTakeYouBackToTheInitialCriteriaLabel} values={{ AppName }} />}
        >
          <div
            className={styles.resetButton}
            role="button"
            tabIndex={0}
            onKeyPress={handleResetButtonClick}
            onClick={handleResetButtonClick}
            sk-event-name={eventTypes.job.manualSearch.resetManualSearchCriteria}
          >
            <FormattedMessage {...messages.resetCriteria} />
          </div>
        </Tooltip>
      </div>
      <SearchSavedCriteriaModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        selectedCriteriaId={selectedCriteriaId ?? selectedAgentIdToViewCriteria}
        handleOk={handleOk}
      />
      <SaveSearchCriteriaModal
        isSaveSearchCriteriaModalVisible={isSaveSearchCriteriaModalVisible}
        handleSaveCriteria={handleSaveCriteria}
        setIsSaveSearchCriteriaModalVisible={setIsSaveSearchCriteriaModalVisible}
        setCriteriaName={setCriteriaName}
        isSaveSearchCriteriaFailureModalVisible={isSaveSearchCriteriaFailureModalVisible}
        setSaveSearchCriteriaFailureModalVisible={setSaveSearchCriteriaFailureModalVisible}
        criteriaName={criteriaName}
        handleUpdate={handleUpdate}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(ManualSearchFormHeaderOptions)));
export { ManualSearchFormHeaderOptions as ManualSearchFormHeaderOptionsWithoutStore };
