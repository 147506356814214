import React from 'react';
import { connect } from 'react-redux';
import CandidateJobsContainer from '../Containers/CandidateJobsContainer/CandidateJobsContainer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
});

function JobMatchingModalContainer(props) {
  const { openJobViewInNewTabCallBack, onClickAddJobCallBack, candidate, version, isATSViewActive, featureToggleList } =
    props;
  const [isCandidateViewHeaderVisible, setCandidateViewHeaderVisibility] = React.useState(false);
  const [isCandidateMatchingJobModalVisible, setCandidateMatchingJobModalVisiblity] = React.useState(true);
  const [candidateJobMatchingInitialAggregation, setCandidateJobMatchingInitialAggregation] = React.useState({});
  const [isFindMathingJobClicked, setIsFindMathingJobClicked] = React.useState(false);

  return (
    <CandidateJobsContainer
      candidate={candidate}
      setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
      setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
      version={version}
      candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
      isATSViewActive={isATSViewActive}
      setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
      setIsFindMathingJobClicked={setIsFindMathingJobClicked}
      isFindMathingJobClicked={isFindMathingJobClicked}
      featureToggleList={featureToggleList}
      showJobCountsInAts
    />
  );
}
const PackageJobMatchingModal = connect(mapStateToProps, null)(JobMatchingModalContainer);
export default PackageJobMatchingModal;
export { JobMatchingModalContainer as JobMatchingModalContainerWithoutStore };
