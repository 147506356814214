import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './EnhancedCandidateTransferModal.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const CandidateCopyButton = props => {
  const { isBoxSelected, actionName, handleActionChange } = props;
  return (
    <div
      className={classNames(styles.actionButtons, {
        [styles.highlightedActionButtons]: isBoxSelected(actionName, 'Copy'),
      })}
      key="Copy"
      onClick={() => handleActionChange('Copy')}
      onKeyPress={() => handleActionChange('Copy')}
      tabIndex={0}
      role="button"
    >
      <div className={styles.actionButtonText}>
        <FormattedMessage {...message.copyLabel} />
        <div className={styles.actionButtonInfoText}>
          ( <FormattedMessage {...message.duplicateToAnotherLabel} />{' '}
        </div>
        <div className={styles.actionButtonInfoText}>
          <FormattedMessage {...message.entityLabel} />)
        </div>
      </div>
    </div>
  );
};

export default CandidateCopyButton;
