import React, { useState } from 'react';
import _ from 'lodash';
import { Modal, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './ATSAuthentication.module.scss';
import message from '../../../ManualSearchV2/ManualSearchMessages';

function ATSDisconnectConfirmationModal(props) {
  const { isConfirmationModalVisible, setConfirmationModalVisible, atsConfig, disconnectATS, intl } = props;
  const vaultName = _.get(atsConfig, ['VaultName'], null);
  const handleCancel = () => {
    setConfirmationModalVisible(false);
  };
  const handleYes = () => {
    setConfirmationModalVisible(false);
    disconnectATS(vaultName, intl);
  };

  const footerButtons = (
    <div className={styles.actionButtons}>
      <Button shape="round" key="cancel" onClick={handleCancel}>
        <FormattedMessage {...message.cancelButton} />
      </Button>
      <Button shape="round" key="yes" onClick={handleYes} type="primary">
        <FormattedMessage {...message.yesButton} />
      </Button>
    </div>
  );

  return (
    <Modal
      title={<FormattedMessage {...message.confirmationMessageLabel} />}
      visible={isConfirmationModalVisible}
      className={styles.confirmationModal}
      footer={[footerButtons]}
      zIndex={2501}
      maskClosable={false}
      closable={false}
    >
      <div className={styles.modalContent}>
        <FormattedMessage {...message.areYouSureYouWantToDisconnectYourAccountLabel} />
      </div>
    </Modal>
  );
}

export default injectIntl(ATSDisconnectConfirmationModal);
