import _ from 'lodash';
import * as JobUsageBudgetRepository from '../Repository/JobUsageBudgetRepository';
import { setApiStatus } from './ApiStatusActions';
import { setNotification } from './ActionCreators/ConnectActions';
import {
  SET_AVAILABLE_JOB_USAGE_BUDGET,
  SET_JOB_USAGE_BUDGET_REQUESTS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
} from './ActionCreators/JobUsageBudgetActionCreator';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

function fetchJobUsageAvailableBudget(jobId) {
  return async dispatch => {
    try {
      const response = await JobUsageBudgetRepository.fetchJobUsageBudget(jobId);
      const payload = response.data;
      dispatch({
        type: SET_AVAILABLE_JOB_USAGE_BUDGET,
        payload,
      });
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse.status === 404) {
        const jobId = _.get(errorResponse, ['data', 'Error', 'Info', 'JobId'], null);
        const payload = {
          AvailableBudget: null,
          TotalBudget: null,
          JobId: jobId,
        };
        dispatch({
          type: SET_AVAILABLE_JOB_USAGE_BUDGET,
          payload,
        });
        return;
      }
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function FetchAllJobUsageBudgetRequests(data, pageSize, pageNumber) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'jobUsageBudgetRequestsApiStatus', status: INPROGRESS }));
      const response = await JobUsageBudgetRepository.FetchAllJobUsageBudgetRequests(data, pageSize, pageNumber);
      const payload = response.data;
      dispatch(setApiStatus({ apiName: 'jobUsageBudgetRequestsApiStatus', status: COMPLETED }));
      dispatch({
        type: SET_JOB_USAGE_BUDGET_REQUESTS,
        payload,
      });
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'jobUsageBudgetRequestsApiStatus', status: FAILED }));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function postJobUsageBudgetRequest(jobId, totalBudget) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'jobLevelPostCreditsRequestApiStatus', status: INPROGRESS }));
      await JobUsageBudgetRepository.postJobUsageBudgetRequest(jobId, totalBudget);
      dispatch({
        type: SET_UPDATED_JOB_USAGE_REQUEST_STATUS,
        payload: {
          JobId: jobId,
          RequestStatus: 'Pending',
        },
      });
      dispatch(setApiStatus({ apiName: 'jobLevelPostCreditsRequestApiStatus', status: COMPLETED }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'requestHasBeenSentToUpdateJobLimit',
        })
      );
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'jobLevelPostCreditsRequestApiStatus', status: FAILED }));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function updateJobUsageBudgetRequestStatus(
  credits,
  status,
  jobId,
  requestId,
  LastAllocatedBudget,
  CurrentAllocatedBudget
) {
  return async dispatch => {
    try {
      const payload = {
        BudgetUnits: credits,
        Status: status,
        JobId: jobId,
      };
      dispatch({
        type: SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
        payload: {
          RequestId: requestId,
          RequestStatus: INPROGRESS,
          CurrentAllocatedBudget: status === 'Rejected' ? LastAllocatedBudget : credits,
          LastAllocatedBudget,
        },
      });
      await JobUsageBudgetRepository.updateJobUsageBudgetRequestStatus(requestId, payload);
      dispatch({
        type: SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
        payload: {
          RequestId: requestId,
          RequestStatus: status,
          CurrentAllocatedBudget: status === 'Rejected' ? LastAllocatedBudget : credits,
          LastAllocatedBudget,
        },
      });
    } catch (e) {
      dispatch({
        type: SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
        payload: {
          RequestId: requestId,
          RequestStatus: 'Pending',
          CurrentAllocatedBudget,
          LastAllocatedBudget,
        },
      });
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

export {
  fetchJobUsageAvailableBudget,
  FetchAllJobUsageBudgetRequests,
  postJobUsageBudgetRequest,
  updateJobUsageBudgetRequestStatus,
};
