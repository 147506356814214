import { defineMessages } from 'react-intl';

const messages = defineMessages({
  callConfiguration: {
    id: 'AdminSettings.Navigator.CallConfiguration',
    defaultMessage: 'Call Configuration',
  },
  contactInfoProvider: {
    id: 'AdminSettings.Navigator.ContactInfoProvider',
    defaultMessage: 'Candidate Info Provider',
  },
  emailConfiguration: {
    id: 'AdminSettings.Navigator.EmailConfiguration',
    defaultMessage: 'SMTP Email Configuration',
  },
  sharing: {
    id: 'AdminSettings.Navigator.Sharing',
    defaultMessage: 'Sharing',
  },
  autoLogout: {
    id: 'AdminSettings.Navigator.AutoLogout',
    defaultMessage: 'Auto Logout',
  },
  consentManager: {
    id: 'AdminSettings.Navigator.ConsentManager',
    defaultMessage: 'Consent Manager',
  },
  multilocation: {
    id: 'AdminSettings.Navigator.Multilocation',
    defaultMessage: 'Multilocation',
  },
  diversityAndInclusion: {
    id: 'AdminSettings.Navigator.DiversityAndInclusion',
    defaultMessage: 'Diversity & Inclusion',
  },
  defaultConsentManager: {
    id: 'AdminSettings.Navigator.DefaultConsentManager',
    defaultMessage: 'Default Consent Manager',
  },
  showContactInfo: {
    id: 'AdminSettings.Navigator.ShowContactInfo',
    defaultMessage: 'Opt-in Required to Show Contact Info',
  },
  diversityOptions: {
    id: 'AdminSettings.Navigator.DiversityOptions',
    defaultMessage: 'Diversity Options',
  },
  creditInfo: {
    id: 'AdminSettings.Navigator.CreditInfo',
    defaultMessage: 'Credit Info',
  },
  atsIntegration: {
    id: 'AdminSettings.Navigator.atsIntegration',
    defaultMessage: 'ATS Integration',
  },
  creditInformation: {
    id: 'AdminSettings.Navigator.CreditInformation',
    defaultMessage: 'Job Level Credit Information',
  },
  adminCanEnable: {
    id: 'AdminSettings.Navigator.AdminCanEnable',
    defaultMessage:
      'The admin can enable masking certain key identifiers to reduce recruiter bias for all or selective users by clicking on Add User.',
  },
  addUser: {
    id: 'AdminSettings.Navigator.AddUser',
    defaultMessage: 'Add User',
  },
  addAllOrAnyUserTitle: {
    id: 'AdminSettings.Navigator.AddAllOrAnyUserTitle',
    defaultMessage: 'Add all or any user and select masking parameters for them',
  },
  loading: {
    id: 'AdminSettings.Navigator.Loading',
    defaultMessage: 'Loading...',
  },
  forAllUsers: {
    id: 'AdminSettings.Navigator.ForAllUsers',
    defaultMessage: 'For All Users',
  },
  universityName: {
    id: 'AdminSettings.Navigator.UniversityName',
    defaultMessage: 'University Name',
  },
  name: {
    id: 'AdminSettings.Navigator.Name',
    defaultMessage: 'Name',
  },
  locationRequiredCallback: {
    id: 'AdminSettings.Navigator.LocationRequired',
    defaultMessage: 'Location is required',
  },
  atMax5LocationsCallback: {
    id: 'AdminSettings.Navigator.AtMax5LocationsCallback',
    defaultMessage: 'At max only 5 locations can be selected for a region',
  },
  regionIsRequired: {
    id: 'AdminSettings.Navigator.RegionIsRequired',
    defaultMessage: 'Region is required',
  },
  setupYourSourcingCriteria: {
    id: 'AdminSettings.Navigator.SetupYourSourcingCriteria',
    defaultMessage: 'Setup your sourcing criteria.',
  },
  region: {
    id: 'AdminSettings.Navigator.Region',
    defaultMessage: 'Region *',
  },
  country: {
    id: 'AdminSettings.Navigator.Country',
    defaultMessage: 'Country *',
  },
  location: {
    id: 'AdminSettings.Navigator.Location',
    defaultMessage: 'Location *',
  },
  dropDownText: {
    id: 'AdminSettings.Navigator.DropDownText',
    defaultMessage: 'loading auto suggestions...',
  },
  addRegion: {
    id: 'AdminSettings.Navigator.AddRegion',
    defaultMessage: 'Add Region',
  },
  delete: {
    id: 'AdminSettings.Navigator.Delete',
    defaultMessage: 'Delete',
  },
  scoutingAgent: {
    id: 'AdminSettings.Navigator.ScoutingAgent',
    defaultMessage: 'Scouting Agent',
  },
  on: {
    id: 'AdminSettings.Navigator.On',
    defaultMessage: 'On',
  },
  off: {
    id: 'AdminSettings.Navigator.Off',
    defaultMessage: 'Off',
  },
});

export default messages;
