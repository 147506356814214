import React, { Component } from 'react';
import ListDetailContainer from '../Containers/Lists/ListDetailContainer/ListDetailContainer';

class SegmentDetailsContainerPackage extends Component {
  constructor(props) {
    super(props);
    const { status, connectionStatus, publishedStatus } = props;
    let newState = { status, connectionStatus, publishedStatus };

    if (connectionStatus === 'Connected') {
      newState = {
        status: null,
        connectionStatus: 'Connected',
        publishedStatus: 'Sourced',
      };
    }

    this.state = newState;
  }

  componentDidMount() {
    const { fetchCountries } = this.props;
    fetchCountries();
    this.setState({
      status: 'Sourced',
      connectionStatus: null,
      publishedStatus: 'Sourced',
    });
  }

  changeStatus = activeKey => {
    let status = '';
    let publishedStatus = '';
    const connectionStatus = null;
    if (activeKey === 'sourced') {
      status = 'Sourced';
      publishedStatus = 'Sourced';
    } else if (activeKey === 'shortlisted' || activeKey === 'subsegment') {
      status = 'Shortlisted';
      publishedStatus = 'Shortlisted';
    } else if (activeKey === 'rejected') {
      status = 'Rejected';
      publishedStatus = 'Rejected';
    } else if (activeKey === 'notes') {
      status = 'notes';
    }

    this.setState({ status, connectionStatus, publishedStatus });
  };


  setStatus = (value) => {
    this.setState({ status: value });
  }

  setPublishedStatus = (value) => {
    this.setState({ publishedStatus: value });
  }

  setConnectionStatus = (value) => {
    this.setState({ connectionStatus: value });
  }

  render() {
    const {
      segmentId,
      onSegmentCreationCallback,
      location,
      setUrl,
      openAtsView,
      openAryaCandidateView,
      openWorkflowinNewTab,
      openSipCallWindowsApp,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      openListOfList,
      notesContainer,
      callNotesContainer,
      postAtsCallNotes,
      atsFetchBulkNotes,
    } = this.props;
    const { status, connectionStatus, publishedStatus } = this.state;

    return (
      <ListDetailContainer
        segmentId={segmentId}
        onSegmentCreationCallback={onSegmentCreationCallback}
        location={location}
        status={status}
        connectionStatus={connectionStatus}
        publishedStatus={publishedStatus}
        changeTabStatus={this.changeStatus}
        setUrl={setUrl}
        openAtsView={openAtsView}
        openAryaCandidateView={openAryaCandidateView}
        version="ats"
        openWorkflowinNewTab={openWorkflowinNewTab}
        openSipCallWindowsApp={openSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        openListOfList={openListOfList}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postAtsCallNotes}
        atsFetchBulkNotes={atsFetchBulkNotes}
        setStatus={this.setStatus}
        setPublishedStatus={this.setPublishedStatus}
        setConnectionStatus={this.setConnectionStatus}
      />
    );
  }
}

const PackageSegmentsDetailContainerConfig = SegmentDetailsContainerPackage;
export default PackageSegmentsDetailContainerConfig;
