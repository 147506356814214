import React from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import SourcesContext from './SourceContext';
import { getIsSourceAuthorized, getSourceDisplayName, getSourceImage, getSourceName } from '../../Utils/SourceUtils';
import { getIsCountryWideSearchEnabled } from '../../Utils/LocationUtils';
import {
  clearAdvancedOptionFields,
  getPortalQueries,
  resetAdvancedOptionFields,
} from '../../Utils/PortalAdvanceOptionsUtils';
import styles from './SourceItemWrapper.module.scss';
import { getIsAdvancedSettingsEnabled } from '../../Components/QuickSearchForm/QuickSearchFormUtils';

const getAdvancedOptionsTitle = ({
  source,
  userConfig,
  whiteLabelInfo,
  revealActiveChannelSourceName,
  setAdvanceSettingsPopover,
  form,
}) => {
  const showVaultName = userConfig.ShowVaultName;
  const sourceDisplayName = getSourceDisplayName(
    source,
    userConfig,
    showVaultName,
    {},
    whiteLabelInfo,
    revealActiveChannelSourceName
  );
  const sourceImg = getSourceImage(
    source,
    { fontSize: 20, height: 20, verticalAlign: 'middle' },
    styles.advancedOptionsTitleicon,
    showVaultName
  );
  const searchType = source.SearchType;
  return (
    <div className={styles.advanceOptionsHeader}>
      <div>
        {sourceImg}{' '}
        <span className={styles.advanceOptionsTitle}>
          {sourceDisplayName} {searchType ? `(${searchType})` : ''}
        </span>
      </div>
      <Icon
        type="close"
        onClick={() => {
          resetAdvancedOptionFields({ source, form });
          setAdvanceSettingsPopover();
        }}
      />
    </div>
  );
};

const SourceItemWrapper = props => {
  const {
    children,
    form,
    defaultValues,
    source,
    portalsConfig,
    sourceNamesSelected,
    defaultAdvancedSettings,
    countries,
    showVaultName,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    userConfig,
    setAdvanceSettingsPopover,
    onApplyAdvancedSettings,
    onSourceSelect,
    advanceSettingsVisiblePopover,
    handleAdvanceSettingsPopoverVisibility,
    currentCompanies,
    monsterClassicCompanyCatagories,
    isAlternateLocationEnabled,
    isStateWideSearchEnabled,
    isSocialAdvanceSettingsEnabled,
  } = props;
  const jobCountryCode = defaultValues?.CountryCode;
  const sourcesConfig = userConfig?.Sources;
  const isSourceAuthorized = getIsSourceAuthorized({
    source,
    sourcesConfig,
    portalsConfig,
    jobCountryCode,
  });
  const sourceName = getSourceName(source);
  const isAdvanceSettingsEnabled = getIsAdvancedSettingsEnabled(source, isSocialAdvanceSettingsEnabled);
  const searchType = source.SearchType;
  const isAdvanceSettingsButtonDisabled =
    !isSourceAuthorized || !isAdvanceSettingsEnabled || !sourceNamesSelected.includes(sourceName);
  const isFilterApplied = !_.isEmpty(_.pickBy(_.get(defaultAdvancedSettings, sourceName, {}), value => value));
  const sourceDisplayName = getSourceDisplayName(
    source,
    userConfig,
    showVaultName,
    { Name: 'quickSearchForm' },
    whiteLabelInfo,
    revealActiveChannelSourceName
  );
  const isCountryWideSearchEnabled = getIsCountryWideSearchEnabled(countries, defaultValues.CountryCode);
  const advancedOptionTitle = getAdvancedOptionsTitle({
    source,
    userConfig,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    setAdvanceSettingsPopover,
    form,
  });
  const applyAdvancedOptions = () => {
    onApplyAdvancedSettings(source, getPortalQueries(source, form.getFieldsValue()));
    setAdvanceSettingsPopover();
  };
  const clearAdvancedOptions = () => {
    onApplyAdvancedSettings(source, {});
    clearAdvancedOptionFields({ source, form });
  };

  return (
    <SourcesContext.Provider
      value={{
        advancedOptionTitle,
        advanceSettingsVisiblePopover,
        applyAdvancedOptions,
        clearAdvancedOptions,
        currentCompanies,
        defaultAdvancedSettings,
        defaultValues,
        form,
        handleAdvanceSettingsPopoverVisibility,
        isAdvanceSettingsButtonDisabled,
        isAlternateLocationEnabled,
        isCountryWideSearchEnabled,
        isFilterApplied,
        isSourceAuthorized,
        isStateWideSearchEnabled,
        monsterClassicCompanyCatagories,
        onSourceSelect,
        searchType,
        showVaultName,
        source,
        sourceDisplayName,
        sourceName,
        sourceNamesSelected,
      }}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { isAdvanceSettingsButtonDisabled, isFilterApplied, sourceDisplayName })
      )}
    </SourcesContext.Provider>
  );
};

export default SourceItemWrapper;
