import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { AryaBotIcon } from '../Icons/AryaIcons';
import BotConfigDrawerContainer from '../Containers/BotConfigDrawerContainer/BotConfigDrawerContainer';
import { fetchJobDetails } from '../Repository/JobRepository';
import { fetchConversationReference } from '../Repository/ConnectRepository';
import { getWhiteLabelInformation } from '../Reducers/ConfigReducer';
import { getConversationReference } from '../Actions/ConnectActions';

class BotConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisibility: false,
      showBotQuestionsReviewMessage: false,
    };
  }

  componentDidMount() {
    const { jobId } = this.props;
    fetchJobDetails(jobId).then(response => {
      this.setState(response.data);
      fetchConversationReference(response.data.JobGuid).then(res => {
        this.setState({ IsBotEnabled: res.data.IsBotEnabled });
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { botConfigDrawerVisibleTime, jobId, getConversationReference } = this.props;
    const { botConfigDrawerVisibleTime: prevBotConfigDrawerVisibleTime } = prevProps;
    if (!!botConfigDrawerVisibleTime && botConfigDrawerVisibleTime !== prevBotConfigDrawerVisibleTime) {
      fetchJobDetails(jobId).then(async response => {
        await getConversationReference(jobId, response.data.JobGuid);
        this.toggleBotConfigDrawer();
      });
      this.setState({ showBotQuestionsReviewMessage: true });
    }
  }

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { jobId } = this.props;
    const { drawerVisibility, botDrawerRenderFlag } = this.state;
    fetchJobDetails(jobId).then(response => {
      this.setState(response.data);
      fetchConversationReference(response.data.JobGuid).then(res => {
        this.setState({ IsBotEnabled: res.data.IsBotEnabled });
      });
    });

    if (drawerVisibility) this.setState({ drawerVisibility: !drawerVisibility, showBotQuestionsReviewMessage: false });
    else
      this.setState({
        drawerVisibility: !drawerVisibility,
        botDrawerRenderFlag: !botDrawerRenderFlag,
      });
  };

  render() {
    const { jobId, whiteLabelInfo } = this.props;
    const { IsBotEnabled, drawerVisibility, botDrawerRenderFlag, showBotQuestionsReviewMessage } = this.state;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
    const intJobId = parseInt(jobId, 10);
    return (
      <>
        <BotConfigDrawerContainer
          jobTitle=""
          jobId={intJobId}
          visible={drawerVisibility}
          onClose={this.toggleBotConfigDrawer}
          toggleBotDrawerRenderFlag={this.toggleBotDrawerRenderFlag}
          botDrawerRenderFlag={botDrawerRenderFlag}
          AppName={AppName}
          showBotQuestionsReviewMessage={showBotQuestionsReviewMessage}
        />
        <div
          role="presentation"
          onClick={this.toggleBotConfigDrawer}
          className={`bot-config ${IsBotEnabled ? 'bot-active' : ''}`}
          style={{ marginRight: '8px' }}
        >
          <div className={`${IsBotEnabled && 'green-circle'}`}></div>
          <AryaBotIcon className="bot-icon" />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  getConversationReference,
};

const PackageBotConfig = connect(mapStateToProps, mapDispatchToProps)(BotConfig);
export default PackageBotConfig;

export { BotConfig as BotConfigWithoutStore };
