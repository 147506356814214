import * as ATSAuthenticationRepository from '../Repository/ATSAuthenticationRepository';
import { setApiStatus } from './ApiStatusActions';
import { setAlertNotification } from './ActionCreators/AppNotificationActions';
import { fetchConfig } from './ConfigActions';
import messages from '../Containers/ManualSearchV2/ManualSearchMessages';

function disconnectATS(VaultName, intl) {
  return async dispatch => {
    dispatch(setApiStatus({ apiName: 'disconnectLeverAts', status: 'INPROGRESS' }));
    try {
      await ATSAuthenticationRepository.disconnectATS(VaultName);
      dispatch(setApiStatus({ apiName: 'disconnectLeverAts', status: 'COMPLETED' }));
      dispatch(
        setAlertNotification({
          alertNotification: {
            Type: 'ALERT_SUCCESS',
            Message: intl.formatMessage({ ...messages.integrationDisconnectedLabel }),
            Description: intl.formatMessage({ ...messages.atsHasBeenDisconnected }),
          },
        })
      );
      dispatch(fetchConfig());
    } catch (error) {
      dispatch(setApiStatus({ apiName: 'disconnectLeverAts', status: 'FAILED' }));
    }
  };
}

export { disconnectATS };
