/* eslint-disable class-methods-use-this */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Pagination, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseTemplateContainer from '../BaseTemplatesContainer';
import CreateDripTemplate from '../../../Components/Connect/BaseTemplateForm/CreateDripTemplate/CreateDripTemplate';
import UserDripTemplateRow from '../../../Components/Connect/TemplateRow/UserDripTemplateRow/UserDripTemplateRow';
import SharedDripTemplateRow from '../../../Components/Connect/TemplateRow/SharedDripTemplateRow/SharedDripTemplateRow';
import TemplateHeader from '../../../Components/Connect/TemplateHeader/TemplateHeader';
import * as DripTemplatesActions from '../../../Actions/DripTemplates';
import * as EmailTemplatesActions from '../../../Actions/EmailTemplates';
import * as UserActions from '../../../Actions/UserActions';
import {
  getCurrentDripTemplateIds,
  getDripTemplatesById,
  getDripTemplatesCount,
} from '../../../Reducers/DripTemplates';
import * as MergeTagsReducer from '../../../Reducers/MergeTagsReducer';
import getMergeTags from '../../../Actions/MergeTagsActions';
import { getCurrentEmailTemplateIds, getEmailTemplatesById } from '../../../Reducers/EmailTemplates';
import messages from './messages';
import './DripTemplates.scss';
import {
  getConnectUsersById,
  getUsersByGuId,
  getUserListForShareTemplate,
  getConnectMaxCount,
} from '../../../Reducers/UserReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags } from '../../../Utils/MergeTagsUtils';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { connectTemplateNameByType } from '../../../Utils/ConnectUtils';
import { isPulseUser } from '../../../Utils/ConfigUtils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  currentDripTemplateIds: getCurrentDripTemplateIds(state),
  dripTemplatesById: getDripTemplatesById(state),
  dripTemplatesCount: getDripTemplatesCount(state),
  currentEmailTemplateIds: getCurrentEmailTemplateIds(state),
  emailTemplatesById: getEmailTemplatesById(state),
  mergeTags: MergeTagsReducer.getMergeTags(state),
  connectUsersById: getConnectUsersById(state),
  usersByGuId: getUsersByGuId(state),
  userListForShareTemplate: getUserListForShareTemplate(state),
  maxCount: getConnectMaxCount(state),
  CurrentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
  fetchDripTemplateApiStatus: getApiStatus(state, 'fetchDripTemplateApiStatus'),
});

const mapDispatchToProps = {
  searchDripTemplates: DripTemplatesActions.searchDripTemplates,
  postDripTemplate: DripTemplatesActions.postDripTemplate,
  editDripTemplate: DripTemplatesActions.editDripTemplate,
  deleteDripTemplate: DripTemplatesActions.deleteDripTemplate,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  duplicateDripTemplate: DripTemplatesActions.duplicateDripTemplate,
  clearDripTemplates: DripTemplatesActions.clearDripTemplates,
  searchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  fetchMergeTags: getMergeTags,
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
  shareDripTemplate: DripTemplatesActions.shareDripTemplate,
};

class DripTemplatesContainer extends BaseTemplateContainer {
  constructor(props) {
    super(props);
    this.state = {
      createDrawerVisibility: false,
      editDrawerVisibility: false,
      page: 1,
      pageSize: 10,
      searchQuery: '',
      shared: false,
    };

    this.showEditDrawer = this.showEditDrawer.bind(this);
    this.searchTemplates = this.searchTemplates.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  componentWillUnmount() {
    const { clearDripTemplates, dripTemplatesCount } = this.props;
    if (dripTemplatesCount) clearDripTemplates();
  }

  componentDidMount() {
    const { searchEmailTemplates } = this.props;
    const isPulse = isPulseUser();
    this.searchTemplates();
    searchEmailTemplates({ from: 0, size: 100, IncludeSystemTemplate: !isPulse });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  showEditDrawer(templateId) {
    const { getDripTemplate } = this.props;
    getDripTemplate(templateId);
    this.setEditTemplateId(templateId);
    this.setState({
      editDrawerVisibility: true,
    });
  }

  searchTemplates() {
    const { page, pageSize, searchQuery, shared } = this.state;
    const { searchDripTemplates } = this.props;
    searchDripTemplates({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    });
  }

  deleteTemplate(templateId) {
    const { deleteDripTemplate, currentDripTemplateIds } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const shouldGoBack = currentDripTemplateIds.length === 1 && page !== 1;
    const updatedPage = shouldGoBack ? page - 1 : page;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    deleteDripTemplate(templateId, filter);
    this.setState({ page: updatedPage });
  }

  duplicateTemplate(templateId) {
    const { duplicateDripTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    duplicateDripTemplate(templateId, filter);
  }

  createTemplate(template) {
    const { postDripTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    postDripTemplate(template, filter);
    this.closeCreateDrawer();
  }

  editTemplate(template) {
    const { editDripTemplate } = this.props;
    const { page, pageSize, searchQuery, shared, editTemplateId } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    editDripTemplate(editTemplateId, template, filter);
    this.closeEditDrawer();
  }

  render() {
    const {
      intl,
      currentDripTemplateIds,
      dripTemplatesById,
      dripTemplatesCount,
      currentEmailTemplateIds,
      emailTemplatesById,
      mergeTags,
      connectUsersById,
      maxCount,
      CurrentUser,
      usersByGuId,
      userListForShareTemplate,
      shareDripTemplate,
      fetchUsersForShare,
      featureToggleList,
      fetchDripTemplateApiStatus,
      version,
      jobDetails,
    } = this.props;
    const { createDrawerVisibility, editDrawerVisibility, editTemplateId, page, pageSize, shared } = this.state;
    const { CustomizeJobUrl, JobSummary, CustomizeJobNotInterestedUrl } = featureToggleList;
    const filteredMergeTags = getFilteredMergeTags(
      mergeTags,
      { CustomizeJobUrl, JobSummary, CustomizeJobNotInterestedUrl },
      version,
      jobDetails
    );

    return (
      <div className="drip-templates">
        <TemplateHeader
          onClick={this.showCreateDrawer}
          // onSearch={this.onSearch}
          onChange={event => this.onSearch(event.target.value)}
          heading={<FormattedMessage {...messages.emailDrips} />}
          placeholder={intl.formatMessage({ ...messages.searchDrip })}
          type={connectTemplateNameByType.drip}
        />

        {createDrawerVisibility ? (
          <CreateDripTemplate
            currentEmailTemplateIds={currentEmailTemplateIds}
            emailTemplatesById={emailTemplatesById}
            visible={createDrawerVisibility}
            onClose={this.closeCreateDrawer}
            onSubmit={this.createTemplate}
            initialValues={{}}
            mergeTags={filteredMergeTags}
            version={version}
            jobDetails={jobDetails}
          />
        ) : null}
        {editDrawerVisibility ? (
          <CreateDripTemplate
            currentEmailTemplateIds={currentEmailTemplateIds}
            emailTemplatesById={emailTemplatesById}
            visible={editDrawerVisibility}
            onClose={this.closeEditDrawer}
            onSubmit={this.editTemplate}
            initialValues={dripTemplatesById[editTemplateId]}
            mergeTags={filteredMergeTags}
            viewOnly={shared}
            fetchDripTemplateApiStatus={fetchDripTemplateApiStatus}
            type="edit"
            version={version}
            jobDetails={jobDetails}
          />
        ) : null}

        <Tabs defaultActiveKey="my-templates" onChange={this.onTabChange}>
          <TabPane tab={<FormattedMessage {...messages.myDrips} />} key="my-templates">
            <Row className="drip template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.dripSequence} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.shared} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              {/* <Col span={3}>
            <FormattedMessage {...messages.includesBot} />
          </Col> */}
              {/* <Col span={3}>
            <FormattedMessage {...messages.shared} />
          </Col> */}
            </Row>
            {currentDripTemplateIds.map(templateId => {
              const dripTemplate = dripTemplatesById[templateId];
              return (
                <UserDripTemplateRow
                  key={dripTemplate.Id}
                  title={dripTemplate.Name}
                  updatedTime={dripTemplate.UpdatedTime}
                  templates={['My introduction email']}
                  isPublic={dripTemplate.IsPublic}
                  includesBot
                  sharedCount={3}
                  deleteTemplate={this.deleteTemplate}
                  duplicateTemplate={this.duplicateTemplate}
                  editTemplate={this.showEditDrawer}
                  templateId={templateId}
                  shareDripTemplate={shareDripTemplate}
                  usersByGuId={usersByGuId}
                  userListForShareTemplate={userListForShareTemplate}
                  sharedUsers={_.uniq(dripTemplatesById[templateId].SharedWith || [])}
                  maxCount={maxCount}
                  CurrentUser={CurrentUser}
                  fetchUsersForShare={fetchUsersForShare}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={dripTemplatesCount}
              onChange={this.onPageChange}
              onShowSizeChange={this.onShowSizeChange}
              hideOnSinglePage={dripTemplatesCount <= 10}
              current={page}
              pageSize={pageSize}
              className="connect-templates-pagination"
            />
          </TabPane>
          <TabPane tab={<FormattedMessage {...messages.sharedDrips} />} key="shared-templates">
            <Row className="drip template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.dripSequence} />
              </Col>
              <Col span={5}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              {/* <Col span={4}>
                <FormattedMessage {...messages.includesBot} />
              </Col> */}
              <Col span={5}>
                <FormattedMessage {...messages.createdBy} />
              </Col>
            </Row>
            {currentDripTemplateIds.map(templateId => {
              const dripTemplate = dripTemplatesById[templateId];
              const firstName = _.get(connectUsersById, [dripTemplate.CreatedBy, 'FirstName'], '');
              const lastName = _.get(connectUsersById, [dripTemplate.CreatedBy, 'LastName'], '');
              return (
                <SharedDripTemplateRow
                  key={dripTemplate.Id}
                  id={dripTemplate.Id}
                  title={dripTemplate.Name}
                  updatedTime={dripTemplate.UpdatedTime}
                  includesBot
                  createdBy={`${firstName} ${lastName}`}
                  cloneTemplate={this.duplicateTemplate}
                  viewTemplate={this.showEditDrawer}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={dripTemplatesCount}
              onChange={this.onPageChange}
              onShowSizeChange={this.onShowSizeChange}
              hideOnSinglePage={dripTemplatesCount <= 10}
              className="connect-templates-pagination"
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

DripTemplatesContainer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  currentDripTemplateIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  dripTemplatesById: PropTypes.shape({}).isRequired,
};

DripTemplatesContainer.defaultProps = {
  dripTemplatesCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DripTemplatesContainer));
export { DripTemplatesContainer as DripTemplatesContainerWithoutStore };
