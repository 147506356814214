import React from 'react';
import { connect } from 'react-redux';
import { BackTop } from 'antd';
import BackTopIcon from '../Icons/BackTopIcon';
import ApplicationSettings from '../Components/ApplicationSettings/ApplicationSettings';
import * as ConnectSettingsActions from '../Actions/ConnectSettingsActions';
import * as ConfigActions from '../Actions/ConfigActions';
import { getConfig, getWhiteLabelInformation, getFetchUserConfigApiStatus } from '../Reducers/ConfigReducer';
import { getConnectSettings } from '../Reducers/ConnectSettingsReducer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';

const mapStateToProps = state => ({
  userConfig: getConfig(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  connectConfig: getConnectSettings(state),
  featureToggleList: getFeatureToggleList(state),
  fetchUserConfigApiStatus: getFetchUserConfigApiStatus(state),
});

const mapDispatchToProps = {
  fetchConfig: ConfigActions.fetchConfig,
  fetchConnectConfig: ConfigActions.fetchConnectConfig,
  fetchAllPortalCountries: ConfigActions.fetchAllPortalCountries,
  fetchBotDetails: ConnectSettingsActions.fetchBotDetails,
  updateBotDetails: ConnectSettingsActions.updateBotDetails,
  integrateSource: ConfigActions.integrateSource,
  integratePortal: ConfigActions.integratePortal,
  fetchAllPortalCredentials: ConfigActions.fetchAllPortalCredentials,
  patchUpdateConfig: ConfigActions.patchUpdateConfig,
};

function ApplicationSettingsContainer(props) {
  const {
    userConfig,
    fetchAllPortalCountries,
    connectConfig,
    fetchBotDetails,
    updateBotDetails,
    integrateSource,
    integratePortal,
    fetchAllPortalCredentials,
    patchUpdateConfig,
    featureToggleList,
  } = props;
  React.useEffect(() => {
    fetchAllPortalCountries();
    fetchAllPortalCredentials();
  }, []);
  return (
    <>
      <BackTop style={{ right: '40px', bottom: '20px' }} visibilityHeight={100}>
        <BackTopIcon style={{ transform: 'scale(1.5)' }} />
      </BackTop>
      <ApplicationSettings
        fetchBotDetails={fetchBotDetails}
        updateBotDetails={updateBotDetails}
        userConfig={userConfig}
        integrateSource={integrateSource}
        authenticate={integratePortal}
        fetchAllPortalCredentials={fetchAllPortalCredentials}
        patchUpdateConfig={patchUpdateConfig}
        connectConfig={connectConfig}
        roleType={userConfig?.Role}
        aryaVersion={userConfig.SubscriptionType}
        featureToggleList={featureToggleList}
      />
    </>
  );
}
const PackageApplicationSettings = connect(mapStateToProps, mapDispatchToProps)(ApplicationSettingsContainer);
export default PackageApplicationSettings;
export { ApplicationSettingsContainer as ApplicationSettingsContainerWithoutStore };
