import React, { Component } from 'react';
import { Button, Popover, Tooltip, Badge, Icon } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import {
  getSourceName,
  getCandidateSourceName,
  getSourceImage,
  getSourceDisplayName,
  getIsSourceAuthorized,
} from '../../../Utils/SourceUtils';
import { getPortalsConfig, getConfig } from '../../../Reducers/ConfigReducer';
import message from '../CandidateFiltersMessage';

const mapStateToProps = state => ({
  portalsConfig: getPortalsConfig(state),
  userConfig: getConfig(state),
});

class CandidateSourceFilter extends Component {
  applyCandidateSourceFilter = portals => {
    const { applyCandidateFilter } = this.props;
    applyCandidateFilter({
      modifiedFilters: { Portals: portals },
      isFilterModifed: true,
      invokationContext: 'sourceFilter',
    });
  };

  getSourceIndex = source => {
    const { selectedSources } = this.props;
    return selectedSources.findIndex(
      selectedSource => getSourceName(selectedSource).toLowerCase() === getSourceName(source).toLowerCase()
    );
  };

  onPortalChange = source => {
    const { Portal, Group, Name, Type } = source;
    const { selectedSources } = this.props;
    const index = this.getSourceIndex(source);
    const newSelectedSources = [...selectedSources];
    if (index === -1) {
      newSelectedSources.push({ Portal, Group, Name, Type });
    } else {
      newSelectedSources.splice(index, 1);
    }
    this.applyCandidateSourceFilter(newSelectedSources);
  };

  getQuickFilters = () => {
    const {
      candidateCountBySource,
      whiteLabelInfo,
      sourcesConfig,
      portalSources,
      showVaultName,
      jobCountryCode,
      selectedSources,
      portalsConfig,
      userConfig,
      featureToggleList,
    } = this.props;
    const candidateSources =
      Object.keys(candidateCountBySource || {}).map(source => {
        if (source.toLowerCase() === 'active' || source.toLowerCase() === 'passive')
          return { Group: source, Type: 'Primary' };
        return { Portal: source, Type: 'Primary' };
      }) || [];

    const {
      MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
    } = featureToggleList;

    const totalSources = _.uniqBy(portalSources.concat(candidateSources), source => {
      return (_.get(source, 'Portal') || _.get(source, 'Group', '')).toLowerCase();
    });
    if (!totalSources) {
      return null;
    }
    const quickFilters = totalSources.map(source => {
      const sourceName = getCandidateSourceName(source).toLowerCase();
      const sourceDisplayName = getSourceDisplayName(
        source,
        userConfig,
        showVaultName,
        {},
        whiteLabelInfo,
        null,
        isMaskActiveAsInternalEnabled
      );
      const newSource = _.cloneDeep(source);
      if (source.Portal === 'Internal' && !showVaultName) {
        newSource.Name = undefined;
      }
      const isSourceAuthorized = getIsSourceAuthorized({
        source: newSource,
        sourcesConfig,
        portalsConfig,
        jobCountryCode,
      });
      const disconnected = !isSourceAuthorized ? <FormattedMessage {...message.disconnectedLabel} /> : '';
      return (
        !(sourceDisplayName === 'Internal' && isMaskActiveAsInternalEnabled) && (
          <Tooltip
            title={
              <FormattedMessage
                {...message.sourceDisplayNameDisconnected}
                values={{
                  sourceDisplayName:
                    sourceDisplayName === 'Active' && isMaskActiveAsInternalEnabled ? 'Internal' : sourceDisplayName,
                  disconnected,
                }}
              />
            }
            key={sourceName}
          >
            <Badge
              showZero
              count={candidateCountBySource?.[sourceName] || 0}
              className={
                (!isSourceAuthorized && 'source-count-label unselected') ||
                (selectedSources.length !== 0 && this.getSourceIndex(newSource) >= 0
                  ? 'source-count-label selected'
                  : 'source-count-label unselected')
              }
              overflowCount={1000}
            >
              <Button
                className={`candidate-list-filter ${!isSourceAuthorized ? 'disconnected' : ''} ${
                  selectedSources.length !== 0 && this.getSourceIndex(newSource) >= 0 ? 'selected' : 'unselected'
                }`}
                key={sourceName}
                onClick={() => {
                  this.onPortalChange(newSource);
                }}
              >
                {getSourceImage(
                  source?.Portal === 'Internal' || (isMaskActiveAsInternalEnabled && source?.Group === 'Active')
                    ? { Portal: 'InternalForDownloadedCandidates' }
                    : source,
                  { fontSize: 32, verticalAlign: 'middle' },
                  showVaultName
                )}
              </Button>
            </Badge>
          </Tooltip>
        )
      );
    });
    return <div className="candidate-sources-wrapper">{quickFilters}</div>;
  };

  render() {
    const { featureToggleList, selectedSources } = this.props;
    let clearSourceFilterButton;
    let sourceFilterButton;
    let filterSelectedClassName;
    if (selectedSources.length === 0) {
      sourceFilterButton = (
        <Button className="candidate-source-button">
          {' '}
          <FormattedMessage {...message.sourceFilterLabel} />
        </Button>
      );
    } else {
      sourceFilterButton = (
        <Button className="candidate-source-button-filters">
          <span className="source-filters-text">
            {selectedSources.length > 1 ? (
              <FormattedMessage
                {...message.sourcesSelectedLabel}
                values={{ selectedSources: selectedSources.length }}
              />
            ) : (
              <FormattedMessage {...message.sourceSelectedLabel} values={{ selectedSources: selectedSources.length }} />
            )}
          </span>
        </Button>
      );
      clearSourceFilterButton = (
        <span className="clear-source" role="presentation" onClick={() => this.applyCandidateSourceFilter([])}>
          <Icon type="close" />
        </span>
      );
      filterSelectedClassName = 'selected';
    }
    return (
      <>
        {featureToggleList.SourceFilter.IsEnabled ? (
          <div className={`candidate-source-filter ${filterSelectedClassName}`}>
            <Popover content={this.getQuickFilters()} placement="topLeft" trigger="click">
              {sourceFilterButton}
            </Popover>
            {clearSourceFilterButton}
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(mapStateToProps, null)(CandidateSourceFilter);
export { CandidateSourceFilter as CandidateSourceFilterWithoutStore };
