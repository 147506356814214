import { message } from 'antd';
import _ from 'lodash';
import { fetchAtsConfig } from './AtsActions';
import * as configRepository from '../Repository/ConfigRepository';
import {
  setDistributionPortals,
  setAllJobDistributionCredentials,
  setJobPortalAuthenticationStatus,
  setJobPortalCredentials,
  removeJobPortalCredentials,
  setFetchNotificationConfigApiStatus,
  setUpdateNotificationTypeConfigApiStatus,
  setUpdateNotificationIdConfigApiStatus,
  setNotificationConfig,
  setNotificationTypeConfig,
  setNotificationIdConfig,
  setActivateAllNotificationTypeApiStatus,
  setAllActiveByNotificationType,
  setFeaturesAdditionalInfo,
  setMaskingConfig,
  setOrgMaskingConfig,
  setOrgMaskingConfigApiStatus,
  setNavbarRenderFlag,
  setCandidateFilterDownloadConfig,
  setCandidateFilterCommunicationConfig,
} from './ActionCreators/ConfigActions';
import { setError } from './ActionCreators/CommonActionCreators';
import { setNotification } from './ActionCreators/ConnectActions';

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

const allowedCountriesFeaturesQuery = {
  FeatureNames: ['CandidateResumeGeneration', 'Diversity'],
};

function setWhiteLabelData(data) {
  return {
    type: 'SET_WHITE_LABEL_DATA',
    payload: data,
  };
}

export function removeWhiteLabelData() {
  return dispatch => {
    dispatch(setWhiteLabelData({}));
  };
}

export function fetchWhiteLabelInfo(client) {
  return dispatch => {
    if (client) {
      configRepository.fetchWhiteLabelInfo(client).then(whiteLabelResponse => {
        dispatch(setWhiteLabelData(whiteLabelResponse.data));
      });
    }
  };
}

function setAppConfiguration(appConfiguration) {
  return {
    type: 'SET_APP_CONFIGURATION',
    payload: appConfiguration,
  };
}

export function fetchAppConfiguration(appType) {
  return async dispatch => {
    try {
      const response = await configRepository.fetchAppConfiguration(appType);
      dispatch(setAppConfiguration(response.data));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
}

export function fetchJobDistributionPortals() {
  return async dispatch => {
    try {
      const response = await configRepository.fetchJobDistributionConfiguration();
      dispatch(setDistributionPortals(response.data));
      return response.data;
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'DATA_FETCH_FAIL',
          timeStamp: new Date(),
        },
      });
      return null;
    }
  };
}

export function fetchCandidateDownloadFilterConfig() {
  return async dispatch => {
    try {
      const response = await configRepository.fetchCandidateDownloadFilterConfig();
      await dispatch(setCandidateFilterDownloadConfig(response.data));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'DATA_FETCH_FAIL',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function fetchCandidateCommunicateFilterConfig() {
  return async dispatch => {
    try {
      const response = await configRepository.fetchCandidateCommunicateFilterConfig();
      await dispatch(setCandidateFilterCommunicationConfig(response.data));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'DATA_FETCH_FAIL',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function fetchAllowedCountriesForFeatures() {
  return async dispatch => {
    const response = await configRepository.getFeaturesAllowedCountries(allowedCountriesFeaturesQuery);
    dispatch(setFeaturesAdditionalInfo(allowedCountriesFeaturesQuery.FeatureNames, response.data));
  };
}

export function fetchConfig() {
  return async dispatch => {
    try {
      dispatch({
        type: 'FETCH_CONFIG_API_STATUS',
        payload: 'PENDING',
      });
      const response = await configRepository.fetchConfig();
      const payload = response.data;
      dispatch({
        type: 'SET_CONFIG',
        payload,
      });
      let client = localStorage.getItem('Client');
      if (client !== _.get(payload, ['WhitelabelId'], 'Arya')) {
        localStorage.removeItem('Client');
        dispatch(setWhiteLabelData({}));
      }
      client = _.get(payload, ['WhitelabelId'], '');
      if (client) {
        localStorage.setItem('Client', client);
        dispatch(fetchWhiteLabelInfo(client));
      }
      dispatch({
        type: 'SET_FEATURES',
        payload: payload.Features,
      });
      dispatch(fetchAllowedCountriesForFeatures());
      if (payload?.Features?.find(feature => feature.Name === 'JobDistribution' && feature.IsEnabled)) {
        dispatch(fetchJobDistributionPortals());
      }
      dispatch({
        type: 'FETCH_CONFIG_API_STATUS',
        payload: 'SUCCESS',
      });
      const { Vaults } = response.data;
      if (Vaults && Vaults.length) {
        dispatch(fetchAtsConfig(response.data.Vaults[0]));
      }
    } catch (err) {
      // ? HACK! TODO: Fix API not to send 404 when user is not registered for _config API
      if (
        _.get(err, 'response.status', null) === 401 ||
        _.get(err, 'response.status', null) === 403 ||
        _.get(err, 'response.status', null) === 404
      ) {
        dispatch({
          type: 'SET_USER_AUTHORIZATION',
          payload: {
            IsAryaUser: false,
          },
        });
        dispatch({
          type: 'FETCH_CONFIG_API_STATUS',
          payload: 'FAILED',
        });
        dispatch({
          type: 'SET_LOAD_CURRENT_USER_API_STATUS',
          payload: 'FAILED',
        });
      } else {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      }
      throw err;
    }
  };
}

export function fetchConnectConfig() {
  return dispatch => {
    return configRepository
      .fetchConnectConfig()
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_CONNECT_CONFIG',
          payload,
        });
        dispatch({
          type: 'SET_FEATURES',
          payload: payload.Features,
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
        throw err;
      });
  };
}

export function getConnectConsentConfig() {
  return dispatch => {
    return configRepository
      .getConnectConsentConfig()
      .then(res => {
        dispatch({
          type: 'SET_CONNECT_CONSENT_CONFIG',
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
            error: err,
          },
        });
        throw err;
      });
  };
}

export function updateConsentConfig(isContactViewAllowed) {
  return dispatch => {
    dispatch({
      type: 'SET_UPDATE_CONSENT_CONFIG_API_STATUS',
      payload: 'InProgress',
    });
    configRepository
      .updateConsentConfig(isContactViewAllowed)
      .then(() => {
        dispatch({
          type: 'UPDATE_CONSENT_CONFIG',
          payload: isContactViewAllowed,
        });
        dispatch({
          type: 'SET_UPDATE_CONSENT_CONFIG_API_STATUS',
          payload: 'Completed',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_UPDATE_CONSENT_CONFIG_API_STATUS',
          payload: 'Failed',
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'somethingWentWrongUpdatingSetting',
          })
        );
      });
  };
}

export function patchUpdateConfig(configUpdate) {
  return dispatch => {
    dispatch({
      type: 'SET_UPDATE_CONFIG_API_STATUS',
      payload: 'InProgress',
    });
    configRepository
      .patchUpdateConfig(configUpdate)
      .then(() => {
        dispatch({
          type: 'UPDATE_CONFIG',
          payload: configUpdate,
        });
        dispatch({
          type: 'SET_UPDATE_CONFIG_API_STATUS',
          payload: 'Completed',
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'savedSuccessfully',
          })
        );
      })
      .catch(() => {
        dispatch({
          type: 'SET_UPDATE_CONFIG_API_STATUS',
          payload: 'Failed',
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToSave',
          })
        );
      });
  };
}

export function putUpdateConfig(configuration) {
  return dispatch => {
    configRepository
      .patchUpdateConfig(configuration)
      .then(() => {
        dispatch({
          type: 'SET_CONFIG',
          payload: configuration,
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'savedSuccessfully',
          })
        );
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToSave',
          })
        );
      });
  };
}

export function fetchAllPortalCredentials() {
  return dispatch => {
    configRepository.fetchAllPortalCredentials().then(response => {
      dispatch({
        type: 'SET_ALL_PORTAL_CREDENTIALS',
        payload: response.data.PortalCredentials,
      });
    });
  };
}

export function fetchJobDistributionPortalCredentials() {
  return async dispatch => {
    try {
      const response = await configRepository.fetchJobDistributionPortalCredentials();
      dispatch(setAllJobDistributionCredentials(response.data));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'DATA_FETCH_FAIL',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function fetchAllPortalCountries() {
  return dispatch => {
    configRepository.fetchAllPortalCountries().then(response => {
      dispatch({
        type: 'SET_ALL_PORTAL_COUNTRIES',
        payload: response.data.Portals,
      });
    });
  };
}

export function integratePortal(portal, credentials, index, clearAccount, addSelectedCountry) {
  return dispatch => {
    dispatch({
      type: 'SET_AUTHENTICATE_API_STATUS',
      payload: {
        portal,
        status: {
          [index]: 'INPROGRESS',
        },
      },
    });
    configRepository
      .integratePortal(portal, credentials)
      .then(() => {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'successfullyIntegrated',
          })
        );
        dispatch({
          type: 'SET_PORTAL_CREDENTIALS',
          payload: {
            portal,
            credentials,
          },
        });
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal,
            status: {
              [index]: 'COMPLETED',
            },
          },
        });
        addSelectedCountry(credentials.CountryCode);
        clearAccount();
        dispatch(fetchConfig());
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'invalidCredentials',
          })
        );
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal,
            status: {
              [index]: 'FAILED',
            },
          },
        });
      });
  };
}

export function integrateJobPortal(portal, credentials, index, clearAccount, addSelectedCountry) {
  return async dispatch => {
    try {
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'INPROGRESS'));
      await configRepository.integrateJobPortal(portal, credentials);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'successfullyIntegrated',
        })
      );
      dispatch(setJobPortalCredentials(portal, credentials));
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'COMPLETED'));
      addSelectedCountry(credentials.CountryCode);
      clearAccount();
      dispatch(fetchConfig());
    } catch (err) {
      if (err.response?.status === 409) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'credentialsAlreadyExistForThisCountry',
          })
        );
      } else {
        dispatch(
          setNotification('ERROR', {
            messageId: 'invalidCredentials',
          })
        );
      }
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'FAILED'));
    }
  };
}

export function disconnectJobPortal(portal, countryCode, index, removeSelectedCountry) {
  return async dispatch => {
    try {
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'INPROGRESS'));
      await configRepository.disconnectJobPortal(portal, countryCode);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'disconnectedSuccessfully',
        })
      );
      dispatch(removeJobPortalCredentials(portal, countryCode));
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'COMPLETED'));
      removeSelectedCountry(countryCode);
      dispatch(fetchConfig());
    } catch (err) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'someErrorOccuredTryAgain',
        })
      );
      dispatch(setJobPortalAuthenticationStatus(portal, index, 'FAILED'));
    }
  };
}

export function disintegratePortal(portal, countryCode, index, removeSelectedCountry, searchType) {
  return dispatch => {
    dispatch({
      type: 'SET_AUTHENTICATE_API_STATUS',
      payload: {
        portal,
        status: {
          [index]: 'INPROGRESS',
        },
      },
    });
    configRepository
      .disintegratePortal({ portal, countryCode, searchType })
      .then(() => {
        removeSelectedCountry(countryCode);
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'disconnectedSuccessfully',
          })
        );
        dispatch({
          type: 'DELETE_PORTAL_CREDENTIALS',
          payload: {
            portal,
            countryCode,
          },
        });
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal,
            status: {
              [index]: 'COMPLETED',
            },
          },
        });
        dispatch(fetchConfig());
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'someErrorOccuredTryAgain',
          })
        );
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal,
            status: {
              [index]: 'FAILED',
            },
          },
        });
      });
  };
}

export function integrateSource(sourceName, sourceDetails, index) {
  return dispatch => {
    dispatch({
      type: 'SET_AUTHENTICATE_API_STATUS',
      payload: {
        portal: sourceName,
        status: {
          [index]: 'INPROGRESS',
        },
      },
    });
    configRepository
      .integrateSource(sourceDetails)
      .then(() => {
        if (_.get(sourceDetails, ['IsAuthorized'])) {
          dispatch(
            setNotification('SUCCESS', {
              messageId: 'successfullyIntegrated',
            })
          );
        } else {
          dispatch(
            setNotification('SUCCESS', {
              messageId: 'disconnectedSuccessfully',
            })
          );
        }
        dispatch({
          type: 'SET_SOURCE_AUTHORIZATION',
          payload: {
            sourceName,
            sourceDetails,
          },
        });
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal: sourceName,
            status: {
              [index]: 'COMPLETED',
            },
          },
        });
        dispatch(fetchConfig());
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrongTryAgain',
          })
        );
        dispatch({
          type: 'SET_AUTHENTICATE_API_STATUS',
          payload: {
            portal: sourceName,
            status: {
              [index]: 'FAILED',
            },
          },
        });
      });
  };
}

export function fetchNotificationConfig(filter) {
  return async dispatch => {
    dispatch(setFetchNotificationConfigApiStatus('INPROGRESS'));
    try {
      const response = await configRepository.fetchNotificationConfig(filter);
      const payload = response?.data;
      dispatch(setNotificationConfig(payload));
      dispatch(setFetchNotificationConfigApiStatus('COMPLETED'));
    } catch {
      dispatch(setFetchNotificationConfigApiStatus('FAILED'));
      dispatch(setError('NOTIFICATION_CONFIG_FETCH_FAILED', new Date()));
    }
  };
}

export function updateNotificationConfigByType({ type, config }) {
  return async dispatch => {
    dispatch(setUpdateNotificationTypeConfigApiStatus(type, 'INPROGRESS'));
    try {
      await configRepository.updateNotificationConfigByType({ type, config });
      dispatch(setNotificationTypeConfig(type, config));
      dispatch(setUpdateNotificationTypeConfigApiStatus(type, 'COMPLETED'));
    } catch {
      dispatch(setUpdateNotificationTypeConfigApiStatus(type, 'FAILED'));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setError('NOTIFICATION_TYPE_CONFIG_UPDATE_FAILED', new Date()));
    }
  };
}

export function activateAllNotificationByType({ type, config }) {
  return async dispatch => {
    dispatch(setActivateAllNotificationTypeApiStatus(type, 'INPROGRESS'));
    try {
      await configRepository.updateNotificationConfigByType({ type, config });
      dispatch(setAllActiveByNotificationType(type, config));
      dispatch(setActivateAllNotificationTypeApiStatus(type, 'COMPLETED'));
    } catch {
      dispatch(setActivateAllNotificationTypeApiStatus(type, 'FAILED'));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setError('ACTIVATE_ALL_NOTIFICATION_FAILED', new Date()));
    }
  };
}

export function updateNotificationConfigById({ id, config }) {
  return async dispatch => {
    dispatch(setUpdateNotificationIdConfigApiStatus(id, 'INPROGRESS'));
    try {
      await configRepository.updateNotificationConfigById({ id, config });
      dispatch(setNotificationIdConfig(id, config));
      dispatch(setUpdateNotificationIdConfigApiStatus(id, 'COMPLETED'));
    } catch {
      dispatch(setUpdateNotificationIdConfigApiStatus(id, 'FAILED'));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setError('NOTIFICATION_ID_CONFIG_UPDATE_FAILED', new Date()));
    }
  };
}

export function getMaskingConfig() {
  return async dispatch => {
    const maskingConfig = await configRepository.fetchMaskingConfig();
    dispatch(setMaskingConfig(maskingConfig.data));
  };
}

export function fetchOrgMaskingConfig() {
  return async dispatch => {
    dispatch(setOrgMaskingConfigApiStatus('PENDING'));
    const orgMaskingConfig = await configRepository.fetchOrgMaskingConfig();
    dispatch(setOrgMaskingConfig(orgMaskingConfig.data));
    dispatch(setOrgMaskingConfigApiStatus('COMPLETED'));
  };
}

export function updateOrgMaskingConfig(config) {
  return async dispatch => {
    try {
      await configRepository.updateOrgMaskingConfig(config);
      dispatch(fetchOrgMaskingConfig());
      dispatch(getMaskingConfig());
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'maskingConfigSavedSuccessfully',
        })
      );
    } catch {
      dispatch(setError('Failed to save Masking Config', new Date()));
    }
  };
}

export function setNavbarRenderStatus(status) {
  return setNavbarRenderFlag(status);
}
