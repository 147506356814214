import React from 'react';
import { connect } from 'react-redux';
import { signIn } from 'aws-amplify/auth';
import * as JobActions from '../../Actions/JobActions';
import * as ConfigActions from '../../Actions/ConfigActions';
import * as scoutingAgentActions from '../../Actions/ScoutingAgentActions';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import fetchJobSourcingStatsAction from '../../Actions/SourcingStatsActions';
import { setupSocketConnections } from '../../Events/SocketConnectionManager';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as UserSessionActions from '../../Actions/UserSessionActions';
import getMergeTags from '../../Actions/MergeTagsActions';
import { createBroadcastChannel, closeBroadcastChannel } from '../../Events/BroadcastNotificationHandler';
import { getMaskingConfig as _fetchMaskingConfig } from '../../Actions/ConfigActions';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  fetchActivationStatus: JobActions.fetchActivationStatus,
  fetchJobDetails: JobActions.fetchJobDetails,
  fetchConfig: ConfigActions.fetchConfig,
  fetchConnectConfig: ConfigActions.fetchConnectConfig,
  fetchJobConfig: JobActions.fetchJobConfig,
  fetchJobSourcingStats: fetchJobSourcingStatsAction,
  createSocketConnection: setupSocketConnections,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchMessageTemplates: ConnectActions.fetchMessageTemplates,
  fetchEmailTemplates: ConnectActions.fetchEmailTemplates,
  fetchMergeTags: getMergeTags,
  fetchPortalCredentials: ConfigActions.fetchAllPortalCredentials,
  getConnectConsentConfig: ConfigActions.getConnectConsentConfig,
  loadCurrentUser: UserSessionActions.loadCurrentUser,
  fetchCallerIds: ConnectSettingsActions.fetchCallerIds,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  fetchCountries: JobActions.fetchCountries,
  fetchScoutingAgentConfig: scoutingAgentActions.getScoutingAgentConfigAction,
  fetchMaskingConfig: _fetchMaskingConfig,
};

class JobInitiatorContainer extends React.Component {
  constructor(props) {
    super(props);

    const { createSocketConnection } = props;

    this.fetchUserConfigs = this.fetchUserConfigs.bind(this);
    this.fetchJobConfigs = this.fetchJobConfigs.bind(this);
    document.addEventListener('visibilitychange', createSocketConnection, false);
    document.addEventListener('focus', createSocketConnection, false);
    document.addEventListener('blur', createSocketConnection, false);
  }

  componentWillUnmount() {
    closeBroadcastChannel();
  }

  componentDidMount() {
    const { currentUsername, currentPassword, fetchMaskingConfig } = this.props;
    if (currentUsername && currentPassword) {
      signIn({
        username: currentUsername,
        password: currentPassword,
      })
        .then(user => console.log('user: ', user))
        .catch(err => console.log('error', err));
    }
    this.fetchJobConfigs();
    this.fetchUserConfigs();
    createBroadcastChannel();
    fetchMaskingConfig();
  }

  fetchUserConfigs() {
    const {
      fetchConfig,
      fetchPortalCredentials,
      fetchConnectConfig,
      fetchEmailConfig,
      createSocketConnection,
      fetchEmailTemplates,
      fetchMessageTemplates,
      fetchMergeTags,
      getConnectConsentConfig,
      loadCurrentUser,
      fetchCallerIds,
      fetchUserEmails,
      fetchCountries,
      fetchScoutingAgentConfig,
    } = this.props;
    fetchConfig();
    fetchConnectConfig()
      .then(() => {
        fetchMergeTags();
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    getConnectConsentConfig();
    createSocketConnection();
    fetchEmailConfig({ isAdmin: true });
    fetchEmailConfig({ isAdmin: false });
    fetchPortalCredentials();
    fetchMessageTemplates(0, 10, true);
    fetchEmailTemplates(0, 10);
    loadCurrentUser();
    fetchCallerIds();
    fetchUserEmails({ isOrgDefault: true });
    fetchCountries();
    fetchScoutingAgentConfig();
  }

  fetchJobConfigs() {
    const { jobId, fetchActivationStatus, fetchJobDetails, fetchJobConfig, fetchJobSourcingStats } = this.props;
    if (jobId) {
      fetchJobDetails(jobId);
      fetchJobConfig(jobId);
      fetchActivationStatus([jobId]);
      fetchJobSourcingStats([jobId]);
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobInitiatorContainer);
export { JobInitiatorContainer as JobInitiatorContainerWithoutStore };
