/* eslint-disable no-console */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth';

import {
  CandidateListWithStore,
  JobIntelWithStore,
  ConnectWithStore,
  ActivationDialogWithStore,
  JobSettingsWithStore,
  BotConfigWithStore,
  ConnectContainerWithStore,
  JobInitiatorWithStore,
  setConfig,
  CallDialogWithStore,
  CandidateViewWithStore,
  UserSMTPSettingsWithStore,
  AdminSMTPSettingsWithStore,
  JobBoardSettingsWithStore,
  ContactSettingsWithStore,
  AdminSettingsWithStore,
  ManualSearchContainerWithStore,
  ApplicationSettingsWithStore,
  CandidateJobMatchingWithStore,
  SettingsWithStore,
  SegmentWithStore,
  SegmentDetailContainerWithStore,
  ScoutingAgentPackageWithStore,
} from './createPackage';

setConfig('us-east-1', 'us-east-1_CoBq70Cuo', '11v64u80pnfsjfo6fb85vm3u6h');

// !NOTE:  Add credentials before testing components
const username = '';
const password = '';

function notesContainer({ jobId, candidateId }) {
  return (
    <div>
      {jobId} {candidateId}
    </div>
  );
}

function getUrlParams() {
  const url = window.location.search.substr(1);
  const urlQuery = url.split('&');
  const params = {};
  urlQuery.forEach(query => {
    const [key, value] = query.split('=');
    params[key] = value;
  });
  if (!params.tab) {
    const urlPathName = window.location.pathname;
    const [, tabsUrl] = urlPathName.split('/');
    params.tab = tabsUrl;
  }
  return params;
}

class App extends React.Component {
  componentDidMount() {
    signIn({
      username,
      password,
    })
      .then(user => console.log('user: ', user))
      .catch(err => console.log('error', err));
  }

  render() {
    const { tab, jobId, candidateId, segmentId } = getUrlParams();
    return (
      <>
        <JobInitiatorWithStore jobId={jobId} />
        <Router>
          {(() => {
            switch (tab) {
              case 'candidates':
                return (
                  <>
                    <ActivationDialogWithStore jobId={jobId} showReviewDetails={false} />
                    <CandidateListWithStore
                      jobId={jobId}
                      notesContainer={notesContainer}
                      openJobViewInNewTabCallBack={jobCode => {
                        console.log(jobCode);
                      }}
                      onClickAddJobCallBack={(jobCode, candidateId) => {
                        console.log(jobCode, candidateId);
                      }}
                      openAtsView={(a, b, c, d) => {
                        console.log('ATS: ', a, b, c, d);
                      }}
                      OpenSipCallWindowsApp={async obj => {
                        console.log(obj);
                        const { openNotesPopUp } = obj;
                        setTimeout(() => {
                          openNotesPopUp();
                        }, 3000);
                      }}
                      postCallNotes={(a, b, c) => {
                        console.log(a, b, c);
                      }}
                      dialingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dialingTone.mp3`)}
                      ringingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/ringingTone.mp3`)}
                      dtmfTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dtmf.wav`)}
                      status="Sourced"
                      openSegmentAtsView={segmentId => {
                        console.log(segmentId);
                      }}
                      botEnabledFromBulkMessageAtsCallback={() => {
                        console.log('Bot Enabled');
                      }}
                      // candidateId={candidateId}
                      // connectionStatus="Connected"
                    />
                    <CallDialogWithStore
                      callNotesContainer={notesContainer}
                      postAtsCallNotes={(a, b, c) => {
                        console.log(a, b, c);
                      }}
                      OpenSipCallWindowsApp={async obj => {
                        console.log(obj);
                        const { openNotesPopUp } = obj;
                        setTimeout(() => {
                          openNotesPopUp();
                        }, 3000);
                      }}
                      dialingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dialingTone.mp3`)}
                      ringingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/ringingTone.mp3`)}
                      dtmfTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dtmf.wav`)}
                    />
                  </>
                );
              case 'intel':
                return <JobIntelWithStore jobId={jobId} />;
              case 'conversations':
                return (
                  <ConnectWithStore
                    notesContainer={notesContainer}
                    openAtsView={(a, b, c, d) => {
                      console.log('ATS: ', a, b, c, d);
                    }}
                    OpenSipCallWindowsApp={async obj => {
                      console.log(obj);
                      // const { toggleCallbuttonLoading, toggleCallWarningModal } = obj;
                      // setTimeout(() => {
                      //   toggleCallbuttonLoading();
                      //   toggleCallWarningModal();
                      // }, 3000);
                    }}
                    openJobViewInNewTabCallBack={jobCode => {
                      console.log(jobCode);
                    }}
                    onClickAddJobCallBack={(jobCode, candidateId) => {
                      console.log(jobCode, candidateId);
                    }}
                  />
                );
              case 'jobsettings':
                return (
                  <JobSettingsWithStore
                    jobId={jobId}
                    openWorkflowinNewTab={workflowDefinitionId => {
                      console.log(workflowDefinitionId);
                    }}
                  />
                );
              case 'botsettings':
                return <BotConfigWithStore jobId={jobId} />;
              case 'scoutingAgent':
                return <ScoutingAgentPackageWithStore jobId={jobId} />;
              case 'connectNotification':
                return <ConnectContainerWithStore />;
              case 'usersmtpsettings':
                return <UserSMTPSettingsWithStore />;
              case 'adminsmtpsettings':
                return <AdminSMTPSettingsWithStore />;
              case 'jobboardsettings':
                return <JobBoardSettingsWithStore />;
              case 'contactsettings':
                return <ContactSettingsWithStore />;
              case 'adminsettings':
                return <AdminSettingsWithStore />;
              case 'manualsearchcontainer':
                return (
                  <ManualSearchContainerWithStore
                    jobId={jobId}
                    defaultIsManualSearchFormVisible
                    defaultIsManualSearchFormMinimized
                    onSearchCandidatesCallback={() => {
                      console.log('ATS Call Tabs Is onSearchCandidatesCallback: ');
                    }}
                  />
                );
              case 'applicationsettings':
                return <ApplicationSettingsWithStore />;
              case 'candidateToJobMatching':
                return (
                  <CandidateJobMatchingWithStore
                    openJobViewInNewTabCallBack={jobCode => {
                      console.log(jobCode);
                    }}
                    onClickAddJobCallBack={(jobCode, candidateId) => {
                      console.log(jobCode, candidateId);
                    }}
                    // candidateId="73e66892-8c5e-11eb-8e61-000c29adc9b9"
                    // candidateTitle="Java Developer"
                    // candidateSkills={['Java', 'Spring Boot', 'RESTful WebServices']}
                    // candidateCountry="US"
                    // version="ats"
                  />
                );
              case 'settings':
                return <SettingsWithStore />;

              case 'candidateView': // tab=candidateView&jobId=262211&candidateId=42d666fb-6c2e-11ea-a7c7-000c2916b604
                return (
                  <CandidateViewWithStore
                    openJobViewInNewTabCallBack={jobCode => {
                      console.log(jobCode);
                    }}
                    onClickAddJobCallBack={jobCode => {
                      console.log(jobCode);
                    }}
                    OpenSipCallWindowsApp={async obj => {
                      console.log(obj);
                      const { toggleCallbuttonLoading, toggleCallWarningModal } = obj;
                      setTimeout(() => {
                        toggleCallbuttonLoading();
                        toggleCallWarningModal();
                      }, 3000);
                    }}
                    jobId={jobId}
                    candidateId={candidateId}
                    candidateViewVersion="v3"
                    version="ats"
                    src="qs"
                    openSegmentAtsView={segmentId => {
                      console.log(segmentId);
                    }}
                  />
                );
              case 'segment':
                return <SegmentWithStore onSegmentCreationCallback={id => console.log({ id })} />;
              case 'segments':
                return (
                  <SegmentDetailContainerWithStore
                    segmentId={segmentId}
                    onSegmentCreationCallback={id => console.log({ id })}
                    OpenSipCallWindowsApp={async obj => {
                      console.log(obj);
                      const { openNotesPopUp } = obj;
                      setTimeout(() => {
                        openNotesPopUp();
                      }, 3000);
                    }}
                    openSegmentAtsView={segmentId => {
                      console.log(segmentId);
                    }}
                    openJobViewInNewTabCallBack={jobCode => {
                      console.log(jobCode);
                    }}
                    onClickAddJobCallBack={(jobCode, candidateId) => {
                      console.log(jobCode, candidateId);
                    }}
                    openListOfList={linkTo => {
                      console.log(linkTo);
                    }}
                    notesContainer={notesContainer}
                    callNotesContainer={notesContainer}
                    postAtsCallNotes={(noteContent, CandidateId, jobCode) => {
                      console.log(noteContent, CandidateId, jobCode);
                    }}
                  />
                );

              /* falls through */
              default:
                return <ActivationDialogWithStore jobId={jobId} showReviewDetails={false} />;
            }
          })()}
        </Router>
      </>
    );
  }
}

export default App;
