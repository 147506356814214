import React from 'react';
import styles from './EnhancedCandidateTransferModal.module.scss';

const CandidateTransferDetailsCard = props => {
  const { candidateName, candidateLocation, candidateEmail, isBulkIconActivated, candidateIds } = props;
  const candidateText = candidateIds.length === 1 ? 'Candidate' : 'Candidates';

  return (
    <div className={styles.candidateDetailsCard}>
      {isBulkIconActivated ? (
        <div>{`${candidateIds.length} ${candidateText} selected`}</div>
      ) : (
        <>
          <div className={styles.candidateName}>{candidateName}</div>
          <div className={styles.candidateDetails}>
            {candidateEmail ? `${candidateEmail} - ${candidateLocation}` : candidateLocation}
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateTransferDetailsCard;
