import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import * as JobActions from '../../Actions/JobActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as EmailTemplatesActions from '../../Actions/EmailTemplates';
import * as MessageTemplatesActions from '../../Actions/TextTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import * as PaymentActions from '../../Actions/AryaPayActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import JobFormComponent from '../../Components/JobForm/JobFormComponent';
import JobSettings from '../JobSettings/JobSettings';
import { getJobUtilities, getJobsById, getSkillSet, getTrynowFormData } from '../../Reducers/JobReducer';
import { getJobStatus, getDefaultStatusId, getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCurrentUser, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { getEmailTemplatesById, getEmailTemplatesCount, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import { getTextTemplatesById, getTextTemplatesCount, getTextTemplateIds } from '../../Reducers/TextTemplates';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import {
  getProductVariantsById,
  getProductVariants,
  getPlanDetailsByProductVariantId,
} from '../../Reducers/AryaPayOrderReducer';
import { getCreateOrderApiStatus } from '../../Reducers/AryaPayReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
// import BotConfig from '../../Components/Connect/BotConfig/BotConfig';
import { getContextById } from '../../Reducers/AryabotContextReducer';
import BotPrequalPopup from '../../Components/Connect/BotConfig/BotPrequalPopup';
import BotConfigPopup from '../BotConfig/BotConfigPopup';
import { NORMAL_JOB_SAVE_DRAFT, SAVE_JOB } from '../../PubSub/EventTypes';
import { getJobDetailsForAnalytics, getIsLoading, getIsFreeCandidateStepper } from '../../Utils/JobUtils';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../Utils/CreditInfoUtils';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import { LINK_TO_JOBS, LINK_TO_SELECT_PLAN, getJobCandidateLink } from '../../Utils/LinkUtils/LinkUtils';
import { setCandidatesViewedStats, raiseGtmEvents } from '../../Utils/PubSubUtils';
import ConfirmationDialogueBox from '../../Components/AdvancedJobCreation/ConfirmationDialogueBox/ConfirmationDialogueBox';
import config from '../../Config/Config';
import { getCurrentClientId, getCurrentClientName } from '../../Reducers/ClientReducer';
import { clearCurrentClientCreationInfo as _clearCurrentClientCreationInfo } from '../../Actions/ClientActions';
import { getAutomationConfiguration, getIsAutomationConfigured } from '../../Utils/AutomationUtils';

const mapStateToProps = state => ({
  jobUtilities: getJobUtilities(state),
  jobStatus: getJobStatus(state),
  defaultStatusId: getDefaultStatusId(state),
  jobsById: getJobsById(state),
  usersById: getUsersById(state),
  userConfig: getConfig(state),
  createJobStatus: getApiStatus(state, 'createJobStatus'),
  getJobQuestionApiStatus: getApiStatus(state, 'getJobQuestionApiStatus'),
  emailTemplatesCount: getEmailTemplatesCount(state),
  messageTemplatesCount: getTextTemplatesCount(state),
  emailTemplateIds: getEmailTemplateIds(state),
  dripTemplatesById: getDripTemplatesById(state),
  messageTemplateIds: getTextTemplateIds(state),
  emailTemplatesById: getEmailTemplatesById(state),
  messageTemplatesById: getTextTemplatesById(state),
  currentUser: getCurrentUser(state),
  titleSynonymStatusUpdate: getApiStatus(state, 'titleSynonymStatusUpdate'),
  skillsFetchStatusUpdate: getApiStatus(state, 'skillsFetchStatusUpdate'),
  tagsFetchStatusUpdate: getApiStatus(state, 'tagsFetchStatusUpdate'),
  featureToggleList: getFeatureToggleList(state),
  skillSetForJob: getSkillSet(state),
  productVariantrsById: getProductVariantsById(state),
  contextById: getContextById(state),
  trynowFormData: getTrynowFormData(state),
  tryNowCandidateApiStatus: getApiStatus(state, 'fetchTryNowCandidateApiStatus'),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  role: getConfig(state) ? getConfig(state).Role : 'Recruiter',
  currentClientId: getCurrentClientId(state),
  currentClientName: getCurrentClientName(state),
  productVariants: getProductVariants(state),
  createOrderApiStatus: getCreateOrderApiStatus(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  currentUserDetails: getCurrentUserDetails(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  createOrder: PaymentActions.createOrder,
  createJob: JobActions.createJob,
  fetchJobDetails: JobActions.fetchJobDetails,
  fetchTitleSynonyms: JobActions.fetchTitleSynonyms,
  fetchCountries: JobActions.fetchCountries,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetZipCodes: JobActions.resetZipcodes,
  fetchClients: JobActions.fetchClients,
  fetchIndustries: JobActions.fetchIndustries,
  fetchJobConfig: JobActions.fetchJobConfig,
  fetchJobStatus: JobStatusActions.fetchJobStatus,
  clearTitleSynonyms: JobActions.clearTitleSynonyms,
  fetchJobResumes: JobActions.fetchJobResumes,
  searchZipCode: JobActions.searchZipCode,
  clearZipCodeError: JobActions.clearZipCodeError,
  clearZipValidationError: JobActions.clearZipValidationError,
  fetchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  fetchMessageTemplates: MessageTemplatesActions.searchTextTemplates,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  downloadJobResume: JobActions.downloadJobResume,
  fetchTagsForJobForm: JobActions.fetchTagsForJobForm,
  updateTotalTagsCount: JobActions.updateTotalTagsCount,
  fetchSkills: JobActions.fetchSkills,
  fetchOrgActivatedJobsCount: JobActions.fetchOrgActivatedJobsCount,
  setTrynowFormData: JobActions.setTrynowFormData,
  fetchTryNowCandidates: CandidateActions.fetchTryNowCandidates,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
  saveJobDiversityChanges: JobActions.saveJobDiversityChanges,
  clearCurrentClientCreationInfo: _clearCurrentClientCreationInfo,
  fetchOrgOpenedJobsCount: JobActions.fetchOrgOpenedJobsCount,
};

class JobCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toShareWith: [],
      toUnshareWith: [],
      settingsDrawerVisibility: false,
      botModalVisibility: false,
      botPrequalPopupVisibility: false,
      confirmationDialogueBoxVisibility: false,
    };
    this.createNewJob = this.createNewJob.bind(this);
    this.toShareJob = this.toShareJob.bind(this);
    this.onClose = this.onClose.bind(this);
    this.toggleSettingsDrawer = this.toggleSettingsDrawer.bind(this);
    this.changeJobConfig = this.changeJobConfig.bind(this);
    this.changeAutoEmailEnabled = this.changeAutoEmailEnabled.bind(this);
    this.changeAutoMessageEnabled = this.changeAutoMessageEnabled.bind(this);
    this.changeSourceLimit = this.changeSourceLimit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.createJobForPulse = this.createJobForPulse.bind(this);
    this.placeJob = this.placeJob.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { userConfig, location, currentUser, featureToggleList } = props;
    const { jobConfig } = state;
    const defaultSourceLimit = _.get(userConfig, 'DefaultSourceLimit', null);
    const isJobConfigEnabled = _.get(featureToggleList, ['JobConfiguration', 'IsEnabled'], false);
    const jobId = _.get(location, ['state', 'jobId'], null);
    if (isJobConfigEnabled) {
      if (!_.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], false) && defaultSourceLimit && !jobId) {
        const newSourcingConfig = {
          SourceLimit: defaultSourceLimit,
        };
        const newJobConfig = { ...jobConfig, SourcingConfiguration: newSourcingConfig };
        return {
          jobConfig: newJobConfig,
        };
      }
      if (!_.get(jobConfig, 'AutomationConfiguration', false) && !jobId) {
        const newAutomationConfig = {
          Email: {},
          Message: {},
        };
        const newJobConfig = { ...jobConfig, AutomationConfiguration: newAutomationConfig };
        return {
          jobConfig: newJobConfig,
        };
      }
      if (jobId) {
        const jobDetails = _.get(props, ['jobsById', jobId], undefined);
        const automationConfig = _.get(jobDetails, ['AutomationConfiguration'], null);
        const workflowAutomationConfiguration = automationConfig?.Workflows?.[0];
        const sourcingConfig = _.get(jobDetails, ['SourcingConfiguration'], null);
        const autoEmailTemplateFrom =
          _.get(automationConfig, ['Email', 'From'], null) ||
          _.get(workflowAutomationConfiguration, 'EmailActivityFromAddress', undefined);
        const currentUserEmail = _.get(currentUser, 'email', null);
        const currentUserGuid = _.get(currentUser, 'sub', null);

        if (
          state.isAutoEmailEnabled === undefined &&
          state.isAutoMessageEnabled === undefined &&
          jobDetails &&
          automationConfig &&
          sourcingConfig &&
          defaultSourceLimit
        ) {
          if (autoEmailTemplateFrom !== currentUserEmail) {
            if (!automationConfig?.Workflows?.[0]) {
              _.set(automationConfig, ['Email', 'From'], currentUserEmail);
              _.set(automationConfig, ['Email', 'FromUserGuid'], currentUserGuid);
            } else {
              automationConfig.Workflows[0].EmailActivityFromAddress = currentUserEmail;
              automationConfig.Workflows[0].WorkflowContextUserId = currentUserGuid;
            }
          }
          const newSourcingConfig = {
            SourceLimit: _.get(sourcingConfig, 'SourceLimit', defaultSourceLimit),
          };
          const newJobConfig = {
            AutomationConfiguration: automationConfig || {},
            SourcingConfiguration: newSourcingConfig,
          };
          const isAutoEmailEnabled =
            getIsAutomationConfigured(automationConfig, featureToggleList, 'Email') ||
            getIsAutomationConfigured(automationConfig, featureToggleList, 'Workflows');
          const isAutoMessageEnabled = getIsAutomationConfigured(automationConfig, featureToggleList, 'Message');
          return {
            jobConfig: newJobConfig,
            isAutoEmailEnabled,
            isAutoMessageEnabled,
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const {
      fetchJobStatus,
      fetchOrgActivatedJobsCount,
      location,
      fetchJobDetails,
      fetchJobResumes,
      fetchJobConfig,
      fetchPlanDetailsByProductVariantId,
      fetchOrgOpenedJobsCount,
    } = this.props;
    if (_.get(location, 'state.jobId', false)) {
      fetchJobDetails(location.state.jobId);
      fetchJobResumes(location.state.jobId);
      fetchJobConfig(location.state.jobId);
    }
    fetchJobStatus();
    fetchOrgActivatedJobsCount();
    fetchPlanDetailsByProductVariantId();
    fetchOrgOpenedJobsCount();
  }

  toggleSettingsDrawer() {
    const { settingsDrawerVisibility } = this.state;
    this.setState({
      settingsDrawerVisibility: !settingsDrawerVisibility,
    });
  }

  changeJobConfig(jobConfig) {
    const emailTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], undefined);
    const workflowTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0]
      ?.WorkflowDefinitionId;
    const messageTemplateId = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], undefined);
    this.setState({
      jobConfig,
    });
    if (emailTemplateId) {
      this.changeAutoEmailEnabled(_.get(jobConfig, ['AutomationConfiguration', 'Email', 'IsEnabled'], false));
    }
    if (workflowTemplateId) {
      this.changeAutoEmailEnabled(_.get(jobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0]?.IsEnabled);
    }
    if (messageTemplateId) {
      this.changeAutoMessageEnabled(_.get(jobConfig, ['AutomationConfiguration', 'Message', 'IsEnabled'], false));
    }
  }

  changeSourceLimit(sourceLimit) {
    const { jobConfig } = this.state;
    const newSourcingConfig = {
      SourceLimit: sourceLimit,
    };
    this.setState({
      jobConfig: { ...jobConfig, SourcingConfiguration: newSourcingConfig },
    });
  }

  changeAutoEmailEnabled(status) {
    this.setState({
      isAutoEmailEnabled: status,
    });
  }

  changeAutoMessageEnabled(status) {
    this.setState({
      isAutoMessageEnabled: status,
    });
  }

  onClose() {
    const { history, clearTitleSynonyms, resetZipCodes } = this.props;
    history.push('/jobs');
    clearTitleSynonyms();
    resetZipCodes();
  }

  handleBack() {
    const { history, clearTitleSynonyms, resetZipCodes, featureToggleList } = this.props;
    const isJobListEnabled = _.get(featureToggleList, ['JobList', 'IsEnabled'], false);
    history.goBack();
    clearTitleSynonyms();
    if (isJobListEnabled) {
      resetZipCodes();
    }
  }

  skipButton = () => {
    this.setState({
      botPrequalPopupVisibility: false,
      botModalVisibility: true,
    });
  };

  onBotClose = () => {
    this.setState({
      botModalVisibility: false,
    });
    this.onClickSkip();
  };

  async createJobForPulse(jobDetails, submitAction, jobConfig, resumeUpdateDetails) {
    const newJob = _.cloneDeep(jobDetails);
    const { confirmationDialogueBoxVisibility } = this.state;
    const {
      currentUser,
      jobStatus,
      history,
      showSampleCandidates,
      createJob,
      planDetailsByProductVariantId,
      productVariantrsById,
    } = this.props;
    newJob.AssignedTo = [currentUser?.sub];
    const status = jobStatus.find(elem => elem.Name === 'Draft');
    newJob.StatusId = status.Id;
    newJob.BotSumaryViewUrl = config.urls.botSummaryViewUrl;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantrsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantrsById);
    const isFreeCandidateStepper = getIsFreeCandidateStepper(
      availablePaidCredits,
      showSampleCandidates,
      freeJobCreditsCount
    );
    const redirectOptions = {
      history,
      redirectUrl: submitAction === 'SaveDraft' ? LINK_TO_JOBS : LINK_TO_SELECT_PLAN,
    };
    const currentJob =
      submitAction === 'SaveDraft' || !isFreeCandidateStepper
        ? await createJob(newJob, redirectOptions, resumeUpdateDetails?.uploadedResumeFiles, jobConfig, submitAction)
        : null;
    const jobData = currentJob?.data;
    if (isFreeCandidateStepper && submitAction !== 'SaveDraft') {
      if (!confirmationDialogueBoxVisibility) {
        this.setState({ currentJobData: newJob });
        this.toggleConfirmationDialogueBoxVisibility();
      }
    }
    const analyticsJobDetails = getJobDetailsForAnalytics(jobData);
    const gtagPayload = {
      jobId: jobData?.JobId,
      jobTitle: jobData?.JobTitle,
      isAdvanced: jobData?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    };
    if (submitAction === 'SaveDraft') {
      PubSub.publish(NORMAL_JOB_SAVE_DRAFT, gtagPayload);
    } else if (!isFreeCandidateStepper && submitAction !== 'SaveDraft') {
      PubSub.publish(SAVE_JOB, gtagPayload);
    }
  }

  async createNewJob(jobDetails, values, resumeUpdateDetails, submitAction) {
    const newJob = {};
    const { createJob, history, location, jobsById, userConfig, featureToggleList } = this.props;
    const { PaidJobService } = featureToggleList;
    const { toShareWith, toUnshareWith, jobConfig } = this.state;
    let createdJob = {};
    Object.keys(values).forEach(key => {
      if (typeof values[key] !== 'undefined') {
        newJob[key] = values[key];
      }
    });
    newJob.Synonyms = jobDetails.Synonyms;
    newJob.Description = jobDetails.Description;
    newJob.Summary = jobDetails.Summary;
    newJob.SharedWith = toShareWith;
    newJob.GenderPreferences = jobDetails?.GenderPreferences;
    newJob.CategoryPreferences = jobDetails?.CategoryPreferences;
    const primaryLocation = newJob.SourcingLocations?.find(x => x.IsPrimaryLocation) || {};
    if (primaryLocation.Postalcode) newJob.Zipcode = primaryLocation.Postalcode;
    // newJob.MustHaves = newJob.MustHaves && newJob.MustHaves.join(',');
    // newJob.NiceToHaves = newJob.NiceToHaves && newJob.NiceToHaves.join(',');
    // newJob.EducationalQualifications = newJob.EducationalQualifications && newJob.EducationalQualifications.join(',');
    if (!toShareWith.length && !toUnshareWith.length) {
      newJob.SharedWith = jobDetails.SharedWith;
    }
    if (_.get(location, 'state.jobId', false)) {
      newJob.Resumes = _.get(jobsById, `${location.state.jobId}.Resumes`, []).filter(
        resume => !resumeUpdateDetails.removedResumeIds.includes(resume.Id)
      );
    }
    const modifiedJobConfig = _.cloneDeep(jobConfig || {});
    modifiedJobConfig.AutomationConfiguration = getAutomationConfiguration(
      _.get(jobConfig, 'AutomationConfiguration', {}),
      featureToggleList
    );
    const sourceLimit = _.get(modifiedJobConfig, ['SourcingConfiguration', 'SourceLimit'], null);
    const autoEmailTemplateId = _.get(modifiedJobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], null);
    const autoWorkflowTemplateId = _.get(modifiedJobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0]
      ?.WorkflowDefinitionId;
    const autoMessageTemplateId = _.get(modifiedJobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], null);
    const isSourcingConfigUpdated = sourceLimit !== userConfig.DefaultSourceLimit;
    if (PaidJobService.IsEnabled) {
      this.createJobForPulse(newJob, submitAction, modifiedJobConfig, resumeUpdateDetails);
    } else if (isSourcingConfigUpdated || autoWorkflowTemplateId || autoEmailTemplateId || autoMessageTemplateId) {
      createdJob = await createJob(newJob, null, resumeUpdateDetails.uploadedResumeFiles, modifiedJobConfig);
      history.push('/jobs');
    } else {
      createdJob = await createJob(newJob, null, resumeUpdateDetails.uploadedResumeFiles);
      history.push('/jobs');
    }
    return createdJob;
  }

  onClickSkip = () => {
    const { history } = this.props;
    const { currentJobData } = this.state;
    this.setState({
      botPrequalPopupVisibility: false,
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${currentJobData.JobGuid}&jobId=${currentJobData.JobId}`,
      state: currentJobData,
    });
  };

  onBotCancel = () => {
    const { history } = this.props;
    const { currentJobData } = this.state;
    this.setState({
      botPrequalPopupVisibility: false,
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${currentJobData.JobGuid}&jobId=${currentJobData.JobId}`,
      state: currentJobData,
    });
  };

  toShareJob(sharedWith, unSharedWith) {
    this.setState({
      toShareWith: sharedWith,
      toUnshareWith: unSharedWith,
    });
  }

  getJobSharedWithList() {
    const { usersById, currentUser } = this.props;
    const { toShareWith } = this.state;
    const currentUserEmail = _.get(currentUser, 'email', null);
    const allUsers = toShareWith;
    let users = allUsers ? _.uniq(allUsers).map(userId => _.get(usersById, [userId, 'Email'], null)) : [];
    users.push(currentUserEmail);
    users = users.filter(user => !!user);
    return users;
  }

  onClickSave = () => {
    this.setState({
      botPrequalPopupVisibility: false,
      botModalVisibility: true,
    });
  };

  hideBotModal = () => {
    this.setState({
      botPrequalPopupVisibility: false,
    });
  };

  toggleConfirmationDialogueBoxVisibility = () => {
    const { confirmationDialogueBoxVisibility } = this.state;
    this.setState({
      confirmationDialogueBoxVisibility: !confirmationDialogueBoxVisibility,
    });
  };

  async placeJob(jobDetails, resumeUpdateDetails, submitAction) {
    const { createJob, createOrder, history, productVariants, currentUserDetails } = this.props;
    const res = await createJob(jobDetails, null, resumeUpdateDetails?.uploadedResumeFiles, null);
    const freeTrialVariant = productVariants.filter(productVariant => productVariant.Name === FREE_TRIAL);
    const jobGuid = res.data.JobGuid;
    const jobId = res.data.JobId;
    if (submitAction !== 'draft') {
      const paymentData = {
        cardDetails: null,
        paymentProfileId: null,
        address: null,
      };
      const items = [
        {
          ProductVariantId: freeTrialVariant[0].Id,
          Quantity: 1,
          CreditsToUse: 1,
          RefIds: [jobGuid],
        },
      ];
      const orderResponse = await createOrder(items, paymentData, 'immediate');
      const orderResponseData = orderResponse.data;
      raiseGtmEvents(res.data, orderResponseData);
      const jobCandidateLink = getJobCandidateLink(jobId);
      history.push(jobCandidateLink);
      setCandidatesViewedStats([], currentUserDetails?.UserGuid);
    } else {
      history.push('/jobs');
    }
  }

  render() {
    const {
      toShareWith,
      toUnshareWith,
      settingsDrawerVisibility,
      isAutoEmailEnabled,
      isAutoMessageEnabled,
      jobConfig,
      botModalVisibility,
      botPrequalPopupVisibility,
      currentJobData,
      confirmationDialogueBoxVisibility,
    } = this.state;
    const {
      jobUtilities,
      jobStatus,
      fetchTitleSynonyms,
      fetchSkills,
      fetchCountries,
      fetchZipCodes,
      fetchClients,
      fetchTagsForJobForm,
      fetchIndustries,
      fetchJobStatus,
      defaultStatusId,
      usersById,
      jobsById,
      location,
      userConfig,
      createJobStatus,
      searchZipCode,
      clearZipCodeError,
      clearZipValidationError,
      fetchJobConfig,
      downloadJobResume,
      titleSynonymStatusUpdate,
      skillsFetchStatusUpdate,
      tagsFetchStatusUpdate,
      updateTotalTagsCount,
      featureToggleList,
      skillSetForJob,
      // contextById,
      getJobQuestionApiStatus,
      productVariantrsById,
      trynowFormData,
      setTrynowFormData,
      tryNowCandidateApiStatus,
      showSampleCandidates,
      setSampleCandidatesDisplayFlag,
      saveJobDiversityChanges,
      role,
      currentClientId,
      currentClientName,
      clearCurrentClientCreationInfo,
      createOrderApiStatus,
      planDetailsByProductVariantId,
      whiteLabelInfo,
    } = this.props;
    let jobDetails;

    const isConfigLoaded = !!userConfig;
    if (_.get(location, 'state.jobId', false)) {
      jobDetails = _.get(jobsById, location.state.jobId, undefined);
      _.set(jobDetails, ['SharedWith'], []);
      _.set(jobDetails, ['AssignedTo'], []);
      _.set(jobDetails, ['CreatedBy'], undefined);
      // jobDetails.SharedWith = [];
      // jobDetails.CreatedBy = undefined;
    }
    let users = [];
    let showSkelton = true;
    const orgActivatedOrOpenedJobsCount = userConfig.IsJobActivationChargeEnabledOnJobOpen
      ? userConfig.OrgOpenedJobsCount
      : userConfig.OrgActivatedJobsCount;
    if (userConfig) {
      if ((userConfig.JobLimit && orgActivatedOrOpenedJobsCount !== undefined) || !userConfig.JobLimit) {
        showSkelton = false;
      }
    }
    if (defaultStatusId && !showSkelton) {
      if (!toShareWith.length && !toUnshareWith.length && jobDetails) {
        users =
          jobDetails.SharedWith && usersById
            ? jobDetails.SharedWith.map(userId => {
              return {
                name: usersById[userId] ? usersById[userId].FullName : null,
                Id: userId,
                Email: usersById[userId].Email,
              };
            })
            : [];
        users = users.filter(user => !!user);
      } else {
        users = toShareWith.map(id => ({
          name: usersById[id] ? usersById[id].FullName : null,
          Id: id,
          Email: usersById[id] ? usersById[id].Email : null,
        }));
      }

      const isLoading = getIsLoading(createOrderApiStatus, createJobStatus);
      const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantrsById);
      const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantrsById);
      const isFreeCandidateStepper = getIsFreeCandidateStepper(
        availablePaidCredits,
        showSampleCandidates,
        freeJobCreditsCount
      );

      const isAutoWorkflowConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Workflows'], [])?.[0]
        ?.WorkflowDefinitionId;
      const isAutoEmailConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Email', 'TemplateId'], false);
      const isAutoMessageConfigured = _.get(jobConfig, ['AutomationConfiguration', 'Message', 'TemplateId'], false);
      const isAutomationConfigured = isAutoEmailConfigured || isAutoWorkflowConfigured || isAutoMessageConfigured;
      const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
      const isMultiLocationSourcing = _.get(featureToggleList, ['MultiLocationSourcing', 'IsEnabled'], false);
      const isMultiLocationJob = isMultiLocationSourcing && jobDetails?.IsMultiLocationJob;
      if (isPaidJobServiceEnabled) {
        jobDetails = { ...jobDetails };
      }
      const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');

      return (
        <div>
          <JobSettings
            SourcingConfiguration={_.get(jobConfig, 'SourcingConfiguration', {})}
            AutomationConfiguration={getAutomationConfiguration(
              _.get(jobConfig, 'AutomationConfiguration', {}),
              featureToggleList
            )}
            sourceLimit={_.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], null)}
            drawerVisibility={settingsDrawerVisibility}
            toggleSettingsDrawer={this.toggleSettingsDrawer}
            jobDetails={jobDetails}
            updateJobConfig={this.changeJobConfig}
            isSourceLimitReducible
            isSourcingAllowed
            isCandidateAutoRejectAllowed
            isSendToOptionAllowed
            isEmailAutomationNoteAllowed
            isMessageAutomationNoteAllowed
            isJobCreateOrUpdate
          />
          <BotConfigPopup
            title="Arya Pulse PreQualified Questions"
            subTitle="Questions candidates will be asked"
            botModalVisibility={botModalVisibility}
            onBotClose={this.onBotClose}
            jobId={_.get(currentJobData, ['JobId'])}
            onBotCancel={this.onBotCancel}
            cancelButtonText="Skip"
            saveButtonText="Save and Continue"
            hideSelectTemplate
            getJobQuestionApiStatus={getJobQuestionApiStatus}
          />
          <BotPrequalPopup
            botPrequalPopupVisibility={botPrequalPopupVisibility}
            onClickSkip={this.onClickSkip}
            onClickSave={this.onClickSave}
          />
          <JobFormComponent
            type="create"
            jobDetails={{ ...jobDetails, IsMultiLocationJob: isMultiLocationJob }}
            defaultStatusId={defaultStatusId}
            fetchTagsForJobForm={fetchTagsForJobForm}
            redirectUrl="/jobs"
            isAutoEmailEnabled={isAutoEmailEnabled}
            isAutomationConfigured={isAutomationConfigured}
            isAutoMessageEnabled={isAutoMessageEnabled}
            sourceLimit={_.get(jobConfig, ['SourcingConfiguration', 'SourceLimit'], null)}
            handleSubmit={this.createNewJob}
            handleClose={this.onClose}
            fetchTitleSynonyms={fetchTitleSynonyms}
            fetchSkills={fetchSkills}
            fetchCountries={fetchCountries}
            fetchZipCodes={fetchZipCodes}
            fetchClients={fetchClients}
            fetchIndustries={fetchIndustries}
            fetchJobStatus={fetchJobStatus}
            utilities={jobUtilities}
            jobStatus={jobStatus}
            collaborators={users}
            toShareJob={this.toShareJob}
            userConfig={userConfig}
            apiStatus={createJobStatus}
            isConfigLoaded={isConfigLoaded}
            searchZipCode={searchZipCode}
            clearZipCodeError={clearZipCodeError}
            clearZipValidationError={clearZipValidationError}
            fetchJobConfig={fetchJobConfig}
            toggleSettingsDrawer={this.toggleSettingsDrawer}
            downloadJobResume={downloadJobResume}
            changeSourceLimit={this.changeSourceLimit}
            titleSynonymStatusUpdate={titleSynonymStatusUpdate}
            skillsFetchStatusUpdate={skillsFetchStatusUpdate}
            tagsFetchStatusUpdate={tagsFetchStatusUpdate}
            updateTotalTagsCount={updateTotalTagsCount}
            featureToggleList={featureToggleList}
            minSourceValue={1}
            handleBack={this.handleBack}
            skillSetForJob={skillSetForJob}
            location={location}
            productVariantrsById={productVariantrsById}
            trynowFormData={trynowFormData}
            setTrynowFormData={setTrynowFormData}
            tryNowCandidateApiStatus={tryNowCandidateApiStatus}
            showSampleCandidates={isFreeCandidateStepper}
            setSampleCandidatesDisplayFlag={setSampleCandidatesDisplayFlag}
            saveJobDiversityChanges={saveJobDiversityChanges}
            role={role}
            currentClientId={currentClientId}
            currentClientName={currentClientName}
            clearCurrentClientCreationInfo={clearCurrentClientCreationInfo}
            AppName={AppName}
          />
          <ConfirmationDialogueBox
            confirmationDialogueBoxVisibility={confirmationDialogueBoxVisibility}
            onCancel={this.toggleConfirmationDialogueBoxVisibility}
            onPlaceJob={this.placeJob}
            isLoading={isLoading}
            jobDetails={currentJobData}
          />
        </div>
      );
    }
    return (
      <div id="body-content-loading">
        <Skeleton loading active paragraph={{ rows: 16, width: '80' }}></Skeleton>
      </div>
    );
  }
}

JobCreate.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  defaultStatusId: PropTypes.number,
  jobDetails: PropTypes.shape({}),
  jobUtilities: PropTypes.shape({}),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

JobCreate.defaultProps = {
  jobStatus: [],
  jobDetails: {},
  jobUtilities: {},
  defaultStatusId: undefined,
};
const JobCreateContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(JobCreate));
export default JobCreateContainer;
export { JobCreate as JobCreateContainerWithoutStore };
