import _ from 'lodash';
import messages from '../Containers/ManualSearchV2/ManualSearchMessages';

export function getCurrentFilterFormValues(formValues) {
  return {
    Companies: formValues.filterCompanies ? [...formValues.filterCompanies].sort() : [],
    Industries: formValues.filterIndustries ? [...formValues.filterIndustries].sort() : [],
    Skills: formValues.filterSkills ? [...formValues.filterSkills].sort() : [],
    Educations: formValues.filterEducations ? [...formValues.filterEducations].sort() : [],
    Experiences: formValues.filterExperience ? [...formValues.filterExperience].sort() : [],
  };
}

export function getFliterSupllyViewList(original, list) {
  if (list && list.length > 0) {
    const arrayList = list.map(name => ({
      Name: _.upperFirst(_.toLower(name)),
      DisplayName: name,
      Value: 0,
      SupplyPercentage: 0.0,
    }));
    return _.unionBy(original, arrayList, 'Name');
  }
  return original;
}

export function getExperienceLabelName(value, str, intl) {
  const updatedValues = _.split(value, '-', 2);
  if (updatedValues.length === 2) {
    if (updatedValues[0] === '*') {
      const year = _.toInteger(updatedValues[1]);
      return intl.formatMessage({ ...messages.yearsCountLabel }, { str, year });
    }
    if (updatedValues[1] === '*') {
      const year = _.toInteger(updatedValues[0]);
      return intl.formatMessage({ ...messages.noOfYearsLabel }, { year });
    }
    const yearLowerLimit = _.toInteger(updatedValues[0]);
    const yearUpperLimit = _.toInteger(updatedValues[1]) - 1;
    return intl.formatMessage({ ...messages.yearsRangeLabel }, { yearLowerLimit, yearUpperLimit });
  }
  return value;
}

export function convertToPascalCase(inputString) {
  return inputString?.replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
}

export function getMapingStatsList(list, name, totalCount) {
  if (list?.length && !!name) {
    let arrayList = list.map(obj => ({
      ...obj,
      Name: _.upperFirst(_.toLower(obj[name])),
      Value: obj.Count,
      DisplayName: _.trim(obj[name]),
      SupplyPercentage: totalCount === 0 ? 0 : ((obj.Count / totalCount) * 100).toFixed(2),
    }));
    arrayList = _.unionBy(arrayList, 'Name');
    if (name === 'SkillName' || name === 'Certification') {
      arrayList = arrayList.map(obj => ({
        ...obj,
        DisplayName: convertToPascalCase(_.trim(obj[name])),
      }));
    }
    return name === 'Range' ? _.unionBy(arrayList, 'Name') : _.orderBy(arrayList, ['Count'], ['desc']);
  }
  if (name === 'Degree' && list && Object.values(list)?.length) {
    const educationStat = [];
    Object.values(list).forEach(({ Degrees }) => {
      Degrees.forEach(degree => {
        const tempObj = {
          ...degree,
          Name: _.upperFirst(_.toLower(degree[name])),
          Value: degree.Count,
          DisplayName: _.trim(degree[name]),
          SupplyPercentage: totalCount === 0 ? 0 : ((degree.Count / totalCount) * 100).toFixed(2),
        };
        educationStat.push(tempObj);
      });
    });
    return educationStat;
  }
  return [];
}

export function getEducationalRelevanceInfo(list = []) {
  const educationalRelevanceJson = {
    CandidateEducation: 'Bachelors',
    JobRequirement: 'bachelors',
    RelevantEducations: [],
  };
  if (!_.isEmpty(list) && list.length > 0) {
    const topThree = list.slice(0, 3);
    const newTotalCount = list.map(item => item.Count).reduce((acc, value) => acc + value);
    const newlist = topThree.map((obj, i) => ({
      ...obj,
      Value: obj.Count,
      Degree: obj.Name,
      Relevancy: newTotalCount === 0 ? 0 : (obj.Count / newTotalCount) * 100,
      Index: i,
    }));
    educationalRelevanceJson.JobRequirement = topThree[0].Name;
    educationalRelevanceJson.RelevantEducations = newlist;
    return educationalRelevanceJson;
  }
  return educationalRelevanceJson;
}
export function getCheckBoxIntialValues(list = []) {
  if (!_.isEmpty(list) && list.length > 0) {
    return list.map(val => _.upperFirst(_.toLower(val)));
  }
  return [];
}

export const colorsForDegree = ['rgba(19,194,107,1)', 'rgba(19,194,107,0.7)', 'rgba(19,194,107,0.5)', '#83878C'];
export function prepareDonutChart(list) {
  if (list && list.length > 0) {
    const topThree = list.slice(0, 3);
    const newTotalCount = list.map(item => item.Count).reduce((acc, value) => acc + value);
    const newlist = topThree.map((obj, i) => ({
      degree: obj.Name,
      fillColor: colorsForDegree[i],
      relevancy: newTotalCount === 0 ? 0 : (obj.Count / newTotalCount) * 100,
      displayText: (newTotalCount === 0 ? 0 : (obj.Count / newTotalCount) * 100).toFixed(2),
    }));
    const othersRelevancy = 100 - newlist.reduce((acc, val) => acc + val.relevancy, 0);
    if (othersRelevancy > 0) {
      newlist.push({
        degree: 'Others',
        fillColor: '#83878C',
        relevancy: othersRelevancy,
        displayText: othersRelevancy.toFixed(2),
      });
    }
    return newlist;
  }
  return [];
}
export const listColors = [
  '#1890ff',
  '#8e76e4',
  '#ff855c',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
  '#6bbdc1',
  '#e7373c',
  '#bdf765',
  '#88d4c1',
  '#5572b4',
  '#bdf765',
  '#16e68b',
  '#1ba655',
  '#a9a93e',
  '#5d7883',
  '#1890ff',
];
export function prepareColourChartData(list, totalCount) {
  if (list.length > 0) {
    let newlist = list.length > 15 ? list.slice(0, 15) : list;
    newlist = newlist.map((obj, i) => ({
      Name: obj.Name,
      Value: obj.Count,
      Count: obj.Count,
      colorCode: listColors[i],
      SupplyPercentage: ((obj.Count / totalCount) * 100).toFixed(2),
      DisplayName: obj.DisplayName,
    }));
    return newlist;
  }
  return [];
}

export const listOfSkills = [
  {
    Name: 'JavaScript',
    SupplyPercentage: '70%',
  },
  {
    Name: 'C#',
    SupplyPercentage: '65%',
  },
  {
    Name: 'HTML',
    SupplyPercentage: '60%',
  },
  {
    Name: 'Python',
    SupplyPercentage: '55%',
  },
];
export const CarrerProjecctionData = {
  Name: 'Software Developers',
  children: [
    {
      Title: 'software eng',
      CandidatesCount: 275,
    },
    {
      Title: 'software devlpr',
      CandidatesCount: 0,
    },
    {
      Title: 'software expert',
      CandidatesCount: 139,
    },
    {
      Title: 'software engineer',
      CandidatesCount: 96913,
    },
    {
      Title: 'Software Engineer',
      CandidatesCount: 96913,
    },
    {
      Title: 'software developer',
      CandidatesCount: 27296,
    },
    {
      Title: 'software engineers',
      CandidatesCount: 12,
    },
    {
      Title: 'software developers',
      CandidatesCount: 4,
    },
    {
      Title: 'software specialist',
      CandidatesCount: 3169,
    },
    {
      Title: 'software development',
      CandidatesCount: 12695,
    },
    {
      Title: 'Applications Developer',
      CandidatesCount: 2216,
    },
    {
      Title: 'Systems Software Designer',
      CandidatesCount: 1,
    },
    {
      Title: 'Embedded Systems Software Developer',
      CandidatesCount: 0,
    },
  ],
};
