import { Select, Form, DatePicker, Checkbox, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { EllipsedText } from '../../Utils/TextUtils';
import styles from './EmailWorkflow.module.scss';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import message from '../../Containers/CandidateDrawer/messages';
import jobMessage from '../JobForm/JobMessages';
import placeholder from '../Placeholders/PlaceholdersMessages';
import { AutomationSendToSelection } from '../../Utils/AutomationUtils';

const FormItem = Form.Item;
function EmailWorkflow(props) {
  const {
    workflowDripTemplatesById,
    workflowDripTemplatesCount,
    searchWorkflowTemplates,
    currentWorkflowDripTemplateIds,
    form,
    setDefinitionId,
    setStartTime,
    setEndTime,
    setCampaignTriggeredEndTime,
    isSendToOptionAllowed,
    isEmailAutomationNoteAllowed,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    selectedEmailTemplateSendTo,
    onEmailSendToChange,
    sendEmailTo,
    getIsEndTimeValid,
    getIsCampaignEndTimeValid,
    definitionId,
    startTime,
    endTime,
    campaignTriggeredEndTime,
    setWorkflowDefinitionName,
    version,
    openWorkflowinNewTab,
    intl,
    featureToggleList
  } = props;

  const workflowTemplatesByIdLength = Object.keys(workflowDripTemplatesById)?.length;
  const initialWorkflowTemplate = currentWorkflowDripTemplateIds.find(
    templateId => workflowDripTemplatesById[templateId]?.IsRecommended
  );

  React.useEffect(() => {
    if (workflowTemplatesByIdLength) {
      const templateId = definitionId ?? initialWorkflowTemplate;
      form.setFieldsValue({
        SelectedWorkflowTemplate: templateId,
      });
      setDefinitionId(templateId);
      const selectedWorkflowDefinitionName = workflowDripTemplatesById?.[templateId]?.Name;
      setWorkflowDefinitionName(selectedWorkflowDefinitionName);
    }
  }, [workflowTemplatesByIdLength]);

  const [isWorkflowShared, setIsWorkflowShared] = React.useState(false);
  const dateFormat = 'MM/DD/YYYY';
  const formattedStartDate = moment(startTime);

  const formattedEndDate = !endTime ? undefined : moment(endTime);
  const formattedTriggeredEndDate = !campaignTriggeredEndTime
    ? undefined
    : moment(moment.utc(campaignTriggeredEndTime).local().format('MM/DD/YYYY'));
  const handleSearch = debounce(value => {
    searchWorkflowTemplates({ page: 0, pageSize: 10, searchTerm: value, isDraft: false });
  }, 600);

  React.useEffect(() => {
    searchWorkflowTemplates({
      page: 0,
      pageSize: 50,
      searchTerm: '',
      isDraft: false,
      includeRecommendedTemplates: true,
    });
  }, []);

  const handleDropdownScroll = e => {
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      currentWorkflowDripTemplateIds.length < workflowDripTemplatesCount
    ) {
      searchWorkflowTemplates({ page: currentWorkflowDripTemplateIds.length / 10, pageSize: 10, isDraft: false }, true);
    }
  };
  const handleOnClear = () => {
    form.setFieldsValue({ SelectedWorkflowTemplate: undefined });
    setDefinitionId(undefined);
    setWorkflowDefinitionName(undefined);
  };
  React.useEffect(() => {
    const workflowDefinitionId = form.getFieldValue('SelectedWorkflowTemplate');
    const SelectedWorkflowDefinitionName = workflowDripTemplatesById?.[workflowDefinitionId]?.Name;
    const initialName = 'This Workflow is not shared with you';
    if (!SelectedWorkflowDefinitionName && Object.keys(workflowDripTemplatesById)?.length && workflowDefinitionId) {
      form.setFieldsValue({
        SelectedWorkflowTemplate: initialName,
      });
      setIsWorkflowShared(true);
    }
  }, [startTime, endTime, campaignTriggeredEndTime, definitionId, Object.keys(workflowDripTemplatesById)?.length]);
  const handleOnWorkflowClick = () => {
    const workflowDefinitionId = form.getFieldValue('SelectedWorkflowTemplate');
    if (version === 'ats') {
      openWorkflowinNewTab(workflowDefinitionId);
    } else {
      window.open(`/connect?tab=workflows&workflowId=${workflowDefinitionId}`);
    }
  };

  const handleSelect = value => {
    setDefinitionId(value);
    const SelectedWorkflowDefinitionName = workflowDripTemplatesById?.[value]?.Name;
    setWorkflowDefinitionName(SelectedWorkflowDefinitionName);
    setIsWorkflowShared(false);
  };

  const handleStartDateChange = (value, date) => {
    setStartTime(date);
  };
  const handleEndDateChange = (value, date) => {
    setEndTime(date);
  };
  const handleOnTriggerCampaignEndDateChange = (value, date) => {
    setCampaignTriggeredEndTime(date);
  };

  return (
    <div>
      <div className={styles.selectAndButtonWrapper}>
        {form.getFieldDecorator('SelectedWorkflowTemplate', {
          initialValue: initialWorkflowTemplate,
        })(
          <Select
            placeholder={intl.formatMessage({ ...placeholder.chooseAWorkflowLabel })}
            showSearch
            optionFilterProp="label"
            className={styles.workflowSelect}
            dropdownStyle={{ zIndex: '2200' }}
            onSearch={value => handleSearch(value)}
            onPopupScroll={handleDropdownScroll}
            filterOption={false}
            dropdownClassName={styles.workflowDropdownSelect}
            onSelect={handleSelect}
          >
            {currentWorkflowDripTemplateIds?.map(templateId => {
              const workflow = workflowDripTemplatesById[templateId];
              const isRecommended = workflow?.IsRecommended;
              return (
                <Select.Option key={workflow?.DefinitionId} value={workflow?.DefinitionId} label={workflow?.Name}>
                  <div className={styles.workflowOptionContainer}>
                    <span className={styles.workflowTemplateName}>
                      <EllipsedText text={workflow?.Name} maxTextLength={30} />
                    </span>
                    {isRecommended && (
                      <span>
                        <span className={styles.templateIdentifer}>Default</span>
                        <span className={styles.templateIdentifer}>Recommended</span>
                      </span>
                    )}
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        )}
        <div className={styles.linkButtons}>
          <Button type="link" onClick={handleOnClear}>
            <span>
              <FormattedMessage {...jobMessage.clearButton} />
            </span>
          </Button>
          {!isWorkflowShared ? (
            <>
              |
              <Button
                type="link"
                onClick={handleOnWorkflowClick}
                disabled={!form.getFieldValue('SelectedWorkflowTemplate')}
              >
                <span>
                  <FormattedMessage {...jobMessage.gotoWorkflowOption} />
                </span>
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.startEndDate}>
        <FormItem label={<FormattedMessage {...jobMessage.startLabel} />} required colon={false}>
          <DatePicker
            defaultValue={formattedStartDate}
            className={styles.datePicker}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder={intl.formatMessage({ ...placeholder.mmDDYYLabel })}
            onChange={handleStartDateChange}
          />
        </FormItem>
        <FormItem
          label={<FormattedMessage {...jobMessage.endLabel} />}
          colon={false}
          validateStatus={getIsEndTimeValid() ? '' : 'error'}
          help={getIsEndTimeValid() ? '' : 'End time cannot be less than start time'}
        >
          <DatePicker
            className={styles.datePicker}
            defaultValue={formattedEndDate}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder={intl.formatMessage({ ...placeholder.mmDDYYLabel })}
            onChange={handleEndDateChange}
          />
        </FormItem>
      </div>
      <div className={styles.triggeredEndTimeSelect}>
        {/* {form.getFieldDecorator('isTriggeredEndDateTimeChecked')(
          <Checkbox onChange={handleCheckbox}>Do not start campaign after</Checkbox>
        )} */}
        <FormItem
          colon={false}
          label={<FormattedMessage {...jobMessage.dontStartCampaignAfterLabel} />}
          validateStatus={getIsCampaignEndTimeValid() ? '' : 'error'}
          help={getIsCampaignEndTimeValid() ? '' : 'Campaign end time should be between start time and end time'}
        >
          <DatePicker
            className={styles.datePicker}
            defaultValue={formattedTriggeredEndDate}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder={intl.formatMessage({ ...placeholder.mmDDYYLabel })}
            onChange={handleOnTriggerCampaignEndDateChange}
          />
        </FormItem>
      </div>
      <br />
      {isSendToOptionAllowed ? (
        <div className={styles.sendToContainer}>
          <div className={styles.sendToLabel}>
            <FormattedMessage {...message.sendToLabel} />
          </div>
          <AutomationSendToSelection
            onChange={onEmailSendToChange}
            value={selectedEmailTemplateSendTo}
            sendTo={sendEmailTo}
            isScoreAndRankAutomationEnabled={featureToggleList.ScoreAndRankAutomation.IsEnabled}
          />
        </div>
      ) : null}
      {isEmailAutomationNoteAllowed ? (
        <BulkCandidatesDownloadFilterInfo
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          workflowTemplate
        />
      ) : null}
    </div>
  );
}

export default injectIntl(EmailWorkflow);
export { EmailWorkflow as EmailWorkflowWithoutInjectIntl };
