import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import AdminSettingsContainer from './AdminSettingsContainer';
import ApplicationSettingsContainer from './ApplicationSettingsContainer';
import NotificationSettingsContainer from '../Containers/Settings/NotificationSettings/NotificationSettings';
import { getConfig, getFetchUserConfigApiStatus } from '../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import messages from '../Containers/ManualSearchV2/ManualSearchMessages';

const { TabPane } = Tabs;
const mapStateToProps = state => ({
  userConfig: getConfig(state),
  fetchUserConfigApiStatus: getFetchUserConfigApiStatus(state),
  featureToggleList: getFeatureToggleList(state),
});

function SettingsContainer(props) {
  const { userConfig, fetchUserConfigApiStatus, featureToggleList, defaultActiveKey } = props;
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey ?? 'app');
  const [loadingSkeletonFlag, setLoadingSkeletonFlag] = React.useState(true);
  React.useEffect(() => {
    if (fetchUserConfigApiStatus === 'SUCCESS' && loadingSkeletonFlag) {
      setLoadingSkeletonFlag(false);
    }
  }, [fetchUserConfigApiStatus]);
  const isNotificationSettingsEnabled = featureToggleList?.SourcingNotificationEmails?.IsEnabled;
  return (
    <>
      <h1><FormattedMessage {...messages.settingsLabel} /></h1>
      <Skeleton active paragraph={{ rows: 12 }} loading={loadingSkeletonFlag}>
        <Tabs activeKey={activeKey} onTabClick={key => setActiveKey(key)}>
          <TabPane tab={<FormattedMessage {...messages.applicationLabel} />} key="app">
            <ApplicationSettingsContainer />
          </TabPane>
          {userConfig?.Role?.toLowerCase() !== 'recruiter' ? (
            <TabPane tab={<FormattedMessage {...messages.adminLabel} />} key="admin">
              <AdminSettingsContainer />
            </TabPane>
          ) : null}
          {isNotificationSettingsEnabled ? (
            <TabPane tab={<FormattedMessage {...messages.notificationsLabel} />} key="notifications">
              <NotificationSettingsContainer />
            </TabPane>
          ) : null}
        </Tabs>
      </Skeleton>
    </>
  );
}
const PackageSettings = connect(mapStateToProps, {})(SettingsContainer);
export default PackageSettings;
export { SettingsContainer as SettingsContainerWithoutStore };
