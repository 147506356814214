import React from 'react';
import _ from 'lodash';
import { Empty, Button, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getMessages, getBotContextId } from '../../Reducers/ChatConversationsReducer';
import * as ChatActions from '../../Actions/ChatActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import { getCandidatesConnectInfo, getPersonsById, getConversationPersonMapping } from '../../Reducers/ConnectReducer';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import ConnectBotWindow from '../../Components/Connect/ConnectBotWindow/ConnectBotWindow';
import './ChatWindow.scss';
import { AryaBotIcon } from '../../Icons/AryaIcons';
import { getNonRefundedContacts } from '../../Utils/ContactUtils';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCommunicationDisabledContent, getIsCandidateCommunicationDisabled } from '../../Utils/ConnectUtils';
import { UserAlertWithWrapper } from '../../Components/UserAlerts/UserAlerts';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import message from '../CandidateDrawer/messages';

const mapStateToProps = (state, { conversationId }) => ({
  messages: getMessages(state, conversationId),
  botContextId: getBotContextId(state, conversationId),
  connectInfo: getCandidatesConnectInfo(state),
  featureToggleList: getFeatureToggleList(state),
  personsById: getPersonsById(state),
  conversationPersonMapping: getConversationPersonMapping(state),
});

const mapDispatchToProps = {
  sendMessage: ChatActions.sendMessage,
  fetchMessages: ConnectActions.fetchMessages,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  markConversationAsRead: ConnectActions.markConversationAsRead,
  fetchJobsUnreadConversationCount: ConnectActions.fetchJobsUnreadConversationCount,
  getPersonDetails: ConnectActions.getPersonDetails,
};

// eslint-disable-next-line react/prefer-stateless-function
class ChatWindowContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSendMessage = this.onSendMessage.bind(this);
    this.markChatAsRead = this.markChatAsRead.bind(this);
  }

  componentDidMount() {
    const { personId, conversationId, fetchMessages, getPersonDetails } = this.props;
    if (personId && conversationId) {
      fetchMessages(conversationId, null, 'Chat');
      this.markChatAsRead();
      getPersonDetails(personId, conversationId);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchMessages, conversationId } = this.props;
    if (prevProps.conversationId !== conversationId) {
      fetchMessages(conversationId, null, 'Chat');
      this.markChatAsRead();
    }
  }

  componentWillUnmount() {
    this.markChatAsRead();
  }

  onSendMessage(message) {
    const { sendMessage, botContextId, personId, conversationId } = this.props;
    sendMessage({ message, botContextId, personId, conversationId });
  }

  markChatAsRead() {
    const { markConversationAsRead, conversationId, markNotificationAsRead, jobGuid } = this.props;
    markConversationAsRead(conversationId, 'Chat', jobGuid);
    markNotificationAsRead({
      Source: 'Connect',
      Types: ['Chat'],
      RefId: conversationId,
    });
  }

  checkIfAllPersonContactsRefunded = () => {
    const { connectInfo, personId } = this.props;
    const candidateConnectedInfo = _.get(connectInfo, ['ConnectStatuses', personId]);
    const contact = _.get(candidateConnectedInfo, 'Contact', {});
    const { nonRefundedPhones, nonRefundedEmails } = getNonRefundedContacts({ Contact: contact });
    const isAnyNonRefundedContactAvailable = nonRefundedPhones.length || nonRefundedEmails.length;
    const phones = _.get(contact, ['Phones'], []);
    const emails = _.get(contact, ['Emails'], []);
    const isContactAvailable = phones.length || emails.length;
    if (!isAnyNonRefundedContactAvailable && isContactAvailable) {
      return true;
    }
    return false;
  };

  onInitiateChat = () => {
    const { conversationId, personId } = this.props;
    const botUrl = `https://connect-qa.goarya.com/chat?personId=${personId}&conversationId=${conversationId}`;
    window.open(botUrl, '_blank', 'noopener,noreferrer');
  };

  render() {
    const {
      messages,
      conversationId,
      personId,
      showBotConfigDrawer,
      featureToggleList,
      connectInfo,
      candidate,
      emptyChatStyle,
      isCandidate360View,
      setCandidateViewHeaderVisibility,
      isOrgLevelCandidate,
      personsById,
      conversationPersonMapping,
    } = this.props;
    let messageInfo = <FormattedMessage {...message.noHistoryAvailableLabel} />;
    const isAllPersonContactsRefunded = this.checkIfAllPersonContactsRefunded();
    if (isAllPersonContactsRefunded)
      messageInfo = (
        <span>
          {<FormattedMessage {...message.candidateContactPullRefund} />}{' '}
          <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
          <FormattedMessage {...message.forAnyQueriesAndFurtherInformationLabel} />
        </span>
      );

    const isBotWriteEnabled = featureToggleList.BotWrite.IsEnabled;
    const isConnectBotWindowVisible = !isAllPersonContactsRefunded && _.get(messages, 'length', false);
    const showCommunicationDisabledAlert = getIsCandidateCommunicationDisabled(personId, connectInfo);

    if (showCommunicationDisabledAlert) {
      const alertContent = getCommunicationDisabledContent();
      return <UserAlertWithWrapper header={alertContent.header} content={alertContent.body} />;
    }

    const displayConfigureBotButton =
      isBotWriteEnabled && !isOrgLevelCandidate ? (
        <div className="configure-bot">
          <Button
            type="primary"
            className="configure-bot-button"
            onClick={showBotConfigDrawer}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnectBot.configureBot,
              candidate
            )}
          >
            <FormattedMessage {...message.configureAryaBotLabel} />
          </Button>
          <Button type="primary" className="configure-bot-button" onClick={this.onInitiateChat}>
            <FormattedMessage {...message.initiateChatLabel} />
          </Button>
        </div>
      ) : null;

    const emptyChatPlaceholder = (
      <div className={classNames('chat-empty', { [emptyChatStyle]: emptyChatStyle })}>
        <Empty
          description={
            <div>
              <div className="chat-empty-text">{messageInfo}</div>
              {displayConfigureBotButton}
            </div>
          }
          image={
            <img
              src={`${process.env.PUBLIC_URL}/static/Images/aryabot.svg`}
              style={{ width: 40, height: 40 }}
              alt="LeoBot"
            />
          }
        />
      </div>
    );

    return !conversationId ? (
      emptyChatPlaceholder
    ) : (
      <Skeleton active loading={(!personId && !isOrgLevelCandidate) || !conversationId}>
        {isConnectBotWindowVisible ? (
          <ConnectBotWindow
            messages={messages}
            onSendMessage={this.onSendMessage}
            personId={personId}
            conversationId={conversationId}
            featureToggleList={featureToggleList}
            candidate={candidate}
            isCandidate360View={isCandidate360View}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            isOrgLevelCandidate={isOrgLevelCandidate}
            personsById={personsById}
            conversationPersonMapping={conversationPersonMapping}
          />
        ) : (
          emptyChatPlaceholder
        )}
      </Skeleton>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindowContainer);

export { ChatWindowContainer as ChatWindowContainerWithoutStore };
