import React, { Component } from 'react';
import { Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';
import BotConfigContainer from '../BotConfig/BotConfig';
import messages from '../ManualSearchV2/ManualSearchMessages';

export default class BotConfigDrawerContainer extends Component {
  getDrawerTitle = () => {
    const { jobTitle } = this.props;
    const AppName = 'Leo';
    return (
      <span>
        <b>
          <FormattedMessage {...messages.botConfigurationLabel} values={{ AppName }} />
          &nbsp;
        </b>
        {jobTitle}
      </span>
    );
  };

  handleDrawerVisibleChange = isDrawerVisible => {
    const { toggleBotDrawerRenderFlag } = this.props;
    if (!isDrawerVisible) toggleBotDrawerRenderFlag();
  };

  render() {
    const {
      visible,
      jobId,
      onClose,
      botDrawerRenderFlag,
      showBotQuestionsReviewMessage,
      updateReviewMessageVisibility,
    } = this.props;
    if (!botDrawerRenderFlag) return null;
    return (
      <Drawer
        title={this.getDrawerTitle()}
        bodyStyle={{ height: '82%', overflowY: 'scroll' }}
        width={640}
        visible={visible}
        onClose={onClose}
        destroyOnClose
        afterVisibleChange={this.handleDrawerVisibleChange}
      >
        <BotConfigContainer
          onClose={onClose}
          jobId={jobId}
          showBotQuestionsReviewMessage={showBotQuestionsReviewMessage}
          updateReviewMessageVisibility={updateReviewMessageVisibility}
        />
      </Drawer>
    );
  }
}
