import React, { useState } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import * as connectActions from '../../Actions/ConnectActions';
import { getCandidates } from '../../Reducers/CandidateReducer';
import styles from '../AllEmails/AllEmails.module.scss';
import { getJobsBasicInfoById, getBasicInfoJobGuidToIdMapping } from '../../Reducers/JobsBasicInfoReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as candidateActions from '../../Actions/CandidateActions';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import CallHistory from '../CallHistory/CallHistory';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getAllCallConversations, getAllCallConversationsCount } from '../../Reducers/CallConversationsReducer';
import { getConnectUsersById } from '../../Reducers/UserReducer';

const mapStateToProps = (state, props) => {
  const { candidateId } = props;
  const candidatesById = getCandidates(state);
  const candidate = { ..._.get(candidatesById, [candidateId], {}) };
  const allCallConversationsCount = getAllCallConversationsCount(state, candidate.PersonId);
  return {
    allCallConversationsCount,
    candidatesById,
    candidate,
    featureToggleList: getFeatureToggleList(state),
    basicInfoJobGuidToIdMapping: getBasicInfoJobGuidToIdMapping(state),
    jobsBasicInfoById: getJobsBasicInfoById(state),
    currentUser: getCurrentUser(state),
    allCallConversations: getAllCallConversations(state, candidate.PersonId),
    connectUsersById: getConnectUsersById(state),
  };
};

const mapDispatchToProps = {
  fetchCalls: connectActions.fetchCalls,
  fetchCandidateDetails: candidateActions.fetchCandidateDetails,
};

function AllCalls(props) {
  const {
    jobId,
    candidateId,
    candidatesById,
    fetchCalls,
    version,
    featureToggleList,
    fetchCandidateDetails,
    openJobViewInNewTabCallBack,
    basicInfoJobGuidToIdMapping,
    jobsBasicInfoById,
    currentUser,
    allCallConversations,
    connectUsersById,
    allCallConversationsCount,
    setCount,
    candidate,
  } = props;

  const candidateInfo = { jobId: null, Id: candidateId };
  const pushCandInfo = { Country: null };
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const _fetchcandidateDetails = async () => {
    const _candidateDetails = await fetchCandidateDetails(candidateInfo, pushCandInfo, true);
    setCandidateDetails(_candidateDetails);
    if (_candidateDetails) fetchCalls(null, _candidateDetails.PersonId, null, 0, 10, setCount);
  };

  React.useEffect(() => {
    _fetchcandidateDetails();
  }, []);

  if (!candidateDetails) {
    return <LoadingScreen loading active />;
  }

  const {
    AllConversations: { IsEnabled: isAllConversationsEnabled },
  } = featureToggleList;

  return isAllConversationsEnabled ? (
    <div className={styles.infiniteScrollDiv}>
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        loadMore={page => {
          fetchCalls(null, candidateDetails.PersonId, null, page * 10, 10, setCount);
          setCurrentPage(page);
        }}
        threshold={150}
        hasMore={(currentPage + 1) * 10 < allCallConversationsCount}
      >
        <CallHistory
          callHistory={allCallConversations}
          currentUser={currentUser}
          connectUsersById={connectUsersById}
          connectComponent={false}
          unsubscribeCandidateButton={null}
          isUnsubscribeCandidateEnabled={false}
          personId={candidateDetails.PersonId}
          basicInfoJobGuidToIdMapping={basicInfoJobGuidToIdMapping}
          jobsBasicInfoById={jobsBasicInfoById}
          version={version}
          openCandidateView={() => {}}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          candidate={candidate}
          isAllCallsAts
        />
      </InfiniteScroll>
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCalls);
