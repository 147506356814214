import React from 'react';
import classNames from 'classnames';
import { Modal, Button, Form, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './EnhancedCandidateTransferModal.module.scss';
import CandidateTransferIcon from '../../Icons/CandidateTransferIconV2';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';
import CandidateCopyButton from './CandidateCopyButton';
import CandidateMoveButton from './CandidateMoveButton';
import CandidateTransferDetailsCard from './CandidateTransferDetailsCard';

function EnhancedCandidateTransferModal(props) {
  const {
    selectComponentWithForm,
    tooltipTitle,
    onClickTransferIcon,
    isCandidateTransferModalVisible,
    setCandidateTransferModalVisibility,
    handleOnClose,
    handleBulkTranfer,
    actionName,
    candidateTranserApiStatus,
    setActionName,
    setOptions,
    form,
    setDestinationName,
    destinationName,
    candidateTransferIconClassName,
    fillColor,
    candidateContext,
    customSelectNumber,
    isAryaRecommendedSelectAllChecked,
    jobId,
    selectSubsegmentComponentWithForm,
    activeTab,
    selectedSubSegmentId,
    candidateEmailAndLocation,
    isBulkIconActivated,
    candidateIds,
  } = props;

  const modelFooterMessage =
    candidateContext === 'segment' ? (
      <FormattedMessage {...message.copiedMovedToTheDestinationJobLabel} />
    ) : (
      <FormattedMessage {...message.copiedToTheDestinationJobLabel} />
    );
  const {
    CandidateName: candidateName,
    CandidateLocation: candidateLocation,
    CandidateEmail: candidateEmail,
  } = candidateEmailAndLocation;
  const destinationJobValue = form.getFieldValue('destinationJob');
  let tooltipContent;
  const isSameSubsegmentCopy = activeTab === 'subsegment' && selectedSubSegmentId;
  const isSelfCopy =
    destinationJobValue === jobId &&
    destinationName === 'Segment' &&
    (actionName === 'Move' ||
      (actionName === 'Copy' && !form.getFieldValue('destinationSubsegment')) ||
      isSameSubsegmentCopy);
  const isButtonEnabled = actionName && destinationName && destinationJobValue;
  const segmentModelTitle = <FormattedMessage {...message.copyMoveCandidatesLabel} />;
  const jobModelTitle = <FormattedMessage {...message.AddToLabel} />;
  const candidateTranferModelTitle = candidateContext === 'segment' ? segmentModelTitle : jobModelTitle;

  if (isSelfCopy) {
    if (actionName === 'Move')
      tooltipContent = <FormattedMessage {...message.movingCandidatesWithinSameSegmentLabel} />;
    if (actionName === 'Copy' && !form.getFieldValue('destinationSubsegment'))
      tooltipContent = <FormattedMessage {...message.copyingCandidatesWithinSameSegmentLabel} />;
    if (actionName === 'Copy' && isSameSubsegmentCopy)
      tooltipContent = <FormattedMessage {...message.copyingOfCandidatesWithSiblingLabel} />;
  }
  const isBoxSelected = (stateValue, key) => {
    return stateValue === key;
  };

  const handleActionChange = value => {
    if (actionName !== value) setActionName(value);
  };
  const handleOnChange = value => {
    if (destinationName !== value) {
      setOptions([]);
      form.setFieldsValue({ destinationJob: undefined });
      setDestinationName(value);
    }
  };

  return (
    <div
      className={styles.candidateTransferModalContainer}
      onClick={e => e.stopPropagation}
      onKeyPress={e => e.stopPropagation()}
    >
      <div>
        <Tooltip title={tooltipTitle}>
          <CandidateTransferIcon
            onClick={onClickTransferIcon}
            className={classNames(styles.enhancedCandidateTransferIcon, candidateTransferIconClassName)}
            fillColor={fillColor}
          />
        </Tooltip>
      </div>

      <Modal
        zIndex={2100}
        title={candidateTranferModelTitle}
        visible={isCandidateTransferModalVisible}
        onCancel={() => {
          setCandidateTransferModalVisibility(false);
          setOptions([]);
        }}
        footer={
          customSelectNumber || isAryaRecommendedSelectAllChecked ? (
            <div className={styles.transferModalFooter}>{modelFooterMessage}</div>
          ) : null
        }
      >
        {' '}
        <Form>
          <div className={styles.enhancedModalContent}>
            <div className={styles.actionField}>
              {candidateContext === 'segment' && <FormattedMessage {...message.ActionLabel} />}
              <div
                className={classNames(styles.fieldContainer, {
                  [styles.jobContext]: candidateContext === 'segment',
                })}
              >
                {candidateContext === 'segment' ? (
                  <>
                    <CandidateCopyButton
                      isBoxSelected={isBoxSelected}
                      actionName={actionName}
                      handleActionChange={handleActionChange}
                    />
                    <CandidateMoveButton
                      isBoxSelected={isBoxSelected}
                      actionName={actionName}
                      handleActionChange={handleActionChange}
                    />
                  </>
                ) : (
                  <CandidateTransferDetailsCard
                    candidateEmail={candidateEmail}
                    candidateLocation={candidateLocation}
                    candidateName={candidateName}
                    isBulkIconActivated={isBulkIconActivated}
                    candidateIds={candidateIds}
                  />
                )}
              </div>
            </div>
            <div className={styles.destinationField}>
              <FormattedMessage {...message.destinationLabel} />
              <div
                className={classNames(styles.fieldContainer, {
                  [styles.jobContext]: candidateContext === 'segment',
                })}
              >
                <div
                  className={classNames(styles.actionButtons, {
                    [styles.highlightedActionButtons]: isBoxSelected(destinationName, 'Job'),
                  })}
                  key="Job"
                  onClick={() => handleOnChange('Job')}
                  onKeyPress={() => handleOnChange('Job')}
                  tabIndex={0}
                  role="button"
                >
                  <div className={styles.actionButtonText}>
                    <FormattedMessage {...message.JobLabel} />
                  </div>
                </div>
                <div
                  className={classNames(styles.actionButtons, {
                    [styles.highlightedActionButtons]: isBoxSelected(destinationName, 'Segment'),
                  })}
                  key="Segment"
                  onClick={() => handleOnChange('Segment')}
                  onKeyPress={() => handleOnChange('Segment')}
                  tabIndex={0}
                  role="button"
                >
                  <div className={styles.actionButtonText}>
                    <FormattedMessage {...message.segmentLabel} />
                  </div>
                </div>
              </div>
            </div>
            <div>{selectComponentWithForm}</div>
            {destinationName === 'Segment' ? <div>{selectSubsegmentComponentWithForm} </div> : null}
            {tooltipContent ? (
              <div className={styles.errorInfo}>
                <FormattedMessage {...message.noteLabel} />: {tooltipContent}
              </div>
            ) : null}
            <div className={styles.footerButtons}>
              <div className={styles.closeButton}>
                <Button shape="round" key="back" onClick={handleOnClose}>
                  <FormattedMessage {...message.closeLabel} />
                </Button>
              </div>
              <Button
                shape="round"
                key="submit"
                type="primary"
                onClick={() => handleBulkTranfer(actionName)}
                loading={candidateTranserApiStatus === 'INPROGRESS'}
                disabled={!isButtonEnabled || isSelfCopy}
              >
                <FormattedMessage {...message.doneLabel} />
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default EnhancedCandidateTransferModal;
