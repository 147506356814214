import React from 'react';
import {
  OracleTaleoIcon,
  PinpointATSIcon,
  ManatalIcon,
  LeverByEmployIcon,
  BambooHRIcon,
  BullhornIcon,
  ClearCompanyIcon,
  JobViteIcon,
  WorkableIcon,
} from '../../../../Icons/ATSAuthenticationIcons';

function getSourceImage(source) {
  switch (source) {
    case 'Oracle Taleo':
      return <OracleTaleoIcon />;
    case 'Pinpoint ATS':
      return <PinpointATSIcon />;
    case 'Manatal':
      return <ManatalIcon />;
    case 'Lever by employ':
      return <LeverByEmployIcon />;
    case 'Bamboo HR':
      return <BambooHRIcon />;
    case 'Bullhorn':
      return <BullhornIcon />;
    case 'Clear Company':
      return <ClearCompanyIcon />;
    case 'Jobvite':
      return <JobViteIcon />;
    case 'Workable':
      return <WorkableIcon />;
    default:
      return null;
  }
}

export { getSourceImage };
