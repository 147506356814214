import React from 'react';
import StatsDetails from '../../StatsDetails/StatsDetails';
import { getDatafromPath } from '../../../../../../Utils/LodashUtils';
import StatsView from '../../StatsDetails/StatsView/StatsView';
import styles from './BotStats.module.scss';
import GenericDonut from '../../../../../D3Charts/GenericDonut';

const colors = ['#13C26B', '#F5222D', '#DADBDD'];
export default function BotStats(props) {
  const { botSessionStats, subtitle } = props;

  const totalBotSessions = getDatafromPath(botSessionStats, ['total'], 0);
  const completedBotSessions = getDatafromPath(botSessionStats, ['data', 0, 'Value'], 0);
  const inCompleteBotSessions = getDatafromPath(botSessionStats, ['data', 1, 'Value'], 0);

  return (
    <StatsDetails title="LeoBot" subtitle={subtitle}>
      <div className={styles.botStats}>
        <div className={styles.botStatsView}>
          <StatsView title="Total sessions" count={totalBotSessions} />
          <StatsView title="Complete sessions" count={completedBotSessions} type="success" />
          <StatsView title="Incomplete sessions" count={inCompleteBotSessions} type="error" />
        </div>
        <div>
          <GenericDonut
            data={botSessionStats?.data}
            width={208}
            height={208}
            title={totalBotSessions}
            subtitle="Total"
            colors={colors}
            showStatsText={false}
            chartTitle="LeoBot"
            thickness={35}
          />
        </div>
      </div>
    </StatsDetails>
  );
}
