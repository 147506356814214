import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Affix, message } from 'antd';
import PropTypes from 'prop-types';
import JobBoardSettings from '../JobBoardSettings/JobBoardSettings';
import BotSettings from '../BotSettings/BotSettings';
import JobBoardSettingsContainer from '../../Containers/JobBoardSettings/JobBoardSettingsContainer';
import messages from './messages';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import './ApplicationSettings.scss';
import DistanceSettings from '../DistanceSettings/DistanceSettings';
import {
  getPortalCountries,
  getWhiteLabelInformation,
  getJobDistributionPortals,
  getJobPortalsConfig,
} from '../../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import * as ConfigActions from '../../Actions/ConfigActions';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import { JobBoardPortals } from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import EmailConfigurationContainer from '../../Containers/EmailConfigurationContainer/EmailConfigurationContainer';
import CandidateAutoRejectSwitch from '../CandidateAutoRejectSwitch/CandidateAutoRejectSwitch';
import { getAvailableCredits } from '../../Reducers/AryaPayReducer';
import * as JobDistributionActions from '../../Actions/JobDistributionActions';
import applicationSettingMessages from './ApplicationSettingMessages';

const mapStateToProps = state => ({
  availableAdCredits: getAvailableCredits(state),
  fetchJobAdCreditsApiStatus: getApiStatus(state, 'fetchAvailableJobAdCreditsStatus'),
  updateBotDetailsApiStatus: getApiStatus(state, 'updateBotDetailsStatus'),
  updateConfigApiStatus: getApiStatus(state, 'updateConfigStatus'),
  testSmtpApiStatus: getApiStatus(state, 'testSmtpApiStatus'),
  portalCountries: getPortalCountries(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  currentUser: getCurrentUser(state),
  jobDistributionPortals: getJobDistributionPortals(state),
  jobPortalsConfig: getJobPortalsConfig(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  fetchAvailableJobAdCredits: JobDistributionActions.fetchAvailableJobAdCredits,
  fetchAllPortalCountries: ConfigActions.fetchAllPortalCountries,
  deleteSmtpEmail: ConnectSettingsActions.deleteSmtpEmail,
  fetchJobDistributionCredentials: ConfigActions.fetchJobDistributionPortalCredentials,
  authenticateJobPortal: ConfigActions.integrateJobPortal,
  disconnectJobPortal: ConfigActions.disconnectJobPortal,
};

message.config({
  top: 100,
  duration: 2,
  maxCount: 1,
});

export class ApplicationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.refsArray = [];
    this.isJobDistributionCredentialsFetched = false;
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { fetchBotDetails, fetchAllPortalCredentials, fetchAllPortalCountries, fetchAvailableJobAdCredits } =
      this.props;
    fetchBotDetails();
    fetchAllPortalCredentials();
    fetchAllPortalCountries();
    fetchAvailableJobAdCredits();
  }

  componentDidUpdate() {
    const { jobDistributionPortals, fetchJobDistributionCredentials } = this.props;
    if (!this.isJobDistributionCredentialsFetched && !_.isEmpty(jobDistributionPortals)) {
      this.isJobDistributionCredentialsFetched = true;
      fetchJobDistributionCredentials();
    }
  }

  handleScroll(index) {
    const { refsArray } = this;
    if (window) {
      window.scrollTo({
        top: refsArray[index].offsetTop,
        behavior: 'smooth',
      });
    }
  }

  handleAutoRejectChange = value => {
    const { patchUpdateConfig } = this.props;
    patchUpdateConfig({ IsAryaRejectionEnabled: value });
  };

  getCredentialPostingJobboardNames = () => {
    const { jobDistributionPortals } = this.props;
    return jobDistributionPortals
      .filter(
        portalConfig => portalConfig.IsEnabled && JobBoardPortals.includes(portalConfig.Portal?.Name?.toLowerCase())
      )
      .map(portalConfig => portalConfig.Portal.Name);
  };

  getPostingJobboards = () => {
    const { refsArray } = this;
    const {
      userConfig,
      integrateSource,
      whiteLabelInfo,
      authenticateJobPortal,
      disconnectJobPortal,
      jobPortalsConfig,
      featureToggleList,
    } = this.props;

    const AppName = whiteLabelInfo?.AppName ?? 'Leoforce';
    const sourceConfigsByName = userConfig?.SourceConfigsByName ?? [];

    const postingJobboards = this.getCredentialPostingJobboardNames();
    if (this.showJobPostingIntegration()) {
      return (
        <div className="settings-section" style={{ paddingTop: '0px' }}>
          <div className="jobboard-settings">
            <div>
              <div
                className="integration-title"
                ref={input => {
                  refsArray[5] = input;
                }}
              >
                <FormattedMessage {...applicationSettingMessages.jobPublishIntegrationTitle} />
              </div>
              <div className="integration-message">
                <FormattedMessage {...applicationSettingMessages.applicationSettingIntegrationMessage} />
              </div>
            </div>
          </div>
          <div className="jobboards">
            {postingJobboards.map(jobBoard => (
              <JobBoardSettings
                jobBoard={jobBoard}
                portalSettings={_.get(jobPortalsConfig, jobBoard, {})}
                authenticate={authenticateJobPortal}
                disconnect={disconnectJobPortal}
                portalCountries={{ Countries: ['US'] }}
                key={`${jobBoard}-posting`}
                integrateSource={integrateSource}
                sourcesConfig={_.get(userConfig, ['Sources'])}
                sourceConfig={_.get(sourceConfigsByName, jobBoard.toLowerCase(), {})}
                AppName={AppName}
                whiteLabelInfo={whiteLabelInfo}
                isPostingJobboard
                featureToggleList={featureToggleList}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  showJobPostingIntegration = () => {
    const { featureToggleList } = this.props;
    const postingJobboards = this.getCredentialPostingJobboardNames();
    const {
      JobDistribution: { IsEnabled: isJobDistributionEnabled },
    } = featureToggleList;
    return postingJobboards.length && isJobDistributionEnabled;
  };

  render() {
    const { refsArray } = this;
    const {
      userConfig,
      roleType,
      connectConfig,
      updateBotDetails,
      patchUpdateConfig,
      updateBotDetailsApiStatus,
      updateConfigApiStatus,
      aryaVersion,
      whiteLabelInfo,
      featureToggleList,
    } = this.props;

    const {
      EmailWrite: { IsEnabled: isEmailWriteEnabled },
      BotWrite: { IsEnabled: isBotWriteEnabled },
    } = featureToggleList;
    const jobBoards = _.get(userConfig, 'Portals', []).filter(
      portal => !['social', 'internal'].includes((portal || '').toLowerCase())
    );

    const candidateAutoRejectValue = userConfig?.IsAryaRejectionEnabled;
    const isCandidateAutoRejectEnabled = featureToggleList.AryaRejection.IsEnabled;

    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    return (
      <div className="settings-container">
        <div className="settings-navigator">
          <Affix offsetTop={80} className="navigator-group-wrapper">
            <div className="navigator-group">
              {jobBoards.length && aryaVersion !== 'Lite' ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(0)}
                  onKeyPress={() => this.handleScroll(0)}
                >
                  <FormattedMessage {...messages.jobBoardIntegration} />
                </div>
              ) : null}
              {/* <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(1)}
                onKeyPress={() => this.handleScroll(1)}
              >
                {intl.formatMessage({ ...messages.languageChange })}
              </div> */}
              {this.showJobPostingIntegration() ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(5)}
                  onKeyPress={() => this.handleScroll(5)}
                >
                  <FormattedMessage {...applicationSettingMessages.applicationSettingJobPublishIntegration} />
                </div>
              ) : null}
              <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(1)}
                onKeyPress={() => this.handleScroll(1)}
              >
                <FormattedMessage {...messages.defaultDistance} />
              </div>
              {isEmailWriteEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(2)}
                  onKeyPress={() => this.handleScroll(2)}
                >
                  <FormattedMessage {...messages.emailConfiguration} />
                </div>
              ) : null}
              {isCandidateAutoRejectEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(3)}
                  onKeyPress={() => this.handleScroll(3)}
                >
                  <FormattedMessage {...applicationSettingMessages.applicationSettingCandidateAutoRejection} />
                </div>
              ) : null}
              {isBotWriteEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(4)}
                  onKeyPress={() => this.handleScroll(4)}
                >
                  <FormattedMessage {...messages.aryaBotSettings} values={{ AppName }} />
                </div>
              ) : null}
            </div>
            <div className="vertical-line" />
          </Affix>
        </div>
        <div className="settings">
          {jobBoards.length && aryaVersion !== 'Lite' ? (
            <div
              className="settings-section"
              style={{ paddingTop: '0px' }}
              ref={input => {
                refsArray[0] = input;
              }}
            >
              <JobBoardSettingsContainer />
            </div>
          ) : null}
          {this.getPostingJobboards()}
          {/* <div
            className="language-title"
            ref={input => {
              refsArray[1] = input;
            }}
          >
            {intl.formatMessage({ ...messages.languageChange })}
          </div>
          <div className="language-message">{intl.formatMessage({ ...messages.languageChangeMessage })}</div>
          <div>
            {form.getFieldDecorator('Language', {
              initialValue: _.get(userConfig, 'Language', false) ? userConfig.Language : 'English',
            })(
              <Select placeholder="Please select a language" className="language-select">
                <Option value="en">English</Option>
                <Option value="fr">French</Option>
                <Option value="de">German</Option>
              </Select>
            )}
          </div> */}
          <div
            className="settings-section"
            ref={input => {
              refsArray[1] = input;
            }}
          >
            <DistanceSettings
              userConfig={userConfig}
              refsArray={refsArray}
              roleType={roleType}
              patchUpdateConfig={patchUpdateConfig}
              updateConfigApiStatus={updateConfigApiStatus}
              AppName={AppName}
            />
          </div>
          {isEmailWriteEnabled ? (
            <div
              className="settings-section"
              style={{ width: '960px' }}
              ref={input => {
                refsArray[2] = input;
              }}
            >
              <EmailConfigurationContainer />
            </div>
          ) : null}
          {isCandidateAutoRejectEnabled ? (
            <div className="settings-section">
              <div
                className="candidateAutoRejectSettingsTitle"
                ref={input => {
                  refsArray[3] = input;
                }}
              >
                <FormattedMessage {...applicationSettingMessages.applicationSettingCandidateAutoRejection} />
              </div>
              <CandidateAutoRejectSwitch
                autoRejectValue={candidateAutoRejectValue}
                onChange={this.handleAutoRejectChange}
                disabled={updateConfigApiStatus?.toUpperCase() === 'INPROGRESS'}
              />
            </div>
          ) : null}
          {isBotWriteEnabled ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[4] = input;
              }}
            >
              <BotSettings
                settings={_.get(connectConfig, 'BotSettings', {})}
                updateBotDetails={updateBotDetails}
                updateBotDetailsApiStatus={updateBotDetailsApiStatus}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ApplicationSettings.propTypes = {
  userConfig: PropTypes.shape({}),
};

ApplicationSettings.defaultProps = {
  userConfig: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ApplicationSettings));

export { ApplicationSettings as ApplicationSettingsWithoutStore };
