import React from 'react';
import { Icon, Badge } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import './SourcingNotification.scss';
import { getSourceDisplayName } from '../../../Utils/SourceUtils';
import { EllipsedText } from '../../../Utils/TextUtils';
import store from '../../../store';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';

function getIcon(notificationType) {
  switch (notificationType) {
    case 'Sourcing':
    case 'DilatedSourcing':
      return <Icon style={{ fontSize: 40, color: '#13C26B' }} type="clock-circle" />;
    default:
      return null;
  }
}

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};

function getNotificationInfo(aryaVersion, sourceName, portal, sourcedCount, notificationType) {
  if (notificationType === 'DilatedSourcing') {
    return (
      <div className="message">
        <span className="bold"> {sourcedCount} additional candidates </span>
        have been added.
      </div>
    );
  }
  return (
    <div className="message">
      <span className="bold"> {sourcedCount} </span>
      New candidates have been sourced
      {aryaVersion !== 'Lite' ? (
        <span>
          {' '}
          from <span className="bold">{sourceName}</span>
        </span>
      ) : (
        '.'
      )}
    </div>
  );
}

function SourcingNotification(props) {
  const { notification, onClick, aryaVersion, whiteLabelInfo, featureToggleList } = props;
  if (typeof notification.Payload === 'string') {
    notification.Payload = JSON.parse(notification.Payload);
  }
  const { Type, Payload, UpdatedTime, UnreadCount } = notification;
  const { JobId, JobTitle, Sourced, Portal } = Payload;
  let sourcedCount = 0;
  let SourceName = '';

  const state = store.getState();
  const userConfig = state?.ConfigReducer?.UserConfig ?? {};

  const {
    MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
  } = featureToggleList;

  if (Array.isArray(Sourced)) {
    _.forEach(Sourced, value => {
      sourcedCount += value.Count;
    });
    const sourceArray = [];
    _.forEach(Sourced, value => {
      const sourceName = getSourceDisplayName(
        value.Source,
        userConfig,
        true,
        {},
        whiteLabelInfo,
        null,
        isMaskActiveAsInternalEnabled
      );
      if (value) {
        if (isMaskActiveAsInternalEnabled) {
          if (sourceName === 'Active') sourceArray.push(`Internal (${value.Count})`);
          else if (sourceName !== 'Internal') sourceArray.push(`${sourceName} (${value.Count})`);
        } else sourceArray.push(`${sourceName} (${value.Count})`);
      }
    });
    if (sourceArray.length > 1) {
      const lastSource = sourceArray.pop();
      SourceName = sourceArray.join(', ');
      SourceName = `${SourceName} and ${lastSource}.`;
    } else if (sourceArray.length > 0) {
      SourceName = sourceArray.pop();
      SourceName = `${SourceName}.`;
    }
  } else if (!Portal) {
    _.forEach(Sourced, value => {
      sourcedCount += value;
    });
    const sourceArray = [];
    _.forEach(Sourced, (value, key) => {
      if (value) {
        sourceArray.push(`${key} (${value})`);
      }
    });
    if (sourceArray.length > 1) {
      const lastSource = sourceArray.pop();
      SourceName = sourceArray.join(', ');
      SourceName = `${SourceName} and ${lastSource}.`;
    } else {
      SourceName = sourceArray.join(', ');
      SourceName = `${SourceName}.`;
    }
  }

  return (
    <div className="notification" role="presentation" onClick={() => onClick(notification)}>
      <div className="icon">{getIcon(Type)}</div>
      <div className="content">
        <div className="notification-title">
          <span className="bold">
            <EllipsedText text={JobTitle} maxTextLength={40} />
          </span>
          , {JobId}
        </div>
        {getNotificationInfo(aryaVersion, SourceName, Portal, sourcedCount, Type)}
        <div className="actions" />
        <div className="since">{moment.utc(UpdatedTime).local().fromNow()}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(SourcingNotification);
