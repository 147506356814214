import React from 'react';
import { connect } from 'react-redux';
import ContactSettings from '../Components/ContactSettings/ContactSettings';
import * as AryaPayActions from '../Actions/AryaPayActions';
import * as ConnectSettingsActions from '../Actions/ConnectSettingsActions';
import { getConfig, getWhiteLabelInformation } from '../Reducers/ConfigReducer';
import { getConnectSettings } from '../Reducers/ConnectSettingsReducer';
import { getAvailableCredits } from '../Reducers/AryaPayReducer';
import { getApiStatus } from '../Reducers/ApiStatusReducer';

const mapStateToProps = state => ({
  userConfig: getConfig(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  connectConfig: getConnectSettings(state),
  availableCredits: getAvailableCredits(state),
  requestForCreditsApiStatus: getApiStatus(state, 'requestCreditsStatus'),
});

const mapDispatchToProps = {
  fetchContactType: ConnectSettingsActions.fetchContactType,
  fetchContactProviders: ConnectSettingsActions.fetchContactProviders,
  fetchAvailableCredits: AryaPayActions.fetchAvailableCredits,
  updateContactType: ConnectSettingsActions.updateContactType,
  bulkSubscribeContactProviders: ConnectSettingsActions.bulkSubscribeContactProviders,
  requestForCredits: AryaPayActions.requestForCredits,
};

function ContactSettingsContainer(props) {
  const {
    userConfig,
    connectConfig,
    availableCredits,
    updateContactType,
    bulkSubscribeContactProviders,
    requestForCredits,
    requestForCreditsApiStatus,
    whiteLabelInfo,
    fetchContactType,
    fetchContactProviders,
    fetchAvailableCredits,
  } = props;
  React.useEffect(() => {
    fetchContactType();
    fetchContactProviders();
    fetchAvailableCredits(null, 'Arya');
  }, []);
  const aryaVersion = userConfig?.SubscriptionType;
  const AppName = whiteLabelInfo?.AppName ?? 'Leoforce';
  return (
    <ContactSettings
      contactSettings={connectConfig?.ContactSettings || {}}
      availableCredits={availableCredits}
      updateContactType={updateContactType}
      bulkSubscribeContactProviders={bulkSubscribeContactProviders}
      requestForCredits={requestForCredits}
      requestForCreditsApiStatus={requestForCreditsApiStatus}
      aryaVersion={aryaVersion}
      AppName={AppName}
    />
  );
}
const PackageContactSettings = connect(mapStateToProps, mapDispatchToProps)(ContactSettingsContainer);
export default PackageContactSettings;
export { ContactSettingsContainer as ContactSettingsContainerWithoutStore };
