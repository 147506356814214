function getSelectedMessageTemplateDetails(
  templateId,
  isConsent,
  consentMessageTemplates,
  nonConsentMessageTemplates,
  messageTemplates
) {
  let selectedTemplate = {};
  if (isConsent === true) {
    selectedTemplate = consentMessageTemplates.find(template => template.Id === templateId);
  } else if (isConsent === false) {
    selectedTemplate = nonConsentMessageTemplates.find(template => template.Id === templateId);
  } else {
    selectedTemplate = messageTemplates.find(template => template.Id === templateId);
  }
  return selectedTemplate ?? {};
}

const jobMergeTagKeys = [
  'Candidate.JobViewURL',
  'Bot.Url',
  'Candidate.JobApplyURL',
  'Candidate.JobNotInterestedURL',
  'Job.Id',
  'Job.Zipcode',
  'Job.Title',
  'Job.Location',
  'Job.Country',
];

export { getSelectedMessageTemplateDetails, jobMergeTagKeys };
