import React from 'react';
import { Popover, Badge, Icon, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classname from 'classnames';
import ConnectNotification from '../../Components/ConnectNotification/ConnectNotification';
import { ConnectNotificationIcon } from '../../Icons/AryaIcons';
import { getLastNotificationIdAndUpdateTime } from '../../Utils/NotificationUtils';
import styles from './ConnectNotification.module.scss';

const goToConnect = 'Go To Connect';

const ConnectNotificationComponent = props => {
  const {
    isConnectEnabled,
    popoverParams,
    connectNotifications,
    fetchConnectNofitications,
    form,
    currentUser,
    jobsById,
    jobGuidToIdMapping,
    currentPageConnectNotificationCount,
    saveRecentReadNotificationInfo,
    setCurrentMessagePopupInfos,
    onClickConnectNotificationItem,
    connectNotificationsUnviewedCount,
    showConnectNotification,
    version,
    visible,
    handlePopoversVisibility,
    goToConnectCallback,
  } = props;
  const connectNotificationTitle = (
    <div style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
      <div style={{ maxWidth: 'fit-content', display: 'relative' }}>Connect Notification</div>
    </div>
  );

  const getConnectNotificationComponent = () => {
    const { Id: lastNotificationId, UpdatedTime: beforeTime } =
      getLastNotificationIdAndUpdateTime(connectNotifications);
    return connectNotifications.length ? (
      <div className={styles.connectNotificationContainer}>
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={() =>
            fetchConnectNofitications(lastNotificationId, beforeTime, form.getFieldValue('ConnectUserCheckbox'))
          }
          hasMore={currentPageConnectNotificationCount === 10}
          loader={
            <div key="loader" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
              <Icon style={{ fontSize: 20 }} type="loading" spin />
            </div>
          }
          threshold={220}
        >
          {connectNotifications.map(connectNotification => {
            const jobGuid = _.get(connectNotification, ['Payload', 'RefId'], undefined);
            return (
              <ConnectNotification
                notification={connectNotification}
                key={connectNotification.Id}
                onClick={onClickConnectNotificationItem}
                currentUser={currentUser}
                jobDetails={jobGuid ? _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {}) : {}}
                saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
                setCurrentMessagePopupInfos={setCurrentMessagePopupInfos}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    ) : (
      <div className={styles.connectNotificationEmpty}>
        <div className={styles.connectNotificationEmptyIconWrapper}>
          <ConnectNotificationIcon className={styles.connectNotificationIcon} />
        </div>
        <div className={styles.connectNotificationEmptyTitle}>No connect notification found</div>
        <div className={styles.connectNotificationEmptyDescription}>
          All the replies from the candidates will appear here
        </div>
      </div>
    );
  };

  const connectNotificationComponent = getConnectNotificationComponent();

  const connectNotificationFooter = connectNotifications.length ? (
    <div>
      <Divider style={{ margin: 0 }} />
      <div className={styles.goToConnect}>
        {version === 'ats' ? (
          <div onClick={goToConnectCallback}>{goToConnect}</div>
        ) : (
          <Link to="/connect" style={{ color: '#7F8FA5', fontSize: '14px', fontWeight: 'bold' }}>
            {goToConnect}
          </Link>
        )}
      </div>
    </div>
  ) : null;

  if (isConnectEnabled)
    return (
      <Popover
        title={connectNotificationTitle}
        visible={visible}
        content={
          <div>
            {connectNotificationComponent}
            {connectNotificationFooter}
          </div>
        }
        {...popoverParams}
        onVisibleChange={visible => handlePopoversVisibility(visible, 'ConnectNotification')}
      >
        <div>
          <span className={styles.navbarMenuItemIcon}>
            <span className={styles.badgedIcon}>
              <Badge count={connectNotificationsUnviewedCount} className={styles.connectBadge}>
                <ConnectNotificationIcon
                  style={{ cursor: 'pointer' }}
                  className={classname(styles.connectNotificationIcon, {
                    [styles.wiggle]: showConnectNotification,
                  })}
                />
              </Badge>
            </span>
          </span>
        </div>
      </Popover>
    );
  return null;
};

export default ConnectNotificationComponent;
