import React from 'react';
import { connect } from 'react-redux';
import JobBoardSettingsContainer from '../Containers/JobBoardSettings/JobBoardSettingsContainer';
import * as ConfigActions from '../Actions/ConfigActions';
import { getConfig } from '../Reducers/ConfigReducer';

const mapStateToProps = state => ({
  userConfig: getConfig(state),
});

const mapDispatchToProps = {
  fetchConfig: ConfigActions.fetchConfig,
  fetchAllPortalCountries: ConfigActions.fetchAllPortalCountries,
  fetchAllPortalCredentials: ConfigActions.fetchAllPortalCredentials,
};

function JobBoardSettings(props) {
  const { fetchConfig, fetchAllPortalCountries, fetchAllPortalCredentials } = props;
  React.useEffect(() => {
    fetchConfig();
    fetchAllPortalCountries();
    fetchAllPortalCredentials();
  }, []);
  return (
    <JobBoardSettingsContainer />
  );
}

const PackageJobBoardSettings = connect(mapStateToProps, mapDispatchToProps)(JobBoardSettings);
export default PackageJobBoardSettings;
export { JobBoardSettings as JobBoardSettingsWithoutStore };
