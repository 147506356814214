import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import styles from './CandidateSourceAndName.module.scss';
import SourceImageIcon from '../../CandidateListItem/SourceImageIcon/SourceImageIcon';
import {
  getInternalSourceWithCandidateId,
  getAtsSource,
  getSourceDisplayName,
  getPushedAtsSource,
  getCandidateSource,
  getPortalName,
} from '../../../Utils/SourceUtils';
import { isInternalIconVisible } from '../../../Utils/DownloadedCandidatesSourceUtils';
import CandidateViewIconV2 from '../../../Icons/CandidateViewIconV2';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import CandidateCardShowDuplicate from '../CandidateCardShowDuplicate/CandidateCardShowDuplicate';
import { getCandidatePublishStatus } from '../../../Utils/CandidatePublishUtils';

function CandidateSourceAndName(props) {
  const {
    candidate = {},
    userConfig = {},
    version,
    whiteLabelInfo,
    featureToggleList,
    isCandidateViewIconVisible,
    openCandidateView,
    isCandidateGroupHead,
    duplicateCandidateIds,
    adminFeatures,
    msp,
    candidateContext,
  } = props;

  const { Name: candidateName, Sources: candidateSources } = candidate;
  const { RevealPortalsUnderGroup: revealPortalsUnderGroup, WhiteGloveServiceProgress: whiteGloveServiceProgress } =
    featureToggleList;
  const {
    MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
  } = featureToggleList;
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList, candidateContext);

  const { ShowVaultName: shouldShowVaultName } = userConfig;
  const showVaultName = shouldShowVaultName ?? false;
  const revealActiveChannelSourceName = revealPortalsUnderGroup.IsEnabled;
  const isSRPulseUser = whiteGloveServiceProgress?.IsEnabled;

  const isAtsVersion = version === 'ats';

  const atsSource = getAtsSource(candidateSources);
  const candidateSource = isAtsVersion
    ? getInternalSourceWithCandidateId(candidateSources)
    : getPushedAtsSource(candidateSources);

  const sourceDisplayName = getSourceDisplayName(
    atsSource || getCandidateSource(candidate),
    userConfig,
    showVaultName,
    {},
    whiteLabelInfo,
    revealActiveChannelSourceName
  );

  const candidatePortalName = getPortalName(candidateSources, revealActiveChannelSourceName);

  const candidateSourcePortal =
    candidatePortalName?.toLowerCase() === 'internal' && isAtsVersion
      ? getSourceDisplayName(
          candidateSource,
          userConfig,
          showVaultName,
          {},
          whiteLabelInfo,
          revealActiveChannelSourceName
        )
      : sourceDisplayName;

  const showInternalIcon = isInternalIconVisible(candidate, isSRPulseUser);
  const sourceIconStyle = { fontSize: 18, display: 'flex' };

  const showPortalIcons = (
    <div className={styles.overlappingSourceIcons}>
      <div className={styles.sourceImageContainer}>
        <SourceImageIcon
          tooltipVisibility
          placement="top"
          sourceName={candidateSourcePortal}
          sourcePortal={candidatePortalName}
          iconStyle={sourceIconStyle}
          showVaultName={showVaultName}
          isMaskActiveAsInternalEnabled={isMaskActiveAsInternalEnabled}
        />
      </div>
      {showInternalIcon && !isMaskActiveAsInternalEnabled && (
        <div className={styles.overlappingSourceImage}>
          <SourceImageIcon
            tooltipVisibility
            placement="top"
            sourceName="Internal"
            sourcePortal="InternalForDownloadedCandidates"
            iconStyle={sourceIconStyle}
            showVaultName={showVaultName}
          />
        </div>
      )}
    </div>
  );

  const handleCandidateView = e => {
    e.stopPropagation();
    openCandidateView(candidate);
  };

  const candidateViewIcon =
    isCandidateViewIconVisible && !isCandidatePublishEnabled ? (
      <CandidateViewIconV2
        onClick={handleCandidateView}
        sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateView.viewInNewTab, candidate)}
      />
    ) : null;

  const candidateShowDuplicateOption =
    isCandidateGroupHead && duplicateCandidateIds.length ? <CandidateCardShowDuplicate /> : null;

  return (
    <div className={styles.candidateSourceAndNameWrapper}>
      <div className={styles.candidateSourceAndName}>
        {!msp ? (
          showPortalIcons
        ) : (
          <SourceImageIcon
            tooltipVisibility
            placement="top"
            sourceName="Internal"
            sourcePortal="InternalForDownloadedCandidates"
            iconStyle={sourceIconStyle}
            showVaultName={showVaultName}
            isMaskActiveAsInternalEnabled={isMaskActiveAsInternalEnabled}
          />
        )}
        <Tooltip title={candidateName}>
          <span>{candidateName}</span>
        </Tooltip>
      </div>
      {candidateShowDuplicateOption}
      {!msp && candidateViewIcon}
    </div>
  );
}

export default CandidateSourceAndName;
