import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getCandidates } from '../../Reducers/CandidateReducer';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getCandidateStatus } from '../../Utils/SourceUtils';
import * as candidateActions from '../../Actions/CandidateActions';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import MessageWindowContainer from '../../Containers/MessageWindow/MessageWindow';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';

const mapStateToProps = (state, ownProps) => {
  return {
    connectInfo: getCandidatesConnectInfo(state),
    candidatesById: getCandidates(state),
    featureToggleList: getFeatureToggleList(state),
  };
};

const mapDispatchToProps = {
  fetchCandidateDetails: candidateActions.fetchCandidateDetails,
};

function AllSMS(props) {
  const {
    jobId,
    candidateId,
    candidatesById,
    version,
    connectInfo,
    fetchCandidateDetails,
    featureToggleList,
    setCount,
    openJobViewInNewTabCallBack,
  } = props;
  const {
    AllConversations: { IsEnabled: isAllConversationsEnabled },
  } = featureToggleList;

  const candidateInfo = { jobId: null, Id: candidateId };
  const pushCandInfo = { Country: null };
  const [isCandidateViewHeaderVisible, setCandidateViewHeaderVisible] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const allSmsRef = React.useRef(null);
  const [isComposeMessageModalVisible, setMessageComposeModalVisibility] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [messageTab, setMessageTab] = React.useState(null);

  const fetchAndSetCandidateDetails = async () => {
    const _candidateDetails = await fetchCandidateDetails(candidateInfo, pushCandInfo, true);
    setCandidateDetails(_candidateDetails);
  };

  React.useEffect(() => {
    fetchAndSetCandidateDetails();
  }, []);

  if (!candidateDetails) {
    return <LoadingScreen loading active />;
  }

  const connectStatuses = connectInfo.ConnectStatuses || {};
  const candidate = { ..._.get(candidatesById, [candidateId], {}) };
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
  const candidateStatus = getCandidateStatus(_.get(candidate, ['Sources'], []));

  return isAllConversationsEnabled ? (
    <MessageWindowContainer
      conversationId={null}
      personId={candidate.PersonId}
      availableProviders={availableProviders}
      candidate={candidate}
      jobId={jobId}
      candidateStatus={candidateStatus}
      currentJobDetails={{}}
      candidateContext="job"
      version={version}
      ref={allSmsRef}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      isComposeMessageModalVisible={isComposeMessageModalVisible}
      setMessageComposeModalVisibility={setMessageComposeModalVisibility}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      setMessageTab={setMessageTab}
      isAllSMS
      setCount={setCount}
      isComposeMessageDisabled
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      isAllConversations
    />
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(AllSMS);
