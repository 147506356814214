import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import * as connectActions from '../../Actions/ConnectActions';
import { getCandidates } from '../../Reducers/CandidateReducer';
import styles from './AllEmails.module.scss';
import { getMailCount, getAllMailCount } from '../../Reducers/MailConversationsReducer';
import MailWindowContainer from '../../Containers/MailWindow/MailWindow';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getCandidateStatus } from '../../Utils/SourceUtils';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as candidateActions from '../../Actions/CandidateActions';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';

const mapStateToProps = (state, props) => {
  const { candidateId } = props;
  const candidatesById = getCandidates(state);
  const candidate = { ..._.get(candidatesById, [candidateId], {}) };
  const allMailCount = getAllMailCount(state, candidate.PersonId);
  return {
    connectInfo: getCandidatesConnectInfo(state),
    candidatesById,
    allMailCount,
    featureToggleList: getFeatureToggleList(state),
    allContextualMailsConversationApiStatus: getApiStatus(state, 'setAllContextualMailsConversationApiStatus'),
  };
};

const mapDispatchToProps = {
  fetchMails: connectActions.fetchMails,
  fetchCandidateDetails: candidateActions.fetchCandidateDetails,
};

function Mails(props) {
  const {
    jobId,
    candidateId,
    candidatesById,
    fetchMails,
    currentPage,
    setCurrentPage,
    version,
    connectInfo,
    featureToggleList,
    fetchCandidateDetails,
    allContextualMailsConversationApiStatus,
    allMailCount,
    setCount,
    openJobViewInNewTabCallBack,
  } = props;

  const candidateInfo = { jobId: null, Id: candidateId };
  const pushCandInfo = { Country: null };
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [currentSelectedEmail, setCurrentSelectedEmail] = useState(null);
  const [composeEmailType, setComposeEmailType] = useState(null);
  const [isComposeEmailModalVisible, setEmailComposeModalVisibility] = useState(false);
  const onSubTabClick = () => {};
  const mailRef = React.useRef(null);
  const contactsTabRef = React.useRef(null);
  const [isComposeWorkflowModalVisible, setWorkflowComposeModalVisibility] = useState(false);

  React.useEffect(() => {
    (async function _fetchcandidateDetails() {
      const _candidateDetails = await fetchCandidateDetails(candidateInfo, pushCandInfo, true);
      setCandidateDetails(_candidateDetails);
    })();
  }, []);

  if (!candidateDetails) {
    return <LoadingScreen loading active />;
  }

  const {
    AllConversations: { IsEnabled: isAllConversationsEnabled },
  } = featureToggleList;

  const connectStatuses = connectInfo.ConnectStatuses || {};
  const candidate = { ..._.get(candidatesById, [candidateId], {}) };
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
  const candidateStatus = getCandidateStatus(_.get(candidate, ['Sources'], []));

  return isAllConversationsEnabled ? (
    <div className={styles.infiniteScrollDiv}>
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        loadMore={page => {
          fetchMails(null, candidateDetails.PersonId, null, null, page * 10, 10);
          setCurrentPage(page);
        }}
        loader={
          <div key="loader" className={styles.loader}>
            <Icon className={styles.loadingIcon} type="loading" spin />
          </div>
        }
        threshold={150}
        hasMore={(currentPage + 1) * 10 < allMailCount}
      >
        <MailWindowContainer
          availableProviders={availableProviders}
          conversationId={null}
          personId={candidateDetails.PersonId}
          candidate={candidate}
          jobId={jobId}
          connectInfo={connectStatus}
          candidateStatus={candidateStatus}
          featureToggleList={featureToggleList}
          timeLineClassName="mail-window-display-drip-template"
          currentJobDetails={{}}
          candidateContext="job"
          version={version}
          ref={mailRef}
          isCandidateViewHeaderVisible={false}
          jobGuid={null}
          onSubTabClick={onSubTabClick}
          isComposeEmailModalVisible={isComposeEmailModalVisible}
          setEmailComposeModalVisibility={setEmailComposeModalVisibility}
          currentSelectedEmail={currentSelectedEmail}
          setCurrentSelectedEmail={setCurrentSelectedEmail}
          isContactTabRedirection={false}
          composeEmailType={composeEmailType}
          setComposeEmailType={setComposeEmailType}
          contactsTabRef={contactsTabRef}
          setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
          isAllEmails
          emailApiStatus={allContextualMailsConversationApiStatus}
          openCandidateView={() => {}}
          setCount={setCount}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          isAllConversations
        />
      </InfiniteScroll>
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(Mails);
