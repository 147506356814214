import React, { useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import Cookies from 'js-cookie';
import '@aws-amplify/ui-react/styles.css';
import RoutesContainer from './routes';
import handleLogout from './Hooks/handleLogout';
import config from './Config/Config';
import AppPkg from './AppPkg';

export default function App() {
  const { location } = window;
  const { hostname } = location;
  const userType = hostname === config.urls.pulseHostName ? 'pulse' : 'non-pulse';
  const [isAuthenticated, setAuthenticated] = React.useState(false);

  const checkIsSignedInCookie = async () => {
    const authenticatedUser = await getCurrentUser();
    const isSignedInSso = Cookies.get(`aryasso.${config.auth.cognito.clientId}.${authenticatedUser.userId}.IsSignedIn`);
    if (isSignedInSso === 'false') {
      console.log('User is signed out based on cookie.');
      handleLogout();
    }
  };

  useEffect(() => {
    (async function checkSession() {
      try {
        const authenticatedUser = await getCurrentUser();
        const isSignedInSso = Cookies.get(
          `aryasso.${config.auth.cognito.clientId}.${authenticatedUser.userId}.IsSignedIn`
        );
        if (isSignedInSso === 'false') {
          handleLogout();
          return;
        }
        if (!authenticatedUser) {
          localStorage.setItem('continueTo', window.location.href);
          window.location.href = `${config.auth.arya.loginUrl}/servicelogin?service=arya&redirect=${
            userType === 'pulse' ? config.auth.pulse.sessionTokenRedirectUrl : config.auth.arya.sessionTokenRedirectUrl
          }`;
        } else {
          setAuthenticated(true);
        }
      } catch (error) {
        localStorage.setItem('continueTo', window.location.href);
        window.location.href = `${config.auth.arya.loginUrl}/servicelogin?service=arya&redirect=${
          userType === 'pulse' ? config.auth.pulse.sessionTokenRedirectUrl : config.auth.arya.sessionTokenRedirectUrl
        }`;
      }
    })();

    const handleWindowFocus = () => {
      checkIsSignedInCookie();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkIsSignedInCookie();
      }
    };

    window.addEventListener('focus', handleWindowFocus);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  if (!isAuthenticated) return null;

  return <RoutesContainer />;
  // return <AppPkg />;
}
