import React, { useEffect, useState } from 'react';
import { Input, Form, Table, Button, Avatar, Tooltip, Pagination, Icon, Empty } from 'antd';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import styles from './BudgetInformation.module.scss';
import { getApiStatus } from '../../../../Reducers/ApiStatusReducer';
import messages from '../../../ManualSearchV2/ManualSearchMessages';
import * as JobLevelLimitActions from '../../../../Actions/JobUsageBudgetActions';
import * as JobUsageBudgetReducer from '../../../../Reducers/JobUsageBudgetReducer';
import { getConfiguration } from '../../../../Components/Collaborators/Collaborators';
import CreditFilterPopover from './BudgetFilterPopover';
import CreditsUsedSwapRightIcon from '../../../../Icons/CreditsUsedSwapRightIcon';
import { EmptyJobsIcon } from '../../../../Icons/AryaIcons';

const { Search } = Input;

const mapStateToProps = (state, props) => ({
  creditsRequests: JobUsageBudgetReducer.JobUsageBudgetRequests(state),
  totalRequests: JobUsageBudgetReducer.JobUsageTotalBudgetRequests(state),
  CreditsRequestsApiStatus: getApiStatus(state, 'jobUsageBudgetRequestsApiStatus'),
});

const mapDispatchToProps = {
  FetchAllJobUsageBudgetRequests: JobLevelLimitActions.FetchAllJobUsageBudgetRequests,
  updateJobUsageBudgetRequestStatus: JobLevelLimitActions.updateJobUsageBudgetRequestStatus,
};

function BudgetInformation(props) {
  const { FetchAllJobUsageBudgetRequests, creditsRequests, intl, CreditsRequestsApiStatus, form, totalRequests } =
    props;
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
  const [inputData, setInputData] = useState({});
  const [page, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentAppliedFilter, setCurrentAppliedFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  function validateClientName(rule, value, callback) {
    const digitOnlyRegex = /^[0-9]+$/;
    if (!value) {
      callback('This field is required.');
    } else if (parseInt(value, 10) === rule.initialValue) {
      callback('Value must be different from the previous one.');
    } else if (value < rule.initialValue) {
      callback('Value must be greater than the previous limit.');
    } else if (value >= 100000) {
      callback('Value must be less than 100000.');
    } else if (!digitOnlyRegex.test(value)) {
      callback('Please enter valid limit.');
    } else {
      callback();
    }
  }

  const handleRequestCredits = (status, jobId, RequestId, LastAllocatedBudget, CurrentAllocatedBudget) => {
    const { updateJobUsageBudgetRequestStatus } = props;
    updateJobUsageBudgetRequestStatus(
      status === 'Rejected' ? LastAllocatedBudget : parseInt(form.getFieldValue(`Credits-${jobId}`), 10),
      status,
      jobId,
      RequestId,
      LastAllocatedBudget,
      CurrentAllocatedBudget
    );
  };
  const handleSearchTerm = event => {
    const searchKeyword = event.target.value;
    setPageNumber(1);
    setSearchTerm(searchKeyword);
    if (!searchKeyword) {
      const currentFilter = { ...currentAppliedFilter, SearchKeyword: null };
      setCurrentAppliedFilter(currentFilter);
      FetchAllJobUsageBudgetRequests(currentFilter, pageSize);
    }
  };
  const clearSearch = () => {
    setPageNumber(1);
    setCurrentAppliedFilter(null);
    FetchAllJobUsageBudgetRequests();
    setSearchTerm(null);
    setPageSize(10);
  };

  const handleSearch = searchKeyword => {
    setPageNumber(1);
    const currentFilter = { ...currentAppliedFilter, SearchKeyword: searchKeyword };
    setCurrentAppliedFilter(currentFilter);
    FetchAllJobUsageBudgetRequests(currentFilter, pageSize);
  };
  useEffect(() => {
    FetchAllJobUsageBudgetRequests({ From: (page - 1) * pageSize, Size: pageSize });
  }, []);
  useEffect(() => {
    const updatedInputData = {};
    let pendingCount = 0;
    Object.values(creditsRequests).forEach(request => {
      if (request.RequestStatus === 'Pending') {
        pendingCount += 1;
      }
      updatedInputData[request.JobId] = request.CurrentAllocatedBudget || request.LastAllocatedBudget;
    });
    setInputData(updatedInputData);
    setPendingRequestsCount(pendingCount);
  }, [creditsRequests]);
  const getCredits = (status, LastAllocatedBudget, CurrentAllocatedBudget) => {
    if (status === 'Approved')
      return (
        <div className={styles.creditAccepted}>
          <div>{LastAllocatedBudget}</div>
          <div>
            <CreditsUsedSwapRightIcon />
          </div>
          <div>{CurrentAllocatedBudget}</div>
        </div>
      );
    return <div className={styles.creditRejected}>{CurrentAllocatedBudget}</div>;
  };
  const columns = [
    {
      title: (
        <span className={styles.creditRequestTableHeader}>
          <FormattedMessage {...messages.JOB} />
        </span>
      ),
      width: 190,
      dataIndex: 'title',
    },
    {
      title: (
        <span className={styles.creditRequestTableHeader}>
          <FormattedMessage {...messages.requestedByLabel} />
        </span>
      ),
      width: 165,
      dataIndex: 'name',
    },
    {
      title: (
        <span className={styles.creditRequestTableHeader}>
          <FormattedMessage {...messages.creditLimitLabel} />
        </span>
      ),
      width: 157,
      dataIndex: 'credits',
    },
    {
      title: (
        <div className={styles.creditRequest}>
          <div className={styles.creditRequestHeaderWithCount}>
            <span className={styles.creditRequestTableHeader}>
              <FormattedMessage {...messages.requestsLabel} />
            </span>
            <span className={styles.creditRequestCount}>{pendingRequestsCount}</span>
          </div>
          <div className={styles.creditFilterPopoverWrapper}>
            <CreditFilterPopover
              form={form}
              FetchAllJobUsageBudgetRequests={FetchAllJobUsageBudgetRequests}
              setPageNumber={setPageNumber}
              setCurrentAppliedFilter={setCurrentAppliedFilter}
              currentAppliedFilter={currentAppliedFilter}
              setPageSize={setPageSize}
              pageSize={pageSize}
              page={page}
            />
          </div>
        </div>
      ),
      dataIndex: 'requests',
      width: 194,
    },
  ];
  function onPageChange(_page, _pageSize) {
    setPageNumber(_page);
    setPageSize(_pageSize);
    FetchAllJobUsageBudgetRequests({
      From: (_page - 1) * _pageSize,
      Size: _pageSize,
      ...currentAppliedFilter,
    });
  }

  const requestsArray = Object.values(creditsRequests);
  const dataSource = requestsArray.map(request => {
    const name = `${request.RequestedByUserFirstName} ${request.RequestedByUserLastName}`;
    const config = getConfiguration(name);
    const tooltipText = `${name} (${request.RequestedByUserEmail})`;
    const timestamp = request.LastUpdateTime;
    const Date = moment(timestamp).format('MM/DD/YYYY');
    const requestStatusMessage =
      request.RequestStatus === 'Approved' ? (
        <FormattedMessage {...messages.approvedOnLabel} values={{ date: Date }} />
      ) : (
        <FormattedMessage {...messages.dismissedOnLabel} values={{ date: Date }} />
      );

    const renderRequestStatusActions = () => {
      if (request.RequestStatus === 'Pending') {
        const inputValue = parseInt(form.getFieldValue(`Credits-${request.JobId}`), 10);
        const isApproveButtonDisable =
          request.LastAllocatedBudget === inputValue ||
          inputValue < request.LastAllocatedBudget ||
          inputValue >= 100000 ||
          isNaN(inputValue);

        return (
          <div className={styles.acceptRejectButtons}>
            <Tooltip title="Please increase the credit limit!" placement="top">
              <div>
                <Button
                  disabled={isApproveButtonDisable}
                  size="small"
                  className={classNames(styles.creditRequestButton, styles.creditRequestApproveButton)}
                  onClick={() => {
                    handleRequestCredits(
                      'Approved',
                      request.JobId,
                      request.RequestId,
                      request.LastAllocatedBudget,
                      request.CurrentAllocatedBudget
                    );
                  }}
                >
                  <FormattedMessage {...messages.approveLabel} />
                </Button>
              </div>
            </Tooltip>
            <Button
              size="small"
              className={classNames(styles.creditRequestButton, styles.creditRequestDismissButton)}
              onClick={() =>
                handleRequestCredits(
                  'Rejected',
                  request.JobId,
                  request.RequestId,
                  request.LastAllocatedBudget,
                  request.CurrentAllocatedBudget
                )
              }
            >
              <FormattedMessage {...messages.dismissLabel} />
            </Button>
          </div>
        );
      }

      return <span className={styles.approvedDismissedInfo}>{requestStatusMessage}</span>;
    };
    return {
      title: (
        <div className={styles.creditRequestTableJob}>
          <span className={styles.creditRequestTableJobTitle}>{request.JobTitle}</span> |
          <span className={styles.creditRequestTableJobId}>{`${request.JobId}`}</span>
        </div>
      ),
      name: (
        <div className={styles.name}>
          <Tooltip title={tooltipText}>
            <Avatar size="medium" style={{ color: '#ffffff', backgroundColor: config.color }}>
              {config.text}
            </Avatar>
          </Tooltip>
          <span>{request.RequestedByUserFirstName}</span>
        </div>
      ),
      credits: (
        <div>
          {request.RequestStatus === 'Pending' ? (
            <Form.Item>
              {form.getFieldDecorator(`Credits-${request.JobId}`, {
                initialValue: request.LastAllocatedBudget,
                rules: [{ validator: validateClientName, required: true, initialValue: inputData[request.JobId] }],
              })(
                <Input
                  value={inputData[request.LastAllocatedBudget]}
                  styles={{ width: '126px', borderRadius: '4px', border: '1px solid #DADBDD', height: '26px' }}
                  data-testid="input-number"
                />
              )}
            </Form.Item>
          ) : (
            getCredits(request.RequestStatus, request.LastAllocatedBudget, request.CurrentAllocatedBudget)
          )}
        </div>
      ),
      requests: (
        <div>
          {request.RequestStatus === 'INPROGRESS' ? (
            <div key="loader" className={styles.requestLoader}>
              <Icon type="loading" spin />
            </div>
          ) : (
            renderRequestStatusActions()
          )}
        </div>
      ),
    };
  });

  return (
    <>
      <div className={styles.creditInformationTitle}>
        <FormattedMessage {...messages.creditInformationLabel} />
      </div>
      <div className={styles.creditInformationSubtitle}>
        <FormattedMessage {...messages.descriptionForCreditInformationLabel} />
      </div>
      <div className={styles.jobSearch}>
        <Search
          size="default"
          placeholder={intl.formatMessage({ ...messages.searchJobLabel })}
          className={styles.jobSearchInput}
          onSearch={value => handleSearch(value)}
          allowClear
          onChange={handleSearchTerm}
          value={searchTerm}
        />
      </div>
      {dataSource.length > 0 || CreditsRequestsApiStatus === 'INPROGRESS' ? (
        <div className={styles.creditRequestTable}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={CreditsRequestsApiStatus === 'INPROGRESS'}
          />
        </div>
      ) : (
        <Empty
          image={
            <div style={{ marginTop: '30px' }}>
              <EmptyJobsIcon className={styles.requestsListEmpty} />
            </div>
          }
          description={
            <span>
              <span className={styles.noJobUsageBudgetRequestFound}> No Requests found</span>
              <br />
              {!searchTerm && currentAppliedFilter === null ? (
                <span className={styles.noJobUsageBudgetRequestFoundMessage}>All the requests will appear here</span>
              ) : (
                <span className={styles.noRequestFoundClearSearch} role="button" tabIndex={0} onClick={clearSearch}>
                  Clear search
                </span>
              )}
            </span>
          }
        />
      )}
      {dataSource?.length > 0 ? (
        <div className={styles.jobUsageBudgetPagination}>
          <Pagination
            showSizeChanger
            size="small"
            pageSize={pageSize}
            total={totalRequests}
            onChange={onPageChange}
            onShowSizeChange={onPageChange}
            current={page}
            pageSizeOptions={[10, 20]}
          />
        </div>
      ) : null}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(BudgetInformation)));
export { BudgetInformation as BudgetInformationWithoutStore };
