import React, { useEffect } from 'react';
import { Button } from 'antd';
import qs from 'query-string';
import classnames from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import message from '../../../ManualSearchV2/ManualSearchMessages';
import { getSourceImage } from './ATSAuthenticationUtils';
import styles from './ATSAuthentication.module.scss';
import { getAtsConfig } from '../../../../Reducers/AtsReducer';
import { getSources, getRole } from '../../../../Reducers/ConfigReducer';
import * as ConfigActions from '../../../../Actions/ConfigActions';
import { getApiStatus } from '../../../../Reducers/ApiStatusReducer';

const mapStateToProps = state => {
  return {
    atsConfig: getAtsConfig(state),
    sources: getSources(state),
    role: getRole(state),
    disconnectLeverAtsApiStatus: getApiStatus(state, 'disconnectLeverAtsApiStatus'),
  };
};

const mapDispatchToProps = {
  fetchConfig: ConfigActions.fetchConfig,
};

function ATSAuthentication(props) {
  const {
    atsName,
    atsConfig,
    atsKey,
    sources,
    fetchConfig,
    setConfirmationModalVisible,
    role,
    disconnectLeverAtsApiStatus,
  } = props;
  useEffect(() => {
    window.addEventListener('message', callback);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, []);

  const callback = e => {
    const { search } = e.data;
    if (!_.isEmpty(search)) {
      const parsed = qs.parseUrl(search);
      const { status } = parsed;
      if (status === 'true') {
        fetchConfig();
      }
    }
  };

  const url = _.get(atsConfig, ['Configuration', 'oauth_token_redirect_url'], null);
  const isVaultConfigForOrgLevel = _.get(atsConfig, ['AccessConfiguration', 'IsVaultConfigForOrgLevel'], null);
  const handleAuthentication = () => {
    window.open(url, '_blank', 'width=800,height=800');
  };
  const handleDisconnect = () => {
    setConfirmationModalVisible(true);
  };
  const source = sources.find(item => item.Source?.Name === atsKey);
  const isAuthorized = _.get(source, ['IsAuthorized'], null);

  return (
    <div className={styles.aTSWrapper}>
      <div className={styles.aTSName}>
        {getSourceImage(atsName)}
        <div className={styles.aTSNameText}>{atsName}</div>
      </div>
      <div>
        <Button
          type="link"
          size="small"
          className={classnames(styles.authenticationButton, {
            [styles.authenticated]: isAuthorized,
          })}
          onClick={() => {
            if (!isAuthorized) handleAuthentication();
            else handleDisconnect();
          }}
          disabled={isVaultConfigForOrgLevel && role !== 'Admin'}
          loading={disconnectLeverAtsApiStatus === 'INPROGRESS'}
        >
          <p>
            {isAuthorized ? (
              <FormattedMessage {...message.disconnectedLabel} />
            ) : (
              <FormattedMessage {...message.authenticateLabel} />
            )}
          </p>
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ATSAuthentication);
