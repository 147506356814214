import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { onFetchContact } from '../../../Actions/ContactFetchActions';
import { logSmartKarrotEvent } from '../../../Analytics/EventUtils';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes, { modules } from '../../../Analytics/EventTypes';
import styles from './EnhancedFetchContactWidget.module.scss';
import message from '../../Placeholders/PlaceholdersMessages';

function EnhancedFetchContactWidget(props) {
  const {
    fetchContactStatus,
    currentJobDetails,
    candidate,
    handleContactPull,
    isDisable,
    onCandidate360TabChange,
    contactPullColor = '#83878C',
    buttonPadding,
  } = props;

  const dispatch = useDispatch();
  const isLoading = fetchContactStatus === 'InProgress';

  const isButtonDisabled = isLoading || isDisable;

  const onContactPull = e => {
    e.stopPropagation();
    if (isDisable) return;
    if (handleContactPull) {
      handleContactPull({ candidate });
    } else {
      dispatch(onFetchContact({ currentJobDetails, candidate }));
    }
    if (onCandidate360TabChange) onCandidate360TabChange('contact');
    logSmartKarrotEvent(
      getEventNameByCandidateStatus(eventTypes.candidate.candidateContactPull.pullContact, candidate),
      { Module: modules.candidate.candidateActions }
    );
  };

  return (
    <div
      className={classNames(styles.enhancedFetchContactButton, { [styles.disabledFetchContact]: isButtonDisabled })}
      role="presentation"
      onClick={onContactPull}
      style={{ padding: buttonPadding }}
    >
      {isLoading ? (
        <span>
          <Icon type="loading" />{' '}
        </span>
      ) : null}
      <span style={{ color: contactPullColor }}>
        <FormattedMessage {...message.getContactsLabel} />
      </span>
    </div>
  );
}

export default EnhancedFetchContactWidget;
