import React from 'react';
import _ from 'lodash';
import { isPulseUser } from './ConfigUtils';
import SourceImageIcon from '../Components/CandidateListItem/SourceImageIcon/SourceImageIcon';
import { displaySecondaryInternalSourceIcon, getPortalName } from './SourceUtils';

function getCandidateSourceName(candidate, revealActiveChannelSourceName) {
  const candidateSourceName = getPortalName(candidate.Sources, revealActiveChannelSourceName);
  return candidateSourceName?.toLowerCase();
}

function isInternalIconVisible(candidate, isSRPulseUser, revealActiveChannelSourceName) {
  const lowerCasedCandidateSourceName = getCandidateSourceName(candidate, revealActiveChannelSourceName);

  const isResumeOrCvLibrary = ['resumelibrary', 'cvlibrary'].includes(lowerCasedCandidateSourceName);
  const isResumeOrCvUnlocked = isResumeOrCvLibrary ? candidate?.IsUnlocked : true;

  return displaySecondaryInternalSourceIcon(
    lowerCasedCandidateSourceName,
    isSRPulseUser,
    isResumeOrCvUnlocked,
    isPulseUser(),
    candidate
  );
}

function getCandidatePrimarySourceImage(
  candidate,
  fontSize,
  revealActiveChannelSourceName,
  showVaultName,
  candidateSourceName
) {
  if (candidate?.Id && !candidate.ErrorDescription) {
    const sourcePortal = getCandidateSourceName(candidate, revealActiveChannelSourceName);
    return (
      <SourceImageIcon
        tooltipVisibility
        placement="top"
        sourceName={candidateSourceName}
        sourcePortal={sourcePortal}
        iconStyle={{ fontSize, display: 'flex' }}
        showVaultName={showVaultName}
      />
    );
  }
  return null;
}

function getCandidateOriginalSourceName(sources = []) {
  return sources.find(source => _.get(source, 'Type', '').toLowerCase() === 'original');
}

function getCandidateOriginalSourceImage(
  candidate,
  fontSize,
  showVaultName,
  candidateOriginalSourceName,
  isMaskActiveAsInternalEnabled
) {
  if (candidate?.Id && !candidate.ErrorDescription) {
    const sourcePortal = getCandidateOriginalSourceName(candidate.Sources);
    return (
      <SourceImageIcon
        tooltipVisibility
        placement="top"
        sourceName={
          candidateOriginalSourceName === 'Vault' && isMaskActiveAsInternalEnabled
            ? 'Active'
            : candidateOriginalSourceName
        }
        sourcePortal={
          sourcePortal.Portal === 'AryaVault' && isMaskActiveAsInternalEnabled ? 'INTERNAL' : sourcePortal.Portal
        }
        iconStyle={{ fontSize, display: 'flex' }}
        showVaultName={showVaultName}
        isMaskActiveAsInternalEnabled={isMaskActiveAsInternalEnabled}
      />
    );
  }
  return null;
}

export {
  isInternalIconVisible,
  getCandidatePrimarySourceImage,
  getCandidateSourceName,
  getCandidateOriginalSourceName,
  getCandidateOriginalSourceImage,
};
