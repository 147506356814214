import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import ConnectNotificationComponent from '../Containers/Navbar/ConnectNotification';
import {
  getNotifications,
  getUnviewedNotificationsCount,
  getNotificationsTotalCount,
  getNewNotificationStatus,
  getCurrentPageConnectNotificationCount,
} from '../Reducers/AryaNotifyReducer';
import { getJobGuidToIdMapping, getJobsById } from '../Reducers/JobReducer';
import { getCurrentUser } from '../Reducers/UserSessionReducer';
import MessagePopupsContainer from '../Containers/MessagePopups/MessagePopups';
import * as AryaNotifyActions from '../Actions/AryaNotifyActions';
import * as ConnectActions from '../Actions/ConnectActions';
import * as NotificationActions from '../Actions/NotificationActions';

const mapStateToProps = (state, ownProps) => {
  return {
    connectNotifications: getNotifications(state, 'Connect'),
    connectNotificationsTotalCount: getNotificationsTotalCount(state, 'Connect'),
    connectNotificationsUnviewedCount: getUnviewedNotificationsCount(state, 'Connect'),
    currentUser: getCurrentUser(state),
    jobsById: getJobsById(state),
    jobGuidToIdMapping: getJobGuidToIdMapping(state),
    currentPageConnectNotificationCount: getCurrentPageConnectNotificationCount(state),
    newConnectNotificationStatus: getNewNotificationStatus(state, 'Connect'),
  };
};

const mapDispatchToProps = {
  fetchConnectNofitications: AryaNotifyActions.fetchConnectNofitications,
  saveRecentReadNotificationInfo: AryaNotifyActions.saveRecentReadNotificationInfo,
  setCurrentMessagePopupInfos: AryaNotifyActions.setCurrentMessagePopupInfos,
  markConnectNotificationsAsViewed: AryaNotifyActions.markConnectNotificationsAsViewed,
  setMessagePopupVisibility: NotificationActions.setMessagePopupVisibility,
  getPersonDetails: ConnectActions.getPersonDetails,
};

const connectIconStyle = {
  width: 'fit-content',
  border: '1px solid#dadbdd',
  paddingTop: '6px',
  borderRadius: '4px',
};

const messageFooterStyle = {
  position: 'fixed',
  bottom: 0,
  right: '4%',
  zIndex: 2000,
};

const messagePopupContainerStyle = {
  width: '100%',
  margin: 'auto',
};

const ConnectNotificationContainer = props => {
  const {
    connectNotifications,
    fetchConnectNofitications,
    form,
    currentUser,
    jobsById,
    jobGuidToIdMapping,
    currentPageConnectNotificationCount,
    saveRecentReadNotificationInfo,
    setCurrentMessagePopupInfos,
    markConnectNotificationsAsViewed,
    connectNotificationsUnviewedCount,
    newConnectNotificationStatus,
    setMessagePopupVisibility,
    getPersonDetails,
    goToConnectCallback,
  } = props;
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    const userEmail = currentUser?.email;
    const storedStates = JSON.parse(localStorage.getItem('ConnectMyChatsNotificationPreferences')) || [];
    const currentUserState = storedStates.find(state => state.email === userEmail);
    fetchConnectNofitications(undefined, undefined, currentUserState?.isMyChatsToggleChecked, true, ['SMS', 'Chat']);
  }, [currentUser?.email, fetchConnectNofitications]);

  const onClickConnectNotificationItem = (conversationId, personId, _, messageType, personNumber) => {
    getPersonDetails(personId, conversationId);
    setMessagePopupVisibility(conversationId, personId, true, messageType, personNumber);
    setVisible(false);
  };

  const handlePopoversVisibility = visibleStatus => {
    setVisible(visibleStatus);
    markConnectNotificationsAsViewed();
  };

  const showConnectNotification =
    newConnectNotificationStatus?.showNotification && currentUser.sub !== newConnectNotificationStatus.senderId;

  return (
    <div>
      <div style={connectIconStyle}>
        <ConnectNotificationComponent
          isConnectEnabled
          version="ats"
          popoverParams={{ trigger: 'click', placement: 'bottomLeft' }}
          connectNotifications={connectNotifications}
          fetchConnectNofitications={fetchConnectNofitications}
          form={form}
          currentUser={currentUser}
          jobsById={jobsById}
          jobGuidToIdMapping={jobGuidToIdMapping}
          currentPageConnectNotificationCount={currentPageConnectNotificationCount}
          saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
          setCurrentMessagePopupInfos={setCurrentMessagePopupInfos}
          onClickConnectNotificationItem={onClickConnectNotificationItem}
          markConnectNotificationsAsViewed={markConnectNotificationsAsViewed}
          connectNotificationsUnviewedCount={connectNotificationsUnviewedCount}
          showConnectNotification={showConnectNotification}
          visible={visible}
          handlePopoversVisibility={handlePopoversVisibility}
          goToConnectCallback={goToConnectCallback}
        />
      </div>
      <div id="messages-footer" style={messageFooterStyle}>
        <div id="message-popup-container" style={messagePopupContainerStyle}>
          <MessagePopupsContainer />
        </div>
      </div>
    </div>
  );
};

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(ConnectNotificationContainer));
